
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';

import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

declare var $: any;


@Component({
  selector: 'app-mt5-activity-logs',
  templateUrl: './mt5-activity-logs.component.html',
  styleUrls: ['./mt5-activity-logs.component.scss']
})
export class Mt5ActivityLogsComponent implements OnInit {

 

  userDetails;
  urlGetActivityLogs = 'get-mt5-activity-logs';
  urlGetIndividualActivityLogs = 'get-individual-activity-logs';
  urlGetActivityLogsDetailedView = 'get-activity-logs-detailed-view';
  activityLogs; // for all activity Logs
  closeResult: string;

  page = 1;
  totalItems = 0;
  itemsPerPage = 10;

  searchInputData = '';
  searchAction = '';
  searchDate;
  formData;

  viewDataActivity;
  viewResponseActivity;
  viewInputActivity;

  @ViewChild('viewFormData', { static: false }) private viewFormData: ElementRef;

  // tslint:disable-next-line: max-line-length
  constructor(private modalService: NgbModal, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponents: AuthorisedLayoutComponent) {
      this.AuthorisedLayoutComponents.getRouteAccess();
  }

  ngOnInit() {

      this.userDetails = JSON.parse(localStorage.getItem('user_details'));

      this.getActivityLogs();
  }

  open(content, sizeS, modalTitleID) {
      this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return `with: ${reason}`;
      }
  }

  dateFormates(dateObject) {
      let day: any = dateObject.day;
      let month: any = dateObject.month;
      const year = dateObject.year;
      if (day < 10) {
          day = '0' + day;
      }
      if (month < 10) {
          month = '0' + month;
      }
      const date = year + '-' + month + '-' + day;
      return date;
  }

  /**
   * Fecting Country Lists
   */

  getActivityLogs() {

      this.commonService.showLoader = 1;

      let CreationDate: any;
      if (this.searchDate !== undefined && this.searchDate !== null) {
          CreationDate = this.dateFormates(this.searchDate);
      }

      const input = {
          page: this.page,
          _sDl: this.searchInputData,
          _sA: this.searchAction,
          _sDat: CreationDate
      };

      this.tradeApiService.ApiServices(this.urlGetActivityLogs, input, null).subscribe((responseData: any) => {

          if (responseData.status === 200) {
              this.activityLogs = responseData.activityLists.tradeUserDetails.Records;
              this.totalItems = responseData.activityLists.tradeUserDetails.TotalCount;
          } else {
              this.commonService.showToaster(responseData.message, 4);
          }
          this.commonService.showLoader = 0;

      });
  }

  /**
   * Load pagination next page
   */

  loadPage(page: number) {
      this.page = page;
      this.getActivityLogs();
  }

  /**
   * Clear Search Data's
   */

  clearSearchData() {
      this.searchInputData = '';
      this.searchAction = '';
      this.searchDate = undefined;
      this.getActivityLogs();
  }

  /**
   * Close modal popup
   */

  closeModal() {
      this.modalService.dismissAll();
  }

  /**
   * Get Activity Log Detailed View
   */

  getActivityLogDetailedView(data) {     

    this.viewDataActivity  =  JSON.stringify(data, undefined, 2); 
    this.viewInputActivity = data.data;
    this.viewResponseActivity = data.response;


              this.open(this.viewFormData, 'xl', 'modal-blockunblock-title');
              // $('#viewDataActivity').html( JSON.stringify(data.data, undefined, 2));
              // $('#viewResponseActivity').html( JSON.stringify(data.response, undefined, 2));


  }

}
