import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guest-top-nav',
  templateUrl: './guest-top-nav.component.html',
  styleUrls: ['./guest-top-nav.component.scss']
})
export class GuestTopNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
