import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';

import {TranslateService} from '@ngx-translate/core';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';


@Component({
    selector: 'app-bo-transaction-queue-list-withdrawal-deposite',
    templateUrl: './bo-transaction-queue-list-withdrawal-deposite.component.html',
    styleUrls: ['./bo-transaction-queue-list-withdrawal-deposite.component.scss']
})
export class BoTransactionQueueListWithdrawalDepositeComponent implements OnInit {

    closeResult: string;
    modalReference;
    userDetailsAccount;

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    userDetails;
    userDepWithRAccountDetails;
    userMt5AccountDetails;

    urlCreateDetails = 'create-transaction-request';
    urlgetRequestedDepositeWithdDetails = 'get-queue-transaction-details';
    urlGetSingleMt5AccountDetails = 'get-mt5-account-details';
    urlSendRequestedDepositeWithdDetails = 'send-transaction-request';
    urlRejectRequestedDepositeWithdDetails = 'reject-transaction-request';
    urlSendLockRequestedDepositeWithdDetails = 'lock-transaction-request';
    urlSendInprogressLockRequestedDepositeWithdDetails = 'lock-transaction-request-inprogress';

    // New  ;
    loginFind = 0;
    convertNumberToWd = '';
    transactionDepWdAmt = 0;
    transactionTypes = 0;
    getTransactionLogins

    transactionDepWdAmtErr = 0;
    transactionTypesErr = 0;
    getTransactionLoginsErr = 0;
    getTransactionLoginsFindErr = 0;

    getSearchDetails = '';
    gettransactionCreationDate;
    searchtransactionTypes = 0;

    QueuedDetails;
    getTransactionComments = '';

    getReqValue = 1;

    urlGetMyHistoryDetails = 'my-History-details';
    myHistoryDetails;

    pageHist = 1;
    totalItemsHist = 0;
    itemsPerPageHist = 10;

    currentJustify = 'start';

    getHistoryStartDateErr = 0;
    getHistoryEndDateErr = 0;
    getHistoryStartDate;
    getHistoryEndDate;

    test = 0;

    // New

    @ViewChild('queuedDepositeWithdrawalAccounts', { static: false }) private queuedDepositeWithdrawalAccounts: ElementRef;
    @ViewChild('inprogressHistoryLogind', { static: false }) private inprogressHistoryLogind: ElementRef;
    @ViewChild('tabset', { static: false }) tabset;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private translate: TranslateService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) {
        this.AuthorisedLayoutComponent.getRouteAccess();
        translate.setDefaultLang( this.commonService.defaultLanguale );
     }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(mTy) {
        return this.commonService.getMenuPermission('b87dbea7970aedf579bf', mTy);
    }

    ngOnInit() {
        this.userDetails = JSON.parse(localStorage.getItem('user_details'));
        this.getRequestedDepositeWithdDetails();
    }

    @HostListener('window:beforeunload', ['$event']) beforeUnloadHander(event: any) {
        // debugger
        if (this.QueuedDetails) {
            this.lockTransactionRequest(0)
            this.lockTransactionRequestInprogress(0)
        }
    }

    open(content, sizeS) {
        //this.modalReference = this.modalService.open(content);
        this.modalService.open(content, { size: sizeS, backdrop: 'static', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    createDemoAccount() {
        this.clearData();
        this.open(this.queuedDepositeWithdrawalAccounts, 'sl');
    }

    createDepositeWithDrawalRequest() {

        // console.log('createDepositeWithDrawalRequest');
        // console.log(this.transactionDepWdAmt, this.transactionTypes, this.getTransactionLogins);

        this.transactionDepWdAmtErr = 0;
        this.transactionTypesErr = 0;
        this.getTransactionLoginsErr = 0;
        this.getTransactionLoginsFindErr = 0;

        if (this.transactionDepWdAmt == 0) {
            this.transactionDepWdAmtErr = 1;
        }
        if (this.transactionTypes == 0) {
            this.transactionTypesErr = 1;
        }
        if (this.getTransactionLogins == undefined) {
            this.getTransactionLoginsErr = 1;
        }

        if (this.loginFind == 0) {
            this.getTransactionLoginsFindErr = 1;
        }


        if (this.transactionDepWdAmtErr == 0 && this.transactionTypesErr == 0 && this.getTransactionLoginsErr == 0 && this.getTransactionLoginsFindErr == 0) {
            this.commonService.showLoader = 1;

            let input = {
                //token:localStorage.getItem('auth_token'),                       
                uK: this.userDetails.user_key,
                _cAmt: this.transactionDepWdAmt,
                _cTy: this.transactionTypes,
                _cLg: this.getTransactionLogins,
            };

            this.tradeApiService.ApiServices(this.urlCreateDetails, input, null).subscribe((otpDetails: any) => {
                // do stuff with our data here.
                // ....

                // asign data to our class property in the end
                // so it will be available to our template                  
                if (otpDetails.status == 200) {
                    console.log(otpDetails);
                    this.closeModal();
                    this.getRequestedDepositeWithdDetails();
                    this.commonService.showToaster(otpDetails.message, 1);
                }
                else {
                    this.commonService.showToaster(otpDetails.message, 4);
                }

                this.commonService.showLoader = 0;

            });


        }


    }

    dateFormates(dateObject) {
        let d = new Date(dateObject);
        let day: any = dateObject.day;
        let month: any = dateObject.month;
        let year = dateObject.year;
        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        let date = year + "-" + month + "-" + day;

        return date;
    }

    getRequestedDepositeWithdDetails() {
        // console.log('getRequestedDepositeWithdDetails');
        this.commonService.showLoader = 1;

        var gettransactionCreationDate;
        console.log(this.gettransactionCreationDate);
        if (this.gettransactionCreationDate !== undefined && this.gettransactionCreationDate !== null) {
            gettransactionCreationDate = this.dateFormates(this.gettransactionCreationDate);
        }

        let input = {
            //token:localStorage.getItem('auth_token'),                       
            uK: this.userDetails.user_key,
            _dL: 1,
            page: this.page,
            _sSdt: this.getSearchDetails,
            _sDat: gettransactionCreationDate,
            _Sty: this.searchtransactionTypes,
            _rV: this.getReqValue,
            _uType: this.userDetails.user_type
        };

        this.tradeApiService.ApiServices(this.urlgetRequestedDepositeWithdDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (otpDetails.status == 200) {
                // console.log(otpDetails);
                this.userDepWithRAccountDetails = otpDetails.aDc.DW_details;
                this.totalItems = otpDetails.aDc.Total;
                localStorage.setItem('uTlCount', JSON.stringify(otpDetails.uTlCount));
                this.commonService.uTlCount = otpDetails.uTlCount;
            }
            else {
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }

            this.commonService.showLoader = 0;

        });

    }

    clearData() {

        this.getTransactionLogins;
        this.loginFind = 0;
        this.transactionDepWdAmt = 0;
        this.transactionTypes = 0;
        this.convertNumberToWd = '';
        this.userMt5AccountDetails = [];

    }

    viewMt5Details() {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            _dL: 1,
            _dM: this.getTransactionLogins,
        };

        this.tradeApiService.ApiServices(this.urlGetSingleMt5AccountDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template   
            if (otpDetails.find == 0) {
                this.clearData();
                this.getTransactionLoginsFindErr = 1;
            }
            if (otpDetails.status == 200) {
                // console.log(otpDetails);
                this.getTransactionLoginsFindErr = 0;
                this.userMt5AccountDetails = otpDetails.aDd;
                this.loginFind = otpDetails.find;
            }
            else {
                this.getTransactionLoginsFindErr = 1;
                this.loginFind = 0;
                this.userMt5AccountDetails = [];
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }

            this.commonService.showLoader = 0;

        });


    }

    numberToEnglish(n, custom_join_character) {

        var string = n.toString(),
            units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words;

        var and = custom_join_character || 'and';

        /* Is number zero? */
        if (parseInt(string) === 0) {
            return 'zero';
        }

        /* Array of units as words */
        units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];

        /* Array of tens as words */
        tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

        /* Array of scales as words */
        scales = ['', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion'];

        /* Split user arguemnt into 3 digit chunks from right to left */
        start = string.length;
        chunks = [];
        while (start > 0) {
            end = start;
            chunks.push(string.slice((start = Math.max(0, start - 3)), end));
        }

        /* Check if function has enough scale words to be able to stringify the user argument */
        chunksLen = chunks.length;
        if (chunksLen > scales.length) {
            return '';
        }

        /* Stringify each integer in each chunk */
        words = [];
        for (i = 0; i < chunksLen; i++) {

            chunk = parseInt(chunks[i]);

            if (chunk) {

                /* Split chunk into array of individual integers */
                ints = chunks[i].split('').reverse().map(parseFloat);

                /* If tens integer is 1, i.e. 10, then add 10 to units integer */
                if (ints[1] === 1) {
                    ints[0] += 10;
                }

                /* Add scale word if chunk is not zero and array item exists */
                if ((word = scales[i])) {
                    words.push(word);
                }

                /* Add unit word if array item exists */
                if ((word = units[ints[0]])) {
                    words.push(word);
                }

                /* Add tens word if array item exists */
                if ((word = tens[ints[1]])) {
                    words.push(word);
                }

                /* Add 'and' string after units or tens integer if: */
                if (ints[0] || ints[1]) {

                    /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
                    if (ints[2] || !i && chunksLen) {
                        words.push(and);
                    }

                }

                /* Add hundreds word if array item exists */
                if ((word = units[ints[2]])) {
                    words.push(word + ' hundred');
                }

            }

        }

        return words.reverse().join(' ');

    }

    convertNumberToWord() {
        let x = this.numberToEnglish(this.transactionDepWdAmt, '');

        if (x.substring(0, 3) == 'and') {
            x = x.slice(3);
        }
        console.log(x);
        this.convertNumberToWd = x;
    }

    loadPage(page: number) {

        this.page = page;
        this.getRequestedDepositeWithdDetails();
    }

    getAccountDetailClearSearch() {


        this.searchtransactionTypes = 0;
        this.getSearchDetails = ""
        this.gettransactionCreationDate = undefined;
        this.getRequestedDepositeWithdDetails();
    }

    viewQueuedDetails(Details) {
        console.log(Details);
        this.QueuedDetails = Details;
        this.getTransactionComments = '';
        // this.open(this.queuedDepositeWithdrawalAccounts, 'sl');   
        this.lockTransactionRequest(1);
    }

    releaseLock() {
        // console.log('releaseLock');
        this.lockTransactionRequest(0)
    }
    releaseLockInprogress() {
        // console.log('releaseLock');
        this.lockTransactionRequestInprogress(0)
    }

    lockTransactionRequest(lock) {

        var info; 
        this.translate.get('transactionRequestQueueList.info').subscribe(lang =>{
            info = lang;           
        });

        var AlreadyThisDepositeWithdrawalRequestProcessing; 
        this.translate.get('transactionRequestQueueList.AlreadyThisDepositeWithdrawalRequestProcessing').subscribe(lang =>{
            AlreadyThisDepositeWithdrawalRequestProcessing = lang;           
        });

        var close; 
        this.translate.get('transactionRequestQueueList.close').subscribe(lang =>{
            close = lang;           
        });

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            _Qud: this.QueuedDetails,
            _rels: lock,
        };

        this.tradeApiService.ApiServices(this.urlSendLockRequestedDepositeWithdDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (otpDetails.status == 200) {
                if (lock == 1) {
                    if (otpDetails._use == 0) {
                        this.open(this.queuedDepositeWithdrawalAccounts, 'sl');
                    }
                    else {

                        Swal.fire({
                            title: info,
                            text: AlreadyThisDepositeWithdrawalRequestProcessing,
                            type: 'info',
                            confirmButtonText: close
                        })

                    }
                }
            }
            else {
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }

            this.commonService.showLoader = 0;

        });


    }

    lockTransactionRequestInprogress(lock) {

        this.commonService.showLoader = 1;

        var info; 
        this.translate.get('transactionRequestQueueList.info').subscribe(lang =>{
            info = lang;           
        });

        var AlreadyThisDepositeWithdrawalRequestProcessing; 
        this.translate.get('transactionRequestQueueList.AlreadyThisDepositeWithdrawalRequestProcessing').subscribe(lang =>{
            AlreadyThisDepositeWithdrawalRequestProcessing = lang;           
        });

        var close; 
        this.translate.get('transactionRequestQueueList.close').subscribe(lang =>{
            close = lang;           
        });

        let input = {
            uK: this.userDetails.user_key,
            _Qud: this.QueuedDetails,
            _rels: lock,
        };

        this.tradeApiService.ApiServices(this.urlSendInprogressLockRequestedDepositeWithdDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (otpDetails.status == 200) {
                if (lock == 1) {
                    if (otpDetails._use == 0) {
                        this.commonService.showLoader = 1;
                        this.getMyHistoryDetailsFromMt5(this.QueuedDetails);
                    }
                    else {
                        this.closeModal();
                        Swal.fire({
                            title: info,
                            text: AlreadyThisDepositeWithdrawalRequestProcessing,
                            type: 'info',
                            confirmButtonText: close
                        })
                        this.commonService.showLoader = 0;
                    }

                }
                else {
                    this.commonService.showLoader = 0;
                }


            }
            else {
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
                this.commonService.showLoader = 0;
            }
        });
    }

    depositWithDInTradeServer() {
        // console.log('depositWithDInTradeServer');
        // console.log(this.QueuedDetails);
        // console.log(this.getTransactionComments);

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            _Qud: this.QueuedDetails,
            _Qcmt: this.getTransactionComments,
        };

        this.tradeApiService.ApiServices(this.urlSendRequestedDepositeWithdDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (otpDetails.status == 200) {
                this.closeModal();
                this.getRequestedDepositeWithdDetails();
                this.getTransactionComments = '';
                this.commonService.showToaster(otpDetails.message, 1);
            }
            else {

                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }

                if (this.QueuedDetails.trans_type == 2) {
                    this.releaseLock();
                    this.releaseLockInprogress();
                    this.closeModal();
                    this.fetchRequest(3);
                    this.getTransactionComments = '';
                    this.tabset.select('tab-selectbyid2');
                }

            }

            this.commonService.showLoader = 0;

        });


    }

    fetchRequest(getReq) {
        this.getReqValue = getReq;
        this.getRequestedDepositeWithdDetails();
    }

    getMyHistoryDetailsFromMt5lock(inPg) {
        this.QueuedDetails = inPg;
        this.lockTransactionRequestInprogress(1);
    }

    getMyHistoryDetailsFromMt5(inPg, from = 0, getHistoryStartDate = '', getHistoryEndDate = '') {
        // console.log(inPg);
        this.QueuedDetails = inPg;
        // console.log( inPg.created_at );


        this.commonService.showLoader = 1;

        var created_at = inPg.created_at;

        var getHistoryStartDate1 = new Date(created_at);
        var getHistoryEndDate1 = new Date();


        if (from == 1) {
            var getHistoryStartDate1 = new Date(getHistoryStartDate);
            var getHistoryEndDate1 = new Date(getHistoryEndDate);
        }


        var s = getHistoryStartDate1.getFullYear() + '-' + ('0' + (getHistoryStartDate1.getMonth() + 1)).slice(-2) + '-' + ('0' + getHistoryStartDate1.getDate()).slice(-2);

        var e = getHistoryEndDate1.getFullYear() + '-' + ('0' + (getHistoryEndDate1.getMonth() + 1)).slice(-2) + '-' + ('0' + getHistoryEndDate1.getDate()).slice(-2);


        let input = {
            //token:localStorage.getItem('auth_token'),                       
            uK: this.userDetails.user_key,
            _dL: inPg.login,
            _sD: s,
            _eD: e,
            _p: this.pageHist,
            _dA: 2,
            _ty: this.userDetails.user_type,
        };

        this.tradeApiService.ApiServices(this.urlGetMyHistoryDetails, input, null).subscribe((HistoryDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (HistoryDetails.status == 200) {
                // console.log(HistoryDetails.HistoryDetails);
                this.myHistoryDetails = HistoryDetails.historyDetails;
                this.totalItemsHist = HistoryDetails.total;
                this.open(this.inprogressHistoryLogind, 'xl');
            }
            else {
                const errorValues = Object.keys(HistoryDetails.message).map(key => HistoryDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }

            this.commonService.showLoader = 0;

        });


    }

    loadPageHistory(pageHist: number) {
        // console.log(pageHist);
        this.pageHist = pageHist;
        this.getMyHistoryDetails();
    }

    rejectRequestValidation() {
        // console.log('rejectRequestValidation');

        var AreYouSureWantToReject; 
        this.translate.get('transactionRequestQueueList.AreYouSureWantToReject').subscribe(lang =>{
            AreYouSureWantToReject = lang;           
        });

        var ReasonForRejection; 
        this.translate.get('transactionRequestQueueList.ReasonForRejection').subscribe(lang =>{
            ReasonForRejection = lang;           
        });

        var RejectBtn; 
        this.translate.get('transactionRequestQueueList.Reject').subscribe(lang =>{
            RejectBtn = lang;           
        });

        var ValidReasonInRequired; 
        this.translate.get('transactionRequestQueueList.ValidReasonInRequired').subscribe(lang =>{
            ValidReasonInRequired = lang;           
        });

        Swal.mixin({
            input: 'text',
            inputAttributes: {
                maxlength: '55',
                autocapitalize: 'off',
                autocorrect: 'off'
            },
            confirmButtonText: RejectBtn+' &rarr;',
            showCancelButton: true,
            inputValidator: (result) => {
                return !result && ValidReasonInRequired
            }
            // progressSteps: ['1']
        }).queue([
            {
                title: AreYouSureWantToReject,
                text: ReasonForRejection
            }
        ]).then((result) => {
            if (result.value) {
                console.log(result.value[0]);
                this.rejectMarkAsDepositWithDInTradeServer(result.value[0], '', 1);
            }
        })
    }

    MardAsProcessedRequestValidation() {
        // console.log('MardAsProcessedRequestValidation');

        var Submit; 
        this.translate.get('transactionRequestQueueList.Submit').subscribe(lang =>{
            Submit = lang;           
        });

        var ValidDealIdInRequired; 
        this.translate.get('transactionRequestQueueList.ValidDealIdInRequired').subscribe(lang =>{
            ValidDealIdInRequired = lang;           
        });

        var AreYouSureWantToMakeAsProcessed; 
        this.translate.get('transactionRequestQueueList.AreYouSureWantToMakeAsProcessed').subscribe(lang =>{
            AreYouSureWantToMakeAsProcessed = lang;           
        });

        var DealId; 
        this.translate.get('transactionRequestQueueList.DealId').subscribe(lang =>{
            DealId = lang;           
        });

        Swal.mixin({
            input: 'text',
            inputAttributes: {
                maxlength: '25',
                autocapitalize: 'off',
                autocorrect: 'off'
            },
            confirmButtonText: Submit+' &rarr;',
            showCancelButton: true,
            inputValidator: (result) => {
                return !result && ValidDealIdInRequired
            }
            // progressSteps: ['1']
        }).queue([
            {
                title: AreYouSureWantToMakeAsProcessed,
                text: DealId
            }
        ]).then((result) => {
            if (result.value) {
                // console.log(result.value[0]);
                this.rejectMarkAsDepositWithDInTradeServer('', result.value[0], 2);
            }
        })
    }


    rejectMarkAsDepositWithDInTradeServer(reason = '', ticket = '', from = 0) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            _RQud: this.QueuedDetails,
            _RQcmt: reason,
            _RQticket: ticket,
            _RQfrom: from,
        };

        this.tradeApiService.ApiServices(this.urlRejectRequestedDepositeWithdDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (otpDetails.status == 200) {
                this.closeModal();
                this.getRequestedDepositeWithdDetails();
                this.getTransactionComments = '';
                this.commonService.showToaster(otpDetails.message, 1);
            }
            else {
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }


    getMyHistoryDetails() {


        this.getHistoryStartDateErr = 0;
        this.getHistoryEndDateErr = 0;



        let err = 0;
        if (this.getHistoryStartDate === undefined && this.getHistoryEndDate === undefined) {

            this.getHistoryStartDateErr = 1;
            this.getHistoryEndDateErr = 1;

            err = 1;
        }

        if (this.getHistoryStartDate === undefined) {
            this.getHistoryStartDateErr = 1;
            err = 1;
        }
        if (this.getHistoryEndDate === undefined) {
            this.getHistoryEndDateErr = 1;
            err = 1;
        }


        if (err == 0) {
            var getHistoryStartDate = this.getHistoryStartDate.year + '-' + this.getHistoryStartDate.month + '-' + this.getHistoryStartDate.day;

            var getHistoryEndDate = this.getHistoryEndDate.year + '-' + this.getHistoryEndDate.month + '-' + this.getHistoryEndDate.day;

            // var getHistoryEndDate = new Date( '2019-10-30' ); // pass all the parameters you need to create the time
            if (new Date(getHistoryStartDate).getTime() < new Date(getHistoryEndDate).getTime()) {

                this.getMyHistoryDetailsFromMt5(this.QueuedDetails, 1, getHistoryStartDate, getHistoryEndDate);


            }
            else if (new Date(getHistoryStartDate).getTime() == new Date(getHistoryEndDate).getTime()) {

                this.getMyHistoryDetailsFromMt5(this.QueuedDetails, 1, getHistoryStartDate, getHistoryEndDate);
            }
            else {
                this.getHistoryEndDateErr = 2;
            }
        }
    }
}
