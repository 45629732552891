import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';

import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})

export class BranchComponent implements OnInit {

    userDetails;
    urlGetBranchDetails = 'get-branch-lists';
    urlAddEditBranch = 'add-edit-branch';
    urlBlockUnblockBranch = 'block-unblock-branch';
    urlGetIndividualBranch = 'get-individual-branch-details';
    urlGetCityListAjax = 'get-city-list-ajax';
    branchesList; //for all branch lists
    cityList; //for all city lists
    countryList; //for all country lists
    closeResult: string;
    branchData; //for block & unblock
    individualBranchData; //individual branches details stored for edit branches modal
    title; //Modal Popup header Title
    buttonName; //Modal Popup Button Name
    blockUnblockTitle;

    addEditBranchForm: FormGroup;
    addEditSubmitted = false;

    selectCountry = false;
    selectCity = false;

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    searchInputData = '';
    searchCK = '';
    searchCiK = '';

    @ViewChild('contentAddNewBranch', {static: false}) private contentAddNewBranch: ElementRef;
    @ViewChild('blockUnblockBranch', {static: false}) private blockUnblockBranch: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) {
        this.AuthorisedLayoutComponent.getRouteAccess();
     }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(mTy) {
        return this.commonService.getMenuPermission('3ad60c8b22b1eefb4290', mTy);
    }

    ngOnInit() {

        this.getBranchLists();

        this.userDetails = JSON.parse( localStorage.getItem('user_details'));

        this.addEditBranchForm = this.formBuilder.group({
            branchName: ['', [Validators.required]],
            cK: ['', [Validators.required]], //Country Key
            ciK: ['', [Validators.required]], //City Key
            bK: ['', []], //Branch Key
            comments: ['', []], //Comments
        });
    }

    open(content, sizeS, modalTitleID) {
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {

        this.cityList = [];
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    /**
     * Fecting Branch Lists
     */

    getBranchLists() {

        this.commonService.showLoader = 1;

        let input = {
            page: this.page,
            _sDl: this.searchInputData,
            _sCK: this.searchCK,
            _sCiK: this.searchCiK,
        };

        this.tradeApiService.ApiServices(this.urlGetBranchDetails, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.branchesList = responseData.branchesList.Records;
                this.totalItems = responseData.branchesList.TotalCount;
                this.countryList = responseData.countryList;
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Load pagination next page
     */

    loadPage(page: number) {   
        this.page = page;
        this.getBranchLists();
    }

    /**
     * Add Branch Modal Popup
     */

    addNewBranchModal() {
        this.resetForm();
        this.open(this.contentAddNewBranch, 'x', 'modal-basic-title');
        this.title = 'Add New';
        this.buttonName = 'Add';
        this.cityList = [];
        this.searchCK = '';
    }

    /**
     * Reset add Branch form values and error messages
     */

    resetForm() {
        this.addEditSubmitted = false;
        this.addEditBranchForm.clearValidators();
        this.addEditBranchForm.reset();
        this.addEditBranchForm.markAsPristine();

        this.selectCountry = true;
        this.selectCity = true;
        this.addEditBranchForm.controls['cK'].setValue('');
        this.addEditBranchForm.controls['ciK'].setValue('');
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
        this.cityList = [];
    }

    /**
     * Angular error message form control
     */

    get f() { return this.addEditBranchForm.controls; } //Add Branch Error Controller

    /**
     * Add & Edit Branch form Submit
     */

    addEditBranchSubmit() {

        this.addEditSubmitted = true;

        // stop here if form is invalid
        if (this.addEditBranchForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.addEditBranchForm.value);

        this.tradeApiService.ApiServices(this.urlAddEditBranch, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.getBranchLists();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for(var i=0; i<errorValues.length;i++){                                  
                    this.commonService.showToaster( errorValues[i] , 4 ); 
                }  
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Block & Unblock Branch Modal Popup
     */

    blockUnblock(data) {
        this.branchData = data;
        
        this.blockUnblockTitle = (data.branch_status == 0) ? 'Unblock' : 'Block';

        this.open(this.blockUnblockBranch, 'x', 'modal-blockunblock-title');
    }

    confirmBlockUnblock() {
        
        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            bK: this.branchData.branch_key,
            cK: this.branchData.country_key,
            ciK: this.branchData.city_key,
            bS: this.branchData.branch_status,
        };

        let userInput = Object.assign(input);

        this.tradeApiService.ApiServices(this.urlBlockUnblockBranch, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.branchData = null;
                this.getBranchLists();
            } else {
                this.commonService.showToaster( responseData.message , 4 );
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Get Individual Branch Detail
     */

    getIndividualBranch(data) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            cK: data.country_key,
            ciK: data.city_key,
            bK: data.branch_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualBranch, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {

                this.open(this.contentAddNewBranch, 'x', 'modal-basic-title');
                this.title = 'Edit';
                this.buttonName = 'Update';

                this.individualBranchData = responseData.bD;

                this.cityList = responseData.cityList;

                if(this.cityList.length == 0) {
                    this.addEditBranchForm.controls['ciK'].setValue('');
                }

                this.addEditBranchForm.controls['bK'].setValue(data.branch_key);
                this.addEditBranchForm.controls['ciK'].setValue(data.city_key);
                this.addEditBranchForm.controls['cK'].setValue(data.country_key);
                this.addEditBranchForm.controls['branchName'].setValue(this.individualBranchData.name);
                this.addEditBranchForm.controls['comments'].setValue(this.individualBranchData.comments);

            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Get City List Using Country Key
     */

    getCity(cK) {
        
        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            cK: cK
        };

        this.tradeApiService.ApiServices(this.urlGetCityListAjax, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {

                this.cityList = responseData.cityList;

                if(this.cityList.length == 0) {
                    this.addEditBranchForm.controls['ciK'].setValue('');
                }

            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Clear Search Data's
     */

    clearSearchData() {
        this.searchInputData = '';
        this.searchCK = '';
        this.searchCiK = '';
        this.cityList = [];
        this.getBranchLists();
    }
}
