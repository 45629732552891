import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications
import { Lightbox } from 'ngx-lightbox'; // for image viewer

import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-change-client-info',
  templateUrl: './change-client-info.component.html',
  styleUrls: ['./change-client-info.component.scss']
})
export class ChangeClientInfoComponent implements OnInit {

  closeResult: string;
  imagePathtmp: string;
  imagePathProfile: string;
  modalReference;

  registerForm: FormGroup;
  changeEmailForm: FormGroup;
  changeMobileForm: FormGroup;

  submitted = false;
  submittedChangeEmail = false;
  submittedChangeMobile = false;

  confirm = false;
  pwdPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}';
  numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
  passwordUpdateForm: FormGroup;
  submittedPassword = false;

  disabled = 1;

  newEmaildisabled = 0;
  newEmailShowOtp = 0;

  newMobiledisabled = 0;
  newMobileShowOtp = 0;

  // Customer
  urlProfile = 'customer-profile-update';
  urlProfileEmailMobileVerify = 'customer-mobile-email-opt';
  urlProfileEmailMobileOtpVerify = 'customer-mobile-email-opt-verify';
  urlProfileDetails = 'customer-profile-details';

  urlProfileChangePassword = 'customer-profile-password-reset';

  urlProfileEmailChange = 'customer-email-update-opt';
  urlProfileMobileChange = 'customer-mobile-update-opt';

  userDetails;
  is_email_verified = 0;
  is_mobile_verified = 0;

  ShowTxtMob = 1;
  ShowEnterOtpMob = 0;
  mobileNumberOtp = '';
  OtpVerifyMobileError = 0;

  userDocumentDetails;
  userAddresstDetails;

  changeEmailNumberOtp = '';
  changeEmailNumberOtpError = 0;

  changeMobileNumberOtp = '';
  changeMobileNumberOtpError = 0;

  //
  otpShowLabChange = 0;

  urlProfileImg = 'customer-profile-img';
  urlDeleteProfileImg = 'delete-profile-img';

  timeLeftToCreateLiveAccount = 60;
  showTimeResendOtp = 0;

  pSData = 1;

  constructor( private modalService: NgbModal, private toastr: ToastrService, private _lightbox: Lightbox, private formBuilder: FormBuilder,  private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent, private translate: TranslateService ) { 
    this.AuthorisedLayoutComponent.getRouteAccess();
    translate.setDefaultLang( this.commonService.defaultLanguale );

    // form controll start
    this.reintiPasswordForm();
  }

  ngOnInit() {
    this.userDetails = JSON.parse( localStorage.getItem('user_details'));
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', [Validators.required, Validators.minLength(10)]],
      gender: ['', [Validators.required]],
      country: ['', [Validators.required]],
    });
    this.getProfileDetails();

    this.changeEmailForm = this.formBuilder.group({
      newEmail: ['', [Validators.required, Validators.pattern(/^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
    });

    this.changeMobileForm = this.formBuilder.group({
      newMobileNumber: ['', [Validators.required, Validators.minLength(5)]],
    });

  }

  reintiPasswordForm() {

    this.passwordUpdateForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.pwdPattern)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.pwdPattern)]],
      oldPassword: ['', [Validators.required, Validators.minLength(8)]],
    }, {
      validator: this.MustMatch('newPassword', 'confirmPassword')
  });
}

  /**
   * Assigning the form values
   */

  formValueSet() {
    
    this.registerForm.controls['mobileNumber'].setValue(this.userDetails.user_mobile);
    this.registerForm.controls['email'].setValue(this.userDetails.user_email);

    this.is_email_verified = this.userDetails.email_verified;
    this.is_mobile_verified = this.userDetails.mobile_verified;
    

  }

  open(content) {
    // this.modalReference = this.modalService.open(content);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

   // End document image 

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }
    get fv() { return this.passwordUpdateForm.controls; }
    get fmail() { return this.changeEmailForm.controls; }
    get fmob() { return this.changeMobileForm.controls; }

    onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.registerForm.invalid) {
          return;
      }      

      this.commonService.showLoader = 1;
      
      let input = {
        //token:localStorage.getItem('auth_token'),                       
        uK:this.userDetails.user_key,                       
      };  
      
      
      let userInput = Object.assign(input, this.registerForm.value);

      // console.log( userInput );

      this.tradeApiService.ApiServices(this.urlProfile, userInput , null).subscribe(( loginDetails:any ) => {
        // do stuff with our data here.
        // ....
  
        // asign data to our class property in the end
        // so it will be available to our template                  
            if(loginDetails.status == 200){  
              this.commonService.showToaster( loginDetails.message , 1 ); 
              localStorage.setItem('user_details',  JSON.stringify(loginDetails.pU));
              this.userDetails = loginDetails.pU;
              this.commonService.userLoggedName = this.userDetails.user_name+' '+this.userDetails.last_user_name;
             
            }
            else{                    
              this.commonService.showToaster( loginDetails.message , 4 ); 
            }

            this.commonService.showLoader = 0;
            
        });
    }

    //password compare

MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        this.confirm = true;
          matchingControl.setErrors({ mustMatch: true });
      } else {
          this.confirm = false;
          matchingControl.setErrors(null);
      }
  }
}


updatePassword() {

  this.submittedPassword = true;

  // stop here if form is invalid
  if (this.passwordUpdateForm.invalid) {
      return;
  }

  let input = {                        
    uK:this.userDetails.user_key,                       
  };  
  
  this.commonService.showLoader = 1;
  
  let userInput = Object.assign(input, this.passwordUpdateForm.value);

  // console.log( userInput );

  this.tradeApiService.ApiServices(this.urlProfileChangePassword, userInput , null).subscribe(( changePassword:any ) => {
    // do stuff with our data here.
    // ....

    // asign data to our class property in the end
    // so it will be available to our template                  
        if(changePassword.status == 200){  
          this.commonService.showToaster( changePassword.message , 1 );   
          this.clearForm();
        }
        else{                    
          const errorValues = Object.keys(changePassword.message).map(key => changePassword.message[key]);
          for(var i=0; i<errorValues.length;i++){                                  
            this.commonService.showToaster( errorValues[i] , 4 ); 
          }
        }

        this.commonService.showLoader = 0;
        
    });


}

/**
 * Clearing the form data for change password
 */

clearForm(){

    this.submittedPassword = false;
    this.passwordUpdateForm.clearValidators();  
    this.passwordUpdateForm.reset();  
    this.passwordUpdateForm.markAsPristine(); 

}

/**
 *  Function for sending the otp for verifying the mobile number
 * 
 */

sendOtpVerifyMobileEmail( eM ){ 

  this.commonService.showLoader = 1;
  var emailOrMobile = '';
  if( eM == 1 ){
    emailOrMobile = this.userDetails.user_email;
    this.showTimeResendOtp = 0;
  }
  if( eM == 2 ){
    emailOrMobile = this.userDetails.user_mobile;
    this.showTimeResendOtp = 0;
  }

  let input = {
    //token:localStorage.getItem('auth_token'),                       
    uK:this.userDetails.user_key,                       
    uM:emailOrMobile,
    _eM: eM                       
  };  

  
  this.tradeApiService.ApiServices(this.urlProfileEmailMobileVerify, input , null).subscribe(( otpDetails:any ) => {
    // do stuff with our data here.
    // ....

    // asign data to our class property in the end
    // so it will be available to our template                  
        if(otpDetails.status == 200){  

          this.ShowTxtMob = 0;
          this.ShowEnterOtpMob = 1;

          this.commonService.showToaster( otpDetails.message , 1 ); 
        }
        else{                    
          this.commonService.showToaster( otpDetails.message , 4 ); 
        }

        this.commonService.showLoader = 0;
        
    });


}

/**
 * Once User Received OPT
 */
OtpVerifyMobileEmail( eM ){

    // console.log( 'OtpVerifyMobile' );
    // console.log( this.mobileNumberOtp );
    this.OtpVerifyMobileError = 0;
    if( this.mobileNumberOtp == '' ){
      this.OtpVerifyMobileError = 1;
      return;
    }

    this.commonService.showLoader = 1;
    
  let input = {
    //token:localStorage.getItem('auth_token'),                       
    uK:this.userDetails.user_key, 
    uO: this.mobileNumberOtp, 
    _eM: eM                                                   
  };  
  
  this.tradeApiService.ApiServices(this.urlProfileEmailMobileOtpVerify, input , null).subscribe(( otpDetails:any ) => {
    // do stuff with our data here.
    // ....

    // asign data to our class property in the end
    // so it will be available to our template                  
        if(otpDetails.status == 200){  

          this.ShowTxtMob = 0;
          this.ShowEnterOtpMob = 0;
          this.is_mobile_verified = 1;

          this.commonService.showToaster( otpDetails.message , 1 ); 
          this.modalService.dismissAll();
          this.getProfileDetails();
        }
        else{                    
          this.OtpVerifyMobileError = 1;
          this.commonService.showToaster( otpDetails.message , 4 ); 
        }

        this.commonService.showLoader = 0;
        
    });


  }
  /**
   * Fecting the profile Details
   */
  
getProfileDetails(){
  
  this.commonService.showLoader = 1;

  let input = {
    //token:localStorage.getItem('auth_token'),                       
    uK:this.userDetails.user_key,                                               
  };  
  
  this.tradeApiService.ApiServices(this.urlProfileDetails, input , null).subscribe(( otpDetails:any ) => {
    // do stuff with our data here.
    // ....

    // asign data to our class property in the end
    // so it will be available to our template                  
        if(otpDetails.status == 200){
          
          this.pSData = otpDetails.pSData;
                    switch (otpDetails.pSData) {
                        case 1:
                            this.pwdPattern = this.commonService.HighpwdPattern;
                            break;
                        case 2:
                            this.pwdPattern = this.commonService.MediumpwdPattern;
                            break;
                        case 3:
                            this.pwdPattern = this.commonService.LowpwdPattern;
                            break;
                        default:
                            this.pwdPattern = this.commonService.HighpwdPattern;
                            break;
                    }
                    this.reintiPasswordForm();

          this.userDetails = otpDetails.pU;
          localStorage.setItem('user_details',  JSON.stringify(otpDetails.pU));
          this.userDetails = otpDetails.pU;
          this.userDocumentDetails = otpDetails.pD;
          this.userAddresstDetails = otpDetails.pK;
          this.imagePathProfile = otpDetails.pU.profile_img;
          this.formValueSet();        
        }
        else{                    
          this.commonService.showToaster( otpDetails.message , 4 ); 
        }

        this.commonService.showLoader = 0;
        
    });


}

  /**
   *  Edit Customer Email
   */

  editCustomerEmail( withOpt = 1 ){
    // console.log( 'editCustomerEmail' );
    this.submittedChangeEmail = true;

    // stop here if form is invalid
    if (this.changeEmailForm.invalid) {
        return;
    }  

    this.commonService.showLoader = 1;  
    this.showTimeResendOtp = 0;

    let input = {                        
      uK:this.userDetails.user_key,
      wOpt: withOpt                       
    };  
    
    
    let userInput = Object.assign(input, this.changeEmailForm.value);

    // console.log( userInput );

    if( withOpt == 2 ){
      let Otpinput = {                        
        oE: this.changeEmailNumberOtp,                       
      };  
      userInput = Object.assign(Otpinput, userInput);
    }
    
    this.tradeApiService.ApiServices(this.urlProfileEmailChange, userInput , null).subscribe(( otpDetails:any ) => {
      // do stuff with our data here.
      // ....
  
      // asign data to our class property in the end
      // so it will be available to our template                  
          if(otpDetails.status == 200){   
            this.newEmaildisabled = 1;
            this.newEmailShowOtp = 1;
            this.commonService.showToaster( otpDetails.message , 1 ); 
            if( withOpt == 2 ){
              this.getProfileDetails();
              this.modalService.dismissAll();
            }

          }
          else{       
            this.showTimeResendOtp = 1;             
            const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
            for(var i=0; i<errorValues.length;i++){                                  
              this.commonService.showToaster( errorValues[i] , 4 ); 
            }
          }  
          this.commonService.showLoader = 0;          
      });   
  }

  /**
   *  Edit Customer Mobile
   */

  editMobileNumber( withOpt = 1 ){
    // console.log( 'changeMobileForm' );
    this.submittedChangeMobile = true;

    // stop here if form is invalid
    if (this.changeMobileForm.invalid) {
        return;
    }  

    this.showTimeResendOtp = 0;

    this.commonService.showLoader = 1;  

    let input = {                        
      uK:this.userDetails.user_key,
      wOpt: withOpt                       
    };  
    
    
    let userInput = Object.assign(input, this.changeMobileForm.value);

    // console.log( userInput );

    if( withOpt == 2 ){
      let Otpinput = {                        
        oE: this.changeMobileNumberOtp,                       
      };  
      userInput = Object.assign(Otpinput, userInput);
    }
    
    this.tradeApiService.ApiServices(this.urlProfileMobileChange, userInput , null).subscribe(( otpDetails:any ) => {
      // do stuff with our data here.
      // ....
  
      // asign data to our class property in the end
      // so it will be available to our template                  
          if(otpDetails.status == 200){   
            this.newMobiledisabled = 1;
            this.newMobileShowOtp = 1;
            this.commonService.showToaster( otpDetails.message , 1 ); 
            if( withOpt == 2 ){
              this.getProfileDetails();
              this.modalService.dismissAll();
            }

          }
          else{ 
            this.showTimeResendOtp = 1;                   
            const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
            for(var i=0; i<errorValues.length;i++){                                  
              this.commonService.showToaster( errorValues[i] , 4 ); 
            }
          }  
          this.commonService.showLoader = 0;          
      });   
  }
/**
 * verify Change Email Otp
 */

verifyChangeEmailOtp(){
  this.changeEmailNumberOtpError = 0;
    
    if( this.changeEmailNumberOtp == '' ){
      this.changeEmailNumberOtpError = 1;
      return; 
    }
    console.log( this.changeEmailNumberOtp, this.changeEmailNumberOtpError )
    this.editCustomerEmail(2);
}

/**
 * verify Change Mobile Otp
 */

verifyChangeMobileOtp(){
  this.changeMobileNumberOtpError = 0;
    
    if( this.changeMobileNumberOtp == '' ){
      this.changeMobileNumberOtpError = 1;
      return; 
    }
    // console.log( this.changeMobileNumberOtp, this.changeMobileNumberOtpError )
    this.editMobileNumber(2);
}

clearFormEmail(){ 

  this.submittedChangeEmail = false;
  this.changeEmailForm.clearValidators();  
  this.changeEmailForm.reset();  
  this.changeEmailForm.markAsPristine(); 

  this.newEmaildisabled = 0;
  this.newEmailShowOtp = 0;
  this.changeEmailNumberOtp = '';
  this.changeEmailNumberOtpError = 0;
  this.showTimeResendOtp = 0;


}

clearFormMobile(){ 

  this.submittedChangeMobile = false;
  this.changeMobileForm.clearValidators();  
  this.changeMobileForm.reset();  
  this.changeMobileForm.markAsPristine(); 

  this.newMobiledisabled = 0;
  this.newMobileShowOtp = 0;
  this.changeMobileNumberOtp = '';
  this.changeMobileNumberOtpError = 0;


}

changeNamePopOtp( showLab ){
  // console.log( showLab )

  this.ShowTxtMob = 1;
  this.ShowEnterOtpMob = 0;


  this.otpShowLabChange = showLab;
}

numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;

}

onSelect(imageEvents) {    
  this.imagePathtmp = imageEvents;
}
onReset() {
  console.log('onReset');
}


  // uploading the profile / img url to the image tag

  uploadProfileImg() {

    this.commonService.showLoader = 1;
  
   let input = {
    //token:localStorage.getItem('auth_token'),                       
    uK:this.userDetails.user_key, 
    u_Pi: this.imagePathtmp                                              
  };  
  
  this.tradeApiService.ApiServices(this.urlProfileImg, input , null).subscribe(( profileImg:any ) => {
    // do stuff with our data here.
    // ....

    // asign data to our class property in the end
    // so it will be available to our template                  
        if(profileImg.status == 200){  
            this.imagePathProfile = profileImg.profile;    
            this.commonService.userProfileImg = profileImg.profile;
            this.commonService.showToaster( profileImg.message , 1 ); 
            this.getProfileDetails();
            this.modalService.dismissAll();
        }
        else{                    
          this.commonService.showToaster( profileImg.message , 4 ); 
        }

        this.commonService.showLoader = 0;
        
    });
  }

  removeProfileImage(){    
    this.commonService.showLoader = 1;
    let input = {
      //token:localStorage.getItem('auth_token'),                       
      user_key:this.userDetails.user_key                                               
    };  
    this.tradeApiService.ApiServices(this.urlDeleteProfileImg, input , null).subscribe(( profileImg:any ) => {                    
          if(profileImg.status == 200){                
              this.imagePathProfile = profileImg.profile;    
              this.commonService.userProfileImg = profileImg.profile;
              this.commonService.showToaster( profileImg.message , 1 ); 
              this.getProfileDetails();
              this.modalService.dismissAll();
          }
          else{                    
            this.commonService.showToaster( profileImg.message , 4 ); 
          }  
          this.commonService.showLoader = 0;          
      });
    }

    timeLeftHandleEvent(events) {
      if (events.action == 'done') {
        this.showTimeResendOtp = 1;  
      }
  }

 }
