import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ChartsModule, ThemeService } from 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageContentComponent } from './layout/page-content/page-content.component';
import { GuestTopNavComponent } from './layout/guest/guest-top-nav/guest-top-nav.component';
import { GuestFooterComponent } from './layout/guest/guest-footer/guest-footer.component';
import { GuestLayoutComponent } from './layout/guest/guest-layout/guest-layout.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { AuthorisedSideNavComponent } from './layout/authorised/authorised-side-nav/authorised-side-nav.component';
import { AuthorisedLayoutComponent } from './layout/authorised/authorised-layout/authorised-layout.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthorisedTopNavComponent } from './layout/authorised/authorised-top-nav/authorised-top-nav.component';
import { LoginComponent } from './pages/login/login.component';
import { NewAccountRegisterComponent } from './pages/new-account-register/new-account-register.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { ClientUserProfileComponent } from './pages/client-user-profile/client-user-profile.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

// for image crop
import { NgxImgModule } from 'ngx-img';
import { ClientDemoAccountsDetailsComponent } from './pages/client-demo-accounts-details/client-demo-accounts-details.component';
import { ClientLiveAccountsDetailsComponent } from './pages/client-live-accounts-details/client-live-accounts-details.component';

// for notification
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

// for image view
import { LightboxModule } from 'ngx-lightbox';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { LogoutComponent } from './pages/logout/logout.component';
import { FileuploadComponent } from './pages/fileupload/fileupload.component';
// import { FileSelectDirective } from 'ng2-file-upload';
import { MyPositionsComponent } from './pages/my-positions/my-positions.component';
import { MyHistoryComponent } from './pages/my-history/my-history.component';
import { BackOfficeLiveAccountRequestComponent } from './pages/back-office-live-account-request/back-office-live-account-request.component';
import { CountdownModule } from 'ngx-countdown';
import { CountryComponent } from './pages/country/country.component';
import { BackOfficeKycListsComponent } from './pages/back-office-kyc-lists/back-office-kyc-lists.component';
import { AccountDetailsComponent } from './pages/account-details/account-details.component';
import { BoLivePostionAccountDetailsComponent } from './pages/bo-live-postion-account-details/bo-live-postion-account-details.component';
import { CityComponent } from './pages/city/city.component';
import { BranchComponent } from './pages/branch/branch.component';
import { BoLiveTradeHistoryDetailsComponent } from './pages/bo-live-trade-history-details/bo-live-trade-history-details.component';
import { DemoAccountDetailsComponent } from './pages/demo-account-details/demo-account-details.component';
import { BoDemoPostionAccountDetailsComponent } from './pages/bo-demo-postion-account-details/bo-demo-postion-account-details.component';
import { BoDemoTradeHistoryDetailsComponent } from './pages/bo-demo-trade-history-details/bo-demo-trade-history-details.component';
import { DesignationComponent } from './pages/designation/designation.component';
import { Mt5AccountsComponent } from './pages/mt5-accounts/mt5-accounts.component';
import { BackOfficeKycViewComponent } from './pages/back-office-kyc-view/back-office-kyc-view.component';
import { BoTransactionWithdrawalDepositeComponent } from './pages/bo-transaction-withdrawal-deposite/bo-transaction-withdrawal-deposite.component';
import { BoTransactionQueueListWithdrawalDepositeComponent } from './pages/bo-transaction-queue-list-withdrawal-deposite/bo-transaction-queue-list-withdrawal-deposite.component';
import { GroupComponent } from './pages/group/group.component';
import { LeverageComponent } from './pages/leverage/leverage.component';
import { BalanceComponent } from './pages/balance/balance.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ChangeClientInfoComponent } from './pages/change-client-info/change-client-info.component';
import { EmployeesComponent } from './pages/employees/employees.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { RolesComponent } from './pages/roles/roles.component';
import { ClientDashboardComponent } from './pages/client-dashboard/client-dashboard.component';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { AdminProfilePageComponent } from './pages/admin-profile-page/admin-profile-page.component';

// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import { PackageComponent } from './pages/package/package.component';
import { ActivityLogsComponent } from './pages/activity-logs/activity-logs.component';
import { IndividualActivityLogComponent } from './pages/individual-activity-log/individual-activity-log.component';
import { LanguagesComponent } from './pages/languages/languages.component';
import { Mt5ActivityLogsComponent } from './pages/mt5-activity-logs/mt5-activity-logs.component';

// for color picker
import { ColorPickerModule } from 'ngx-color-picker';
import { ElementsComponent } from './pages/elements/elements.component';
import { ElementLogoComponent } from './pages/element-logo/element-logo.component';
import { ElementColorsComponent } from './pages/element-colors/element-colors.component';
import { DepositWithdrawalComponent } from './pages/deposit-withdrawal/deposit-withdrawal.component';
import { BalanceTransferComponent } from './pages/balance-transfer/balance-transfer.component';

@NgModule({
  declarations: [
    AppComponent,
    PageContentComponent,
    GuestTopNavComponent,
    GuestFooterComponent,
    GuestLayoutComponent,
    LandingPageComponent,
    AuthorisedSideNavComponent,
    AuthorisedLayoutComponent,
    DashboardComponent,
    AuthorisedTopNavComponent,
    LoginComponent,
    NewAccountRegisterComponent,
    ForgetPasswordComponent,
    ClientUserProfileComponent,
    ClientDemoAccountsDetailsComponent,
    ClientLiveAccountsDetailsComponent,
    LogoutComponent,    
    // FileSelectDirective,    
    MyPositionsComponent,
    MyHistoryComponent,
    BackOfficeLiveAccountRequestComponent,
    CountryComponent,
    BackOfficeKycListsComponent,
    AccountDetailsComponent,
    BoLivePostionAccountDetailsComponent,
    CityComponent,
    BranchComponent,
    BoLiveTradeHistoryDetailsComponent,
    DemoAccountDetailsComponent,
    BoDemoPostionAccountDetailsComponent,
    BoDemoTradeHistoryDetailsComponent,
    DesignationComponent,
    Mt5AccountsComponent,
    BackOfficeKycViewComponent,
    BoTransactionWithdrawalDepositeComponent,
    BoTransactionQueueListWithdrawalDepositeComponent,
    GroupComponent,
    LeverageComponent,
    BalanceComponent,
    SettingsComponent,
    ChangeClientInfoComponent,
    EmployeesComponent,
    CustomersComponent,
    RolesComponent,
    ClientDashboardComponent,
    AdminDashboardComponent,
    AdminProfilePageComponent,
    FileuploadComponent,
    PackageComponent,
    ActivityLogsComponent,
    IndividualActivityLogComponent,
    LanguagesComponent,
    Mt5ActivityLogsComponent,
    ElementsComponent,
    ElementLogoComponent,
    ElementColorsComponent,
    DepositWithdrawalComponent,
    BalanceTransferComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ChartsModule,
    NgxImgModule.forRoot(),
    NgbModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 15000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      tapToDismiss: true,
      progressBar: true,
      closeButton: true
    }), // ToastrModule added
    LightboxModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule ,
    HttpClientJsonpModule,
    CountdownModule,
    CarouselModule,
    ColorPickerModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
    
  ],
  providers: [ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}