import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';

import {TranslateService} from '@ngx-translate/core';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

@Component({
  selector: 'app-demo-account-details',
  templateUrl: './demo-account-details.component.html',
  styleUrls: ['./demo-account-details.component.scss']
})
export class DemoAccountDetailsComponent implements OnInit {

 


  closeResult: string;
  modalReference;
  userDetails;
  userliveAccountDetails;


  urlGetliveAccountDetails = 'get-bo-demo-account-details';
  urlGetliveSingleMt5AccountDetails = 'get-live-mt5-account-details';
  userMt5liveAccountDetails;
  userMt5liveAccountDetailsTrade;
  leverage = 0;  

  page = 1;
  totalItems = 0;
  itemsPerPage = 10;

  getSearchDetails = '';
  getAccountCreationDate;

  getGroupDetails;
  
   
  @ViewChild('contentliveAccountsDetails', {static: false}) private contentliveAccountsDetails: ElementRef; 

  constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private translate: TranslateService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) { 
    
    this.AuthorisedLayoutComponent.getRouteAccess();
    translate.setDefaultLang( this.commonService.defaultLanguale );
   }

  /**
   * Menu Enable (or) Disable
   */

  getMenuPermission(mTy) {
      return this.commonService.getMenuPermission('42b3ebf7aaf50ff5de5d', mTy);
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('user_details'));
    this.getliveAccountDetails();
  }

  open(content, sizeS) {
    //this.modalReference = this.modalService.open(content);
    this.modalService.open(content, { size: sizeS, ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }


  dateFormates( dateObject ){
    let d = new Date(dateObject);
    let day:any = dateObject.day;
    let month:any = dateObject.month;
    let year = dateObject.year;
    if (day < 10) {
        day = "0" + day;
    }
    if (month < 10) {
        month = "0" + month;
    }
    let date = year + "-" + month + "-" + day;

    return date;
  }


  getliveAccountDetails(){
    console.log( 'getliveAccountDetails' );
    this.commonService.showLoader = 1;
    
    var AccountCreationDate;
  console.log( this.getAccountCreationDate );
    if( this.getAccountCreationDate !== undefined && this.getAccountCreationDate !== null ){
      // AccountCreationDate = this.getAccountCreationDate.year+'-'+this.getAccountCreationDate.month+'-'+this.getAccountCreationDate.day;
      AccountCreationDate = this.dateFormates( this.getAccountCreationDate );
    }
   

    let input = {
      //token:localStorage.getItem('auth_token'),                       
      uK: this.userDetails.user_key,
      _dL: 1,
      page:this.page,
      _sDl: this.getSearchDetails, 
      _sDat: AccountCreationDate
    };

    this.tradeApiService.ApiServices(this.urlGetliveAccountDetails, input, null).subscribe((otpDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (otpDetails.status == 200) {
        console.log( otpDetails );
        this.userliveAccountDetails = otpDetails.aDc.accountDetails;       
        this.totalItems = otpDetails.aDc.accountTotal;       
      }
      else {
        const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
        for(var i=0; i<errorValues.length;i++){                                  
          this.commonService.showToaster( errorValues[i] , 4 ); 
        }
      }

      this.commonService.showLoader = 0;

    });

  }


  viewMt5Details( mt5Login ){
    console.log( mt5Login )
    
    this.commonService.showLoader = 1;

    let input = {                            
      uK: this.userDetails.user_key,
      _dL: 1,
      _dM: mt5Login,
    };

    this.tradeApiService.ApiServices(this.urlGetliveSingleMt5AccountDetails, input, null).subscribe((otpDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (otpDetails.status == 200) {
        console.log( otpDetails );  
        this.userMt5liveAccountDetails = otpDetails.aDd;
        this.userMt5liveAccountDetailsTrade = otpDetails.aDdAccnts;
        this.open( this.contentliveAccountsDetails,'xl' );
      }
      else {
        const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
        for(var i=0; i<errorValues.length;i++){                                  
          this.commonService.showToaster( errorValues[i] , 4 ); 
        }
      }

      this.commonService.showLoader = 0;

    });


  }

  loadPage(page: number) {
   
    this.page = page;
    this.getliveAccountDetails();
  }

  getAccountDetailClearSearch(){
    this.getSearchDetails = ""
    this.getAccountCreationDate = undefined;
    this.getliveAccountDetails();
  }



}
