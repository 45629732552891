import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

declare var $: any;

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})

export class EmployeesComponent implements OnInit {

    userDetails;
    urlGetEmployeeDetails = 'get-employee-lists';
    urlAddEditEmployee = 'add-edit-employee';
    urlBlockUnblockEmployee = 'block-unblock-employee';
    urlGetIndividualEmployee = 'get-individual-employee-details';
    employeesList; //for all employee lists
    countryList; //for all branch lists
    reportingTo; //for all reporting to
    branchList; //for all branch lists
    designationList; //for all designation lists
    roleList; //for all role lists
    closeResult: string;
    employeeData; //for block & unblock
    individualEmployeeData; //individual employees details stored for edit employees modal
    employeeDeatiledView;
    title; //Modal Popup header Title
    buttonName; //Modal Popup Button Name
    blockUnblockTitle;

    addEditEmployeeForm: FormGroup;
    addEditSubmitted = false;

    selectReportingTo = false;
    selectCountry = false;
    selectBranch = false;
    selectDesignation = false;

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    searchInputData = '';
    searchRT = '';
    searchBK = '';
    searchDK = '';

    @ViewChild('contentAddNewEmployee', {static: false}) private contentAddNewEmployee: ElementRef;
    @ViewChild('blockUnblockEmployee', {static: false}) private blockUnblockEmployee: ElementRef;
    @ViewChild('employeeDetailedView', {static: false}) private employeeDetailedView: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) {
        this.AuthorisedLayoutComponent.getRouteAccess();
     }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(mTy) {
        return this.commonService.getMenuPermission('a1f078bfc60af4ae7539', mTy);
    }

    ngOnInit() {

        this.getEmployeeLists();

        this.userDetails = JSON.parse( localStorage.getItem('user_details'));

        this.addEditEmployeeForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(/^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
            country: ['', [Validators.required]],
            mobile: ['', [Validators.required, Validators.pattern('^[0-9\-\']+')]],
            reportingTo: ['', [Validators.required]],
            branch: ['', [Validators.required]],
            designation: ['', [Validators.required]],
            userRole: ['', [Validators.required]],
            uKey: ['', []],
        });
    }

    open(content, sizeS, modalTitleID) {
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {

        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    /**
     * Fecting Employee Lists
     */

    getEmployeeLists() {

        this.commonService.showLoader = 1;

        let input = {
            page: this.page,
            _sDl: this.searchInputData,
            _sRT: this.searchRT,
            _sBK: this.searchBK,
            _sDK: this.searchDK,
        };

        this.tradeApiService.ApiServices(this.urlGetEmployeeDetails, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.employeesList = responseData.employeesList.Records;
                this.totalItems = responseData.employeesList.TotalCount;
                this.reportingTo = responseData.reportingTo;
                this.branchList = responseData.branchList;
                this.designationList = responseData.designationList;
                this.countryList = responseData.countryList;
                this.roleList = responseData.userRoleList;

                this.searchfilterDesignation(this.searchBK);
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    searchfilterDesignation(branchKey) {

        $(document).ready(function() {
            var val = branchKey;
            var sub = $('#sub_groups');
            $('option', sub).filter(function() {
                if ( $(this).attr('data-group') === val || $(this).attr('data-group') === 'SHOW' || val == '') {
                    if ($(this).parent('span').length) {
                        $(this).unwrap();
                    }

                    if(val == '') {
                        sub.val('');
                    }
                } else {
                    if (!$(this).parent('span').length) {
                        $(this).wrap( "<span>" ).parent().hide();
                    }
                }
            });
        });
    }

    formfilterDesignation(branchKey, type) {
        
        $(document).ready(function() {
            var val = branchKey;
            var sub = $('#formSubGroup');
            $('option', sub).filter(function() {
                if ( $(this).attr('data-group') === val || $(this).attr('data-group') === 'SHOW') {
                    if ($(this).parent('span').length) {
                        $(this).unwrap();
                    }
                } else {
                    if (!$(this).parent('span').length) {
                        $(this).wrap( "<span>" ).parent().hide();
                    }
                }
            });
        });

        if(type == 'add') {
            this.addEditEmployeeForm.controls['designation'].setValue('');
        }
    }

    /**
     * Load pagination next page
     */

    loadPage(page: number) {   
        this.page = page;
        this.getEmployeeLists();
    }

    /**
     * Add Employee Modal Popup
     */

    addNewEmployeeModal() {
        this.resetForm();
        this.open(this.contentAddNewEmployee, 'x', 'modal-basic-title');
        this.title = 'Add New';
        this.buttonName = 'Add';
    }

    /**
     * Reset add Employee form values and error messages
     */

    resetForm() {
        this.addEditSubmitted = false;
        this.addEditEmployeeForm.clearValidators();
        this.addEditEmployeeForm.reset();
        this.addEditEmployeeForm.markAsPristine();

        this.selectReportingTo = true;
        this.selectCountry = true;
        this.selectBranch = true;
        this.selectDesignation = true;
        this.addEditEmployeeForm.controls['country'].setValue('');
        this.addEditEmployeeForm.controls['reportingTo'].setValue('');
        this.addEditEmployeeForm.controls['branch'].setValue('');
        this.addEditEmployeeForm.controls['designation'].setValue('');
        this.addEditEmployeeForm.controls['userRole'].setValue('');
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
    }

    /**
     * Angular error message form control
     */

    get f() { return this.addEditEmployeeForm.controls; } //Add Employee Error Controller

    /**
     * Add & Edit Employee form Submit
     */

    addEditEmployeeSubmit() {

        this.addEditSubmitted = true;

        // stop here if form is invalid
        if (this.addEditEmployeeForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.addEditEmployeeForm.value);

        this.tradeApiService.ApiServices(this.urlAddEditEmployee, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.getEmployeeLists();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for(var i=0; i<errorValues.length;i++){                                  
                    this.commonService.showToaster( errorValues[i] , 4 ); 
                }  
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Block & Unblock Employee Modal Popup
     */

    blockUnblock(data) {
        this.employeeData = data;
        
        this.blockUnblockTitle = (data.user_status == 0) ? 'Unblock' : 'Block';

        this.open(this.blockUnblockEmployee, 'x', 'modal-blockunblock-title');
    }

    confirmBlockUnblock() {
        
        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            uKey: this.employeeData.user_key,
            uS: this.employeeData.user_status,
        };

        let userInput = Object.assign(input);

        this.tradeApiService.ApiServices(this.urlBlockUnblockEmployee, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.employeeData = null;
                this.getEmployeeLists();
            } else {
                this.commonService.showToaster( responseData.message , 4 );
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Get Individual Employee Detail
     */

    getIndividualEmployee(data) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            uKey: data.user_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualEmployee, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {

                this.open(this.contentAddNewEmployee, 'x', 'modal-basic-title');
                this.title = 'Edit';
                this.buttonName = 'Update';

                this.individualEmployeeData = responseData.uD;

                var mobile = this.individualEmployeeData.mobile.replace(this.individualEmployeeData.phonecode,'');

                this.addEditEmployeeForm.controls['uKey'].setValue(data.user_key);
                this.addEditEmployeeForm.controls['name'].setValue(this.individualEmployeeData.name);
                this.addEditEmployeeForm.controls['email'].setValue(this.individualEmployeeData.email);
                this.addEditEmployeeForm.controls['country'].setValue(this.individualEmployeeData.phonecode+'-'+this.individualEmployeeData.country);
                this.addEditEmployeeForm.controls['mobile'].setValue(mobile);
                this.addEditEmployeeForm.controls['reportingTo'].setValue(this.individualEmployeeData.reporting_to);
                this.addEditEmployeeForm.controls['branch'].setValue(this.individualEmployeeData.branch_key);
                this.addEditEmployeeForm.controls['designation'].setValue(this.individualEmployeeData.designation_key);
                this.addEditEmployeeForm.controls['userRole'].setValue(this.individualEmployeeData.user_role_key);

                this.formfilterDesignation(this.individualEmployeeData.branch_key, 'edit');

            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Get Employee Detailed View
     */

    getEmployeeDetailedView(data) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            uKey: data.user_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualEmployee, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {

                this.open(this.employeeDetailedView, 'xl', 'modal-basic-title');

                this.employeeDeatiledView = responseData.uD;

            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Clear Search Data's
     */

    clearSearchData() {
        this.searchInputData = '';
        this.searchRT = '';
        this.searchBK = '';
        this.searchDK = '';
        this.getEmployeeLists();
    }
}
