import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';

import { TranslateService } from '@ngx-translate/core';

import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';


@Component({
    selector: 'app-bo-transaction-withdrawal-deposite',
    templateUrl: './bo-transaction-withdrawal-deposite.component.html',
    styleUrls: ['./bo-transaction-withdrawal-deposite.component.scss']
})
export class BoTransactionWithdrawalDepositeComponent implements OnInit {

    closeResult: string;
    modalReference;
    userDetailsAccount;

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    userDetails;
    userDepWithRAccountDetails;
    userMt5AccountDetails;

    urlCreateDetails = 'create-transaction-request';
    urlgetRequestedDepositeWithdDetails = 'get-deposite-withdrawal-details';
    urlGetSingleMt5AccountDetails = 'get-mt5-account-details';

    // New  ;
    loginFind = 0;
    convertNumberToWd = '';
    transactionDepWdAmt = 0;
    transactionTypes = 0;
    getTransactionLogins = '';

    transactionDepWdAmtErr = 0;
    transactionTypesErr = 0;
    getTransactionLoginsErr = 0;
    getTransactionLoginsFindErr = 0;

    getSearchDetails = '';
    gettransactionCreationDate;
    searchtransactionTypes = 0;
    statustransactionTypes = 0;

    // New

    @ViewChild('contentDepositeWithdrawalAccounts', { static: false }) private contentDepositeWithdrawalAccounts: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private translate: TranslateService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) {
        this.AuthorisedLayoutComponent.getRouteAccess();
        translate.setDefaultLang(this.commonService.defaultLanguale);
    }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(mTy) {
        return this.commonService.getMenuPermission('2c9282b41192ba9f49c9', mTy);
    }

    ngOnInit() {
        this.userDetails = JSON.parse(localStorage.getItem('user_details'));
        this.getRequestedDepositeWithdDetails();
    }

    open(content, sizeS) {
        //this.modalReference = this.modalService.open(content);
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    createDemoAccount() {        
        this.clearData();
        this.open(this.contentDepositeWithdrawalAccounts, 'sl');        
    }


    createDepositeWithDrawalRequest() {

        // console.log('createDepositeWithDrawalRequest');
        // console.log(this.transactionDepWdAmt, this.transactionTypes, this.getTransactionLogins)

        this.transactionDepWdAmtErr = 0;
        this.transactionTypesErr = 0;
        this.getTransactionLoginsErr = 0;
        this.getTransactionLoginsFindErr = 0;

        if (this.transactionDepWdAmt == 0) {
            this.transactionDepWdAmtErr = 1;
        }
        if (this.transactionTypes == 0) {
            this.transactionTypesErr = 1;
        }
        if (this.getTransactionLogins == undefined) {
            this.getTransactionLoginsErr = 1;
        }

        if (this.loginFind == 0) {
            this.getTransactionLoginsFindErr = 1;
        }


        if (this.transactionDepWdAmtErr == 0 && this.transactionTypesErr == 0 && this.getTransactionLoginsErr == 0 && this.getTransactionLoginsFindErr == 0) {
            this.commonService.showLoader = 1;

            let input = {
                //token:localStorage.getItem('auth_token'),                       
                uK: this.userDetails.user_key,
                _cAmt: this.transactionDepWdAmt,
                _cTy: this.transactionTypes,
                _cLg: this.getTransactionLogins,
            };

            this.tradeApiService.ApiServices(this.urlCreateDetails, input, null).subscribe((otpDetails: any) => {
                // do stuff with our data here.
                // ....

                // asign data to our class property in the end
                // so it will be available to our template                  
                if (otpDetails.status == 200) {
                    console.log(otpDetails);
                    this.closeModal();
                    this.getRequestedDepositeWithdDetails();
                    this.commonService.showToaster(otpDetails.message, 1);
                }
                else {
                    this.commonService.showToaster(otpDetails.message, 4);
                }

                this.commonService.showLoader = 0;

            });


        }


    }

    dateFormates(dateObject) {
        let d = new Date(dateObject);
        let day: any = dateObject.day;
        let month: any = dateObject.month;
        let year = dateObject.year;
        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        let date = year + "-" + month + "-" + day;

        return date;
    }

    getRequestedDepositeWithdDetails() {
        // console.log('getRequestedDepositeWithdDetails');
        this.commonService.showLoader = 1;

        var gettransactionCreationDate;
        console.log(this.gettransactionCreationDate);
        if (this.gettransactionCreationDate !== undefined && this.gettransactionCreationDate !== null) {
            gettransactionCreationDate = this.dateFormates(this.gettransactionCreationDate);
        }

        let input = {
            //token:localStorage.getItem('auth_token'),                       
            uK: this.userDetails.user_key,
            _dL: 1,
            page: this.page,
            _sSdt: this.getSearchDetails,
            _sDat: gettransactionCreationDate,
            _Sty: this.searchtransactionTypes,
            _Sts: this.statustransactionTypes,
            _uType: this.userDetails.user_type
        };

        this.tradeApiService.ApiServices(this.urlgetRequestedDepositeWithdDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (otpDetails.status == 200) {
                console.log(otpDetails);
                this.userDepWithRAccountDetails = otpDetails.aDc.DW_details;
                this.totalItems = otpDetails.aDc.Total;
                localStorage.setItem('uTlCount', JSON.stringify(otpDetails.uTlCount));
                this.commonService.uTlCount = otpDetails.uTlCount;
            }
            else {
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }

            this.commonService.showLoader = 0;

        });

    }

    clearData() {

        this.getTransactionLogins = '';
        this.loginFind = 0;
        this.transactionDepWdAmt = 0;
        this.transactionTypes = 0;
        this.convertNumberToWd = '';
        this.userMt5AccountDetails = [];
        this.transactionDepWdAmtErr = 0;
        this.transactionTypesErr = 0;
        this.getTransactionLoginsErr = 0;
        this.getTransactionLoginsFindErr = 0;

    }

    viewMt5Details() {

        this.commonService.showLoader = 1;

        var info;
        this.translate.get('transactionDepositWithdrawl.info').subscribe(lang => {
            info = lang;
        });

        var AlreadyYour;
        this.translate.get('transactionDepositWithdrawl.AlreadyYour').subscribe(lang => {
            AlreadyYour = lang;
        });

        var DepositeWithdrawlRequestProcessing;
        this.translate.get('transactionDepositWithdrawl.DepositeWithdrawlRequestProcessing').subscribe(lang => {
            DepositeWithdrawlRequestProcessing = lang;
        });

        var close;
        this.translate.get('transactionDepositWithdrawl.close').subscribe(lang => {
            close = lang;
        });


        let input = {
            uK: this.userDetails.user_key,
            _dL: 1,
            _dM: this.getTransactionLogins,
        };

        this.tradeApiService.ApiServices(this.urlGetSingleMt5AccountDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template   

            if (otpDetails.alreadyInPend == 1) {

                Swal.fire({
                    title: info,
                    text: AlreadyYour + ' ' + otpDetails.aDd.Login + ' ' + DepositeWithdrawlRequestProcessing,
                    type: 'info',
                    confirmButtonText: close
                })
                this.closeModal();
                this.getRequestedDepositeWithdDetails();

            }


            if (otpDetails.find == 0) {
                this.clearData();
                this.getTransactionLoginsFindErr = 1;
            }
            if (otpDetails.status == 200) {
                // console.log(otpDetails);
                this.getTransactionLoginsFindErr = 0;
                this.userMt5AccountDetails = otpDetails.aDd;
                this.loginFind = otpDetails.find;
            }
            else {
                this.getTransactionLoginsFindErr = 1;
                this.loginFind = 0;
                this.userMt5AccountDetails = [];
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }

            this.commonService.showLoader = 0;

        });


    }

    numberToEnglish(n, custom_join_character) {

        var string = n.toString(),
            units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words;

        var and = custom_join_character || 'and';

        /* Is number zero? */
        if (parseInt(string) === 0) {
            return 'zero';
        }

        /* Array of units as words */
        units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];

        /* Array of tens as words */
        tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

        /* Array of scales as words */
        scales = ['', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion'];

        /* Split user arguemnt into 3 digit chunks from right to left */
        start = string.length;
        chunks = [];
        while (start > 0) {
            end = start;
            chunks.push(string.slice((start = Math.max(0, start - 3)), end));
        }

        /* Check if function has enough scale words to be able to stringify the user argument */
        chunksLen = chunks.length;
        if (chunksLen > scales.length) {
            return '';
        }

        /* Stringify each integer in each chunk */
        words = [];
        for (i = 0; i < chunksLen; i++) {

            chunk = parseInt(chunks[i]);

            if (chunk) {

                /* Split chunk into array of individual integers */
                ints = chunks[i].split('').reverse().map(parseFloat);

                /* If tens integer is 1, i.e. 10, then add 10 to units integer */
                if (ints[1] === 1) {
                    ints[0] += 10;
                }

                /* Add scale word if chunk is not zero and array item exists */
                if ((word = scales[i])) {
                    words.push(word);
                }

                /* Add unit word if array item exists */
                if ((word = units[ints[0]])) {
                    words.push(word);
                }

                /* Add tens word if array item exists */
                if ((word = tens[ints[1]])) {
                    words.push(word);
                }

                /* Add 'and' string after units or tens integer if: */
                if (ints[0] || ints[1]) {

                    /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
                    if (ints[2] || !i && chunksLen) {
                        words.push(and);
                    }

                }

                /* Add hundreds word if array item exists */
                if ((word = units[ints[2]])) {
                    words.push(word + ' hundred');
                }

            }

        }

        return words.reverse().join(' ');

    }

    convertNumberToWord() {
        let x = this.numberToEnglish(this.transactionDepWdAmt, '');

        if (x.substring(0, 3) == 'and') {
            x = x.slice(3);
        }
        console.log(x);
        this.convertNumberToWd = x;
    }

    loadPage(page: number) {

        this.page = page;
        this.getRequestedDepositeWithdDetails();
    }

    getAccountDetailClearSearch() {


        this.searchtransactionTypes = 0;
        this.statustransactionTypes = 0;
        this.getSearchDetails = ""
        this.gettransactionCreationDate = undefined;
        this.getRequestedDepositeWithdDetails();
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }


}
