import { Component, OnInit } from '@angular/core';
// import { FileSelectDirective, FileDropDirective, FileUploader } from 'ng2-file-upload/ng2-file-upload';
const URL = "http://127.0.0.1:8000/api/test";
@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss']
})
export class FileuploadComponent implements OnInit {
  
  // public uploader:FileUploader = new FileUploader({url: URL});
  public hasBaseDropZoneOver:boolean = false;
  public hasAnotherDropZoneOver:boolean = false;
  response:string;
 
  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }
 
  public fileOverAnother(e:any):void {
    this.hasAnotherDropZoneOver = e;
  }

  constructor() { 
    /*
    this.uploader = new FileUploader({
    url: URL,
    disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
    formatDataFunctionIsAsync: true,
    formatDataFunction: async (item) => {
      return new Promise( (resolve, reject) => {
        resolve({
          name: item._file.name,
          length: item._file.size,
          contentType: item._file.type,
          date: new Date()
        });
      });
    }
  });

  this.hasBaseDropZoneOver = false;
  this.hasAnotherDropZoneOver = false;

  this.response = '';

  this.uploader.response.subscribe( res => this.response = res );*/
}

  ngOnInit() {
  }  
}


