import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

@Component({
    selector: 'app-languages',
    templateUrl: './languages.component.html',
    styleUrls: ['./languages.component.scss']
})

export class LanguagesComponent implements OnInit {

    userDetails;
    urlGetLanguageDetails = 'get-language-lists';
    urlAddEditLanguage = 'add-edit-language';
    urlBlockUnblockLanguage = 'block-unblock-language';
    urlGetIndividualLanguage = 'get-individual-language-details';
    languageLists; // For all Language lists
    closeResult: string;
    languageData; // For block & unblock
    individualLanguageData; // Individual Language details stored for edit Language modal
    title; // Modal Popup header Title
    buttonName; // Modal Popup Button Name
    blockUnblockTitle;

    addEditLanguageForm: FormGroup;
    addEditSubmitted = false;

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    searchInputData = '';

    @ViewChild('contentAddNewLanguage', { static: false }) private contentAddNewLanguage: ElementRef;
    @ViewChild('blockUnblockLanguage', { static: false }) private blockUnblockLanguage: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) {
        this.AuthorisedLayoutComponent.getRouteAccess();
    }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(mTy) {
        return this.commonService.getMenuPermission('c373763d0cce5bf3d31e', mTy);
    }

    ngOnInit() {

        this.getLanguageLists();

        this.userDetails = JSON.parse(localStorage.getItem('user_details'));

        this.addEditLanguageForm = this.formBuilder.group({
            languageName: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
            languageIsoName: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
            lK: ['', []], // Language Key
        });
    }

    open(content, sizeS, modalTitleID) {
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    /**
     * Fecting Language Lists
     */

    getLanguageLists() {

        this.commonService.showLoader = 1;

        let input = {
            page: this.page,
            _sDl: this.searchInputData,
        };

        this.tradeApiService.ApiServices(this.urlGetLanguageDetails, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.languageLists = responseData.languageLists.Records;
                this.totalItems = responseData.languageLists.TotalCount;
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Load pagination next page
     */

    loadPage(page: number) {
        this.page = page;
        this.getLanguageLists();
    }

    /**
     * Add Language Modal Popup
     */

    addNewLanguageModal() {
        this.resetForm();
        this.open(this.contentAddNewLanguage, 'x', 'modal-basic-title');
        this.title = 'Add New';
        this.buttonName = 'Add';
        this.addEditLanguageForm.controls['lK'].setValue('');
    }

    /**
     * Reset add Language form values and error messages
     */

    resetForm() {
        this.addEditSubmitted = false;
        this.addEditLanguageForm.clearValidators();
        this.addEditLanguageForm.reset();
        this.addEditLanguageForm.markAsPristine();
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
    }

    /**
     * Angular error message form control
     */

    get f() { return this.addEditLanguageForm.controls; } // Add Language Error Controller

    /**
     * Add & Edit Language form Submit
     */

    addEditLanguageSubmit() {

        this.addEditSubmitted = true;

        // stop here if form is invalid
        if (this.addEditLanguageForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.addEditLanguageForm.value);

        this.tradeApiService.ApiServices(this.urlAddEditLanguage, userInput, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.commonService.showToaster(responseData.message, 1);
                this.closeModal();
                this.getLanguageLists();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Block & Unblock Language Modal Popup
     */

    blockUnblock(data) {
        this.languageData = data;

        this.blockUnblockTitle = (data.language_status == 0) ? 'Unblock' : 'Block';

        this.open(this.blockUnblockLanguage, 'x', 'modal-blockunblock-title');
    }

    confirmBlockUnblock() {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            lK: this.languageData.language_key,
            lS: this.languageData.language_status,
        };

        let userInput = Object.assign(input);

        this.tradeApiService.ApiServices(this.urlBlockUnblockLanguage, userInput, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.commonService.showToaster(responseData.message, 1);
                this.closeModal();
                this.languageData = null;
                this.getLanguageLists();
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Get Individual Language Detail
     */

    getIndividualLanguage(data) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            lK: data.language_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualLanguage, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.open(this.contentAddNewLanguage, 'x', 'modal-basic-title');
                this.title = 'Edit';
                this.buttonName = 'Update';

                this.individualLanguageData = responseData.lD;

                this.addEditLanguageForm.controls['lK'].setValue(data.language_key);
                this.addEditLanguageForm.controls['languageName'].setValue(this.individualLanguageData.language_name);
                this.addEditLanguageForm.controls['languageIsoName'].setValue(this.individualLanguageData.language_iso_name);

            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Clear Search Data's
     */

    clearSearchData() {
        this.searchInputData = '';
        this.getLanguageLists();
    }
}
