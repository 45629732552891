import { Component, OnInit, EventEmitter, Output, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications
import { HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';
import { DOCUMENT } from '@angular/common';

import { AppComponent } from '../../app.component';
import { from } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-element-colors',
  templateUrl: './element-colors.component.html',
  styleUrls: ['./element-colors.component.scss']
})
export class ElementColorsComponent implements OnInit {

  constructor() { }

      /* COLORS */
      colorPrimary: string = '#BF9B5C';
      colorSecondary: string = '#6a6e92';
      colorInfo: string = '#55a6eb';
      colorSuccess: string = '#0abb87';
      colorWarning: string = '#ffb822';
      colorDanger: string = '#e04b4b';
      colorDark: string = '#000000';
      colorLight: string = '#f2f2f2';
  
      colorPrimaryHover: string = '#ad8643';
      colorSecondaryHover: string = '#4c5074';
      colorInfoHover: string = '#3788cd';
      colorSuccessHover: string = '#009d69';
      colorWarningHover: string = '#e19a04';
      colorDangerHover: string = '#c22d2d';
      colorDarkHover: string = '#06050a';
      colorLightHover: string = '#c4c4c4';

  ngOnInit() {
  }


  

}
