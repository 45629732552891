import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications
import { Lightbox } from 'ngx-lightbox'; // for image viewer
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';
declare var $: any;
@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss']
})

export class CustomersComponent implements OnInit {
    albums: any = []; // for image preview
    userDetails;
    urlGetCustomerDetails = 'get-customer-lists';
    urlAddEditCustomer = 'add-edit-customer';
    urlBlockUnblockCustomer = 'block-unblock-customer';
    urlGetIndividualCustomer = 'get-individual-customer-details';
    urlUpdateNewEmail = 'update-new-email';
    urlUpdateNewMobile = 'update-new-mobile';
    urlOtpAuthentication = 'get-otp-authentication';

    customersList; // for all customer lists
    countryList; // for all branch lists
    registeredCountry; // for all branch lists
    closeResult: string;
    customerData; // for block & unblock
    individualCustomerData; // individual customer details stored for edit customer modal
    customerDeatiledView;
    title; // Modal Popup header Title
    buttonName; // Modal Popup Button Name
    blockUnblockTitle;
    userInfo;
    userDocumentDetails;
    userDocuments;
    activityLogs;

    addEditCustomerForm: FormGroup;
    addEditSubmitted = false;

    selectCountry = false;

    // Update New Email Variables

    changeEmailForm: FormGroup;
    submittedChangeEmail = false;
    newEmaildisabled = 0;
    newEmailShowOtp = 0;
    changeEmailNumberOtp = '';
    changeEmailNumberOtpError = 0;
    clickedClientData: any;
    tempEmail = '';
    invalidEmail = 0;

    timeLeftTo = 60;
    showTimeResendOtp = 0;
    isOtpAuthentication = 1; // Set Default With OTP Authentication for Customer Update Email/Mobile Number

    // Update New Mobile Number Variables

    changeMobileForm: FormGroup;
    submittedChangeMobile = false;
    newMobiledisabled = 0;
    newMobileShowOtp = 0;
    changeMobileNumberOtp = '';
    changeMobileNumberOtpError = 0;
    tempMobileNumber = '';
    invalidMobile = 0;
    customerPhonecode = '';

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    searchInputData = '';
    searchCK = '';
    searchDate;

    Block;
    UnBlock;

    clientDocument_1: string;
    clientDocument_2: string;
    clientDocument_3: string;
    clientDocument_4: string;
    clientDocument_5: string;
    clientDocument_6: string;
    clientDocument_7: string;
    clientDocument_8: string;
    // end document Image upload

    @ViewChild('contentAddNewCustomer', { static: false }) private contentAddNewCustomer: ElementRef;
    @ViewChild('blockUnblockCustomer', { static: false }) private blockUnblockCustomer: ElementRef;
    @ViewChild('customerDetailedView', { static: false }) private customerDetailedView: ElementRef;
    @ViewChild('contentClientEmailChange', { static: false }) private contentClientEmailChange: ElementRef;
    @ViewChild('contentClientMobileChange', { static: false }) private contentClientMobileChange: ElementRef;

    // tslint:disable-next-line: max-line-length
    constructor(private modalService: NgbModal, private _lightbox: Lightbox, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private router: Router, private translate: TranslateService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) {
        this.AuthorisedLayoutComponent.getRouteAccess();
        translate.setDefaultLang(this.commonService.defaultLanguale);
    }

    /**
     * Menu Enable (or) Disable
     */

    activityLogPermission(mTy) {
        const moduleKey = '74124dd7ef776ae56049';
        var userDetails = JSON.parse(localStorage.getItem('user_details'));

        if (userDetails.user_type == 1) {
            return 1;
        }
        var p = 0;
        var skinName = this.tradeApiService.permissions.find(x => x.module_key == moduleKey);
        if (skinName !== undefined) {
            if (skinName.module_key == moduleKey) {
                if (mTy == 1) { // Main Menu
                    if (skinName.view == 1) {
                        p = 1;
                    }
                } else { // Sub Menu
                    if (skinName.manage == 1) {
                        p = 1;
                    }
                }
            }
        }
        return p;
    }

    getMenuPermission(mTy) {
        return this.commonService.getMenuPermission('ba7c66056ab1968dc3d6', mTy);
    }

    ngOnInit() {

        this.getCustomerLists();

        this.translate.get('customerList.Block').subscribe(lang => {
            this.Block = lang;
        });

        this.translate.get('customerList.UnBlock').subscribe(lang => {
            this.UnBlock = lang;
        });

        this.userDetails = JSON.parse(localStorage.getItem('user_details'));

        this.addEditCustomerForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(/^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
            country: ['', [Validators.required]],
            mobile: ['', [Validators.required, Validators.pattern('^[0-9\-\']+')]],
            uKey: ['', []],
        });

        this.changeEmailForm = this.formBuilder.group({
            newEmail: ['', [Validators.required, Validators.pattern(/^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
        });

        this.changeMobileForm = this.formBuilder.group({
            newMobileNumber: ['', [Validators.required, Validators.minLength(5)]],
        });
    }

    open(content, sizeS, modalTitleID) {
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    // open document image 
    Sopen(index: number): void {
        // open lightbox
        this._lightbox.open(this.albums, index);
    }

    SopenView(val) {

        const skinName = this.albums.findIndex(x => x.src == val);

        this._lightbox.open(this.albums, skinName);

    }

    close(): void {
        // close lightbox programmatically
        this._lightbox.close();
    }

    private getDismissReason(reason: any): string {

        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    dateFormates(dateObject) {
        let day: any = dateObject.day;
        let month: any = dateObject.month;
        const year = dateObject.year;
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        const date = year + '-' + month + '-' + day;
        return date;
    }

    /**
     * Fecting Customer Lists
     */

    getCustomerLists() {

        this.commonService.showLoader = 1;

        let CreationDate: any;
        if (this.searchDate !== undefined && this.searchDate !== null) {
            CreationDate = this.dateFormates(this.searchDate);
        }

        const input = {
            page: this.page,
            _sDl: this.searchInputData,
            _sCK: this.searchCK,
            _sDat: CreationDate
        };

        this.tradeApiService.ApiServices(this.urlGetCustomerDetails, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.customersList = responseData.customersList.Records;
                this.totalItems = responseData.customersList.TotalCount;
                this.countryList = responseData.countryList;
                this.registeredCountry = responseData.registeredCountry;
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Load pagination next page
     */

    loadPage(page: number) {
        this.page = page;
        this.getCustomerLists();
    }

    /**
     * Add customer Modal Popup
     */

    addNewCustomerModal() {
        this.resetForm();
        this.open(this.contentAddNewCustomer, 'x', 'modal-basic-title');
        this.title = 'Add New';
        this.buttonName = 'Add';
    }

    /**
     * Reset add Customer form values and error messages
     */

    resetForm() {
        this.addEditSubmitted = false;
        this.addEditCustomerForm.clearValidators();
        this.addEditCustomerForm.reset();
        this.addEditCustomerForm.markAsPristine();

        this.selectCountry = true;
        this.addEditCustomerForm.controls['country'].setValue('');
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
    }

    /**
     * Angular error message form control
     */

    get f() { return this.addEditCustomerForm.controls; } // Add Customer Error Controller

    /**
     * Add & Edit Customer form Submit
     */

    addEditCustomerSubmit() {

        this.addEditSubmitted = true;

        // stop here if form is invalid
        if (this.addEditCustomerForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        const input = {
            uK: this.userDetails.user_key,
        };

        const userInput = Object.assign(input, this.addEditCustomerForm.value);

        this.tradeApiService.ApiServices(this.urlAddEditCustomer, userInput, null).subscribe((responseData: any) => {

            if (responseData.status === 200) {
                this.commonService.showToaster(responseData.message, 1);
                this.closeModal();
                this.getCustomerLists();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (let i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Block & Unblock Customer Modal Popup
     */

    blockUnblock(data) {
        this.customerData = data;
        this.blockUnblockTitle = (data.user_status == 0) ? 'Unblock' : 'Block';
        this.open(this.blockUnblockCustomer, 'x', 'modal-blockunblock-title');
    }

    confirmBlockUnblock() {
        this.commonService.showLoader = 1;
        const input = {
            uK: this.userDetails.user_key,
            uKey: this.customerData.user_key,
            uS: this.customerData.user_status,
        };

        const userInput = Object.assign(input);

        this.tradeApiService.ApiServices(this.urlBlockUnblockCustomer, userInput, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.commonService.showToaster(responseData.message, 1);
                this.closeModal();
                this.customerData = null;
                this.getCustomerLists();
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Get Individual Customer Detail
     */

    getIndividualCustomer(data) {

        // this.commonService.showLoader = 1;

        // let input = {
        //     uK: this.userDetails.user_key,
        //     uKey: data.user_key,
        // };

        /*
        *   To set selected record into local storage  & arg 3 is hide the approve, Resubmit buttons in kyc-view
        */
        window.localStorage.setItem('currentRecord', data.user_key.toString());
        // this.router.navigate(['/kyc-views'],{state:{'page':'customer'}});
        this.router.navigate(['/kyc-views'], {
            queryParams: {
                page: 'customer'
            }
        });

        // this.tradeApiService.ApiServices(this.urlGetIndividualCustomer, input, null).subscribe((responseData: any) => {

        //     if (responseData.status == 200) {

        //         this.open(this.contentAddNewCustomer, 'x', 'modal-basic-title');
        //         this.title = 'Edit';
        //         this.buttonName = 'Update';

        //         this.individualCustomerData = responseData.cD;

        //         var mobile = this.individualCustomerData.mobile.replace(this.individualCustomerData.phonecode,'');

        //         this.addEditCustomerForm.controls['uKey'].setValue(data.user_key);
        //         this.addEditCustomerForm.controls['name'].setValue(this.individualCustomerData.name);
        //         this.addEditCustomerForm.controls['email'].setValue(this.individualCustomerData.email);
        //         this.addEditCustomerForm.controls['country'].setValue(this.individualCustomerData.phonecode+'-'+this.individualCustomerData.country);
        //         this.addEditCustomerForm.controls['mobile'].setValue(mobile);

        //     } else {
        //         this.commonService.showToaster(responseData.message, 4);
        //     }
        //     this.commonService.showLoader = 0;
        // });
    }

    /**
     * Get Customer Detailed View
     */

    getCustomerDetailedView(data) {
        // console.log(data);
        // console.log(data);
        this.commonService.showLoader = 1;

        const input = {
            uK: this.userDetails.user_key,
            uKey: data.user_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualCustomer, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {

                this.open(this.customerDetailedView, 'xl', 'modal-basic-title');
                this.userInfo = responseData.myInfo;
                this.userDocuments = responseData.documentInfo;
                this.documentValueSet();
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Clear Search Data's
     */

    clearSearchData() {
        this.searchInputData = '';
        this.searchCK = '';
        this.searchDate = undefined;
        this.getCustomerLists();
    }

    getGender(val) {
        if (val == 1) {
            return 'Male';
        } else if (val == 2) {
            return 'Female';
        } else if (val == 3) {
            return 'Not interest to say';
        } else {
            return '';
        }
    }

    getEmploymentStatus(id) {
        if (id == 1) {
            return 'Salaried';
        } else if (id == 2) {
            return 'Self-Employement';
        } else if (id == 3) {
            return 'Business';
        } else if (id == 4) {
            return 'UnEmployement';
        } else {
            return "_";
        }
    }

    getIndustry(id) {
        if (id == 1) {
            return 'Computer';
        } else if (id == 2) {
            return 'Tele Communication';
        } else if (id == 3) {
            return 'Agriculture';
        } else if (id == 4) {
            return 'Construction';
        } else if (id == 5) {
            return 'Pharamedical';
        } else if (id == 6) {
            return 'Education';
        } else if (id == 7) {
            return 'Transport';
        } else if (id == 8) {
            return 'Aerospace';
        } else if (id == 9) {
            return 'Food';
        } else {
            return '_';
        }
    }

    /**
     * Assing the document image URL to popup image viewer and thumline
     */

    documentValueSet() {
        this.albums = []; // for image preview  
        let i = this.userDocuments.length;

        for (const doc of this.userDocuments) {
            // for image preview
            const src = doc.image;
            const caption = '';
            const thumb = doc.document_url;
            const album = { src: src, caption: caption, thumb: thumb };

            // Document upload #060
            $('#' + doc.user_document_number).css('display', 'block');
            $('#' + 'Preview_' + doc.user_document_number).css('display', 'block');
            // $('#'+'Upload_'+doc.user_document_number).css('display','block');
            if (album.src != null || album.src != 'null') {
                this.albums.push(album);
            }
            // end for image preview

            this.documentImageMapping(doc.user_document_number, doc.image);
            i--;
        }
    }

    /**
     * Here Mapping the document Image for thumline
     */

    documentImageMapping(docNuber, FileUrl) {

        switch (docNuber) {
            case 'clientDocument_1':
                this.clientDocument_1 = FileUrl;
                break;
            case 'clientDocument_2':
                this.clientDocument_2 = FileUrl;
                break;
            case 'clientDocument_3':
                this.clientDocument_3 = FileUrl;
                break;
            case 'clientDocument_4':
                this.clientDocument_4 = FileUrl;
                break;
            case 'clientDocument_5':
                this.clientDocument_5 = FileUrl;
                break;
            case 'clientDocument_6':
                this.clientDocument_6 = FileUrl;
                break;
            case 'clientDocument_7':
                this.clientDocument_7 = FileUrl;
                break;
            case 'clientDocument_8':
                this.clientDocument_8 = FileUrl;
                break;
        }
    }

    /**
     * Get Individual Activity Log
     */

    getIndividualActivitylog(data) {

        this.commonService.showLoader = 1;

        window.localStorage.setItem('individualActivityLogUserKey', data.user_key.toString());
        this.router.navigate(['/individual-activity-log'], {});
    }

    /**
     *  Open New Email Upadte Form
     */

    openEmailModal(data) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            cK: 0,
        };

        this.tradeApiService.ApiServices(this.urlOtpAuthentication, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.updateEmailFormClear();
                this.clickedClientData = data;
                this.isOtpAuthentication = responseData.OtpAuthentication;
                this.open(this.contentClientEmailChange, 'x', 'update-email-modal');
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     *  Validate New Email Update Fields
     */

    get fmail() { return this.changeEmailForm.controls; }

    /**
     *  Clear New Email Update Form Fields
     */

    updateEmailFormClear() {
        this.submittedChangeEmail = false;
        this.tempEmail = '';
        this.newEmailShowOtp = 0;
        this.showTimeResendOtp = 0;
        this.newEmaildisabled = 0;
        this.invalidEmail = 0;
        this.isOtpAuthentication = 1; // Set Default With OTP Authentication for Customer Update Email/Mobile Number
        this.changeEmailForm.clearValidators();
        this.changeEmailForm.reset();
        this.changeEmailForm.markAsPristine();
        this.changeEmailNumberOtpError = 0;
        this.changeEmailNumberOtp = '';
    }

    /**
     *  Update Customer Email
     */

    updateCustomerEmail(withOpt = 1) {

        this.submittedChangeEmail = true;

        // stop here if form is invalid
        if (this.changeEmailForm.invalid) {
            return;
        }

        this.invalidEmail = 0;
        if (this.tempEmail != '' && this.changeEmailForm.value.newEmail !== this.tempEmail) {
            this.invalidEmail = 1;
            return;
        }

        this.commonService.showLoader = 1;
        this.showTimeResendOtp = 0;

        let input = {
            // uK: this.userDetails.user_key,
            uK: this.clickedClientData.user_key,
            wOpt: withOpt,
            isOtpAuthentication: this.isOtpAuthentication,
        };

        let userInput = Object.assign(input, this.changeEmailForm.value);

        if (withOpt == 2) {
            let Otpinput = {
                oE: this.changeEmailNumberOtp,
            };

            userInput = Object.assign(Otpinput, userInput);
        }

        this.tradeApiService.ApiServices(this.urlUpdateNewEmail, userInput, null).subscribe((responseData: any) => {
            if (responseData.status == 200) {
                if (this.isOtpAuthentication == 1) { // With OTP Authentication
                    this.newEmaildisabled = 1;
                    this.newEmailShowOtp = 1;
                    this.tempEmail = this.changeEmailForm.value.newEmail;
                    if (withOpt == 2) {
                        this.tempEmail = '';
                        this.getCustomerLists();
                        this.modalService.dismissAll();
                    }
                } else { // Without OTP Authentication
                    this.getCustomerLists();
                    this.modalService.dismissAll();
                }
                this.commonService.showToaster(responseData.message, 1);
            } else {
                this.showTimeResendOtp = 1;
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    timeLeftHandleEvent(events) {
        if (events.action == 'done') {
            this.showTimeResendOtp = 1;
        }
    }

    /**
     * Verify Update Email OTP
     */

    verifyChangeEmailOtp() {
        this.changeEmailNumberOtpError = 0;

        if (this.changeEmailNumberOtp == '') {
            this.changeEmailNumberOtpError = 1;
            return;
        }
        this.updateCustomerEmail(2);
    }

    /**
     *  Open New Mobile Upadte Form
     */

    openMobileModal(data) {

        this.commonService.showLoader = 1;

        let input = {
            uK: data.user_key,
            cK: 1,
        };

        this.tradeApiService.ApiServices(this.urlOtpAuthentication, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.updateMobileFormClear();
                this.clickedClientData = data;
                this.isOtpAuthentication = responseData.OtpAuthentication;
                this.customerPhonecode = responseData.cCode;
                this.open(this.contentClientMobileChange, 'x', 'update-mobile-modal');
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     *  Clear New Mobile Update Form Fields
     */

    updateMobileFormClear() {
        this.submittedChangeMobile = false;
        this.tempMobileNumber = '';
        this.newMobileShowOtp = 0;
        this.showTimeResendOtp = 0;
        this.newMobiledisabled = 0;
        this.invalidMobile = 0;
        this.isOtpAuthentication = 1; // Set Default With OTP Authentication for Customer Update Email/Mobile Number
        this.changeMobileForm.clearValidators();
        this.changeMobileForm.reset();
        this.changeMobileForm.markAsPristine();
        this.changeMobileNumberOtpError = 0;
        this.changeMobileNumberOtp = '';
    }

    /**
     *  Validate New Email Update Fields
     */

    get fmob() { return this.changeMobileForm.controls; }

    /**
     *  Update Customer Mobile
     */

    updateCustomerMobile(withOpt = 1) {

        this.submittedChangeMobile = true;

        // stop here if form is invalid
        if (this.changeMobileForm.invalid) {
            return;
        }

        this.invalidMobile = 0;
        if (this.tempMobileNumber != '' && this.changeMobileForm.value.newMobileNumber !== this.tempMobileNumber) {
            this.invalidMobile = 1;
            return;
        }

        this.commonService.showLoader = 1;
        this.showTimeResendOtp = 0;

        let input = {
            uK: this.clickedClientData.user_key,
            wOpt: withOpt,
            isOtpAuthentication: this.isOtpAuthentication,
        };

        let userInput = Object.assign(input, this.changeMobileForm.value);

        if (withOpt == 2) {
            let Otpinput = {
                oE: this.changeMobileNumberOtp,
            };

            userInput = Object.assign(Otpinput, userInput);
        }

        this.tradeApiService.ApiServices(this.urlUpdateNewMobile, userInput, null).subscribe((responseData: any) => {
            if (responseData.status == 200) {
                if (this.isOtpAuthentication == 1) { // With OTP Authentication
                    this.newMobiledisabled = 1;
                    this.newMobileShowOtp = 1;
                    this.tempMobileNumber = this.changeMobileForm.value.newMobileNumber;
                    if (withOpt == 2) {
                        this.tempMobileNumber = '';
                        this.getCustomerLists();
                        this.modalService.dismissAll();
                    }
                } else { // Without OTP Authentication
                    this.getCustomerLists();
                    this.modalService.dismissAll();
                }
                this.commonService.showToaster(responseData.message, 1);
            } else {
                this.showTimeResendOtp = 1;
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        return true;
    }

    /**
     * Verify Update Mobile Number OTP
     */

    verifyChangeMobileOtp() {
        this.changeMobileNumberOtpError = 0;

        if (this.changeMobileNumberOtp == '') {
            this.changeMobileNumberOtpError = 1;
            return;
        }
        this.updateCustomerMobile(2);
    }
}
