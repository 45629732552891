import { Component, OnInit } from '@angular/core';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';


@Component({
    selector: 'app-back-office-kyc-lists',
    templateUrl: './back-office-kyc-lists.component.html',
    styleUrls: ['./back-office-kyc-lists.component.scss']
})
export class BackOfficeKycListsComponent implements OnInit {
    urlKycRequests = 'get-bo-kyc-requests';
    userDetails;
    no_records = false;
    constructor(public tradeApiService: ApiTradeService, public commonService: CommonService, private router: Router, private translate: TranslateService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) {
        this.AuthorisedLayoutComponent.getRouteAccess();
        translate.setDefaultLang(this.commonService.defaultLanguale);
    }

    ngOnInit() {
        this.getKycRequests();
    }

    getKycRequests() {
        const input = {
            page: 1,
            _sDl: '',
            _sCK: '',
            _sDat: undefined
        };
        this.tradeApiService.ApiServices(this.urlKycRequests, input, null).subscribe((kycDetails: any) => {
            if (kycDetails.status == 200 && kycDetails.data.length > 0) {
                this.userDetails = kycDetails.data;
            }
            else {
                this.no_records = true;
            }
            this.commonService.showLoader = 0;
        });
    }

    kycStatus(val) {

        var WaitingApproval;
        this.translate.get('kycRequestList.WaitingApproval').subscribe(lang => {
            WaitingApproval = lang;
        });

        var Approved;
        this.translate.get('kycRequestList.Approved').subscribe(lang => {
            Approved = lang;
        });

        var ReSubmitted;
        this.translate.get('kycRequestList.ReSubmitted').subscribe(lang => {
            ReSubmitted = lang;
        });

        var Rejected;
        this.translate.get('kycRequestList.Rejected').subscribe(lang => {
            Rejected = lang;
        });

        if (val == 1) {
            return WaitingApproval
        } else if (val == 2) {
            return Approved
        } else if (val == 3) {
            return ReSubmitted
        } else if (val == 4) {
            return Rejected
        }
    }

    userDetailView(user_key) {
        window.localStorage.setItem("currentRecord", user_key.toString());
        //this.router.navigate(['/kyc-views?d=d'],{state:{'page':'kyc-list'}});

        this.router.navigate(['/kyc-views'], {
            queryParams: {
                page: 'kyc-list'
            }
        });

    }

}
