import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications
import { Lightbox } from 'ngx-lightbox'; // for image viewer

import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import {TranslateService} from '@ngx-translate/core';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

@Component({
  selector: 'app-admin-profile-page',
  templateUrl: './admin-profile-page.component.html',
  styleUrls: ['./admin-profile-page.component.scss']
})
export class AdminProfilePageComponent implements OnInit {

  

  closeResult: string;
  imagePathtmp: string;
  imagePathProfile: string;  
  modalReference;

  confirm = false;
  pwdPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}';
  numericNumberReg= '^-?[0-9]\\d*(\\.\\d{1,2})?$';
  passwordUpdateForm: FormGroup;
  submittedPassword = false;


  // Customer
  urlProfileDetails = 'get-profile-employee-details';
 
  urlProfileChangePassword = 'customer-profile-password-reset';
  userDetails;
  userDetailsEmp;

  urlProfileImg = 'customer-profile-img';
  urlDeleteProfileImg = 'delete-profile-img';

  pSData = 1;

  constructor( private modalService: NgbModal, private toastr: ToastrService, private _lightbox: Lightbox, private formBuilder: FormBuilder,  private tradeApiService: ApiTradeService, private commonService: CommonService, private translate: TranslateService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent ) {
    this.AuthorisedLayoutComponent.getRouteAccess();
    translate.setDefaultLang( this.commonService.defaultLanguale );

    // form controll start  
    this.reintiPasswordForm();
  }

  ngOnInit() {    
    this.userDetails = JSON.parse( localStorage.getItem('user_details'));    
    this.getProfileDetails();


  }

  
  reintiPasswordForm(){

    this.passwordUpdateForm = this.formBuilder.group({
      newPassword: ['', [Validators.required,Validators.minLength(8),Validators.pattern(this.pwdPattern)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.pwdPattern)]],  
      oldPassword: ['', [Validators.required,Validators.minLength(8)]],  
    },{
      validator: this.MustMatch('newPassword', 'confirmPassword')
  }); 

}
  

  open(content) {
    this.modalReference = this.modalService.open(content);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

   // End document image 

    // convenience getter for easy access to form fields

    get fv() { return this.passwordUpdateForm.controls; }
  

    //password compare

MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        this.confirm = true;
          matchingControl.setErrors({ mustMatch: true });
      } else {
          this.confirm = false;
          matchingControl.setErrors(null);
      }
  }
}


updatePassword() {

  this.submittedPassword = true;

  // stop here if form is invalid
  if (this.passwordUpdateForm.invalid) {
      return;
  }

  let input = {                        
    uK:this.userDetails.user_key,                       
  };  
  
  this.commonService.showLoader = 1;
  
  let userInput = Object.assign(input, this.passwordUpdateForm.value);

  console.log( userInput );


  this.tradeApiService.ApiServices(this.urlProfileChangePassword, userInput , null).subscribe(( changePassword:any ) => {
    // do stuff with our data here.
    // ....

    // asign data to our class property in the end
    // so it will be available to our template                  
        if(changePassword.status == 200){  
          this.commonService.showToaster( changePassword.message , 1 );   
          this.clearForm();
        }
        else{                    
          const errorValues = Object.keys(changePassword.message).map(key => changePassword.message[key]);
          for(var i=0; i<errorValues.length;i++){                                  
            this.commonService.showToaster( errorValues[i] , 4 ); 
          }
        }

        this.commonService.showLoader = 0;
        
    });


}

/**
 * Clearing the form data for change password
 */

clearForm(){

    this.submittedPassword = false;
    this.passwordUpdateForm.clearValidators();  
    this.passwordUpdateForm.reset();  
    this.passwordUpdateForm.markAsPristine(); 

}
  /**
   * Fecting the profile Details
   */
  
getProfileDetails(){
  
  this.commonService.showLoader = 1;

  let input = {
    //token:localStorage.getItem('auth_token'),                       
    uK:this.userDetails.user_key,                                               
    uKey:this.userDetails.user_key,                                               
  };  
  
  this.tradeApiService.ApiServices(this.urlProfileDetails, input , null).subscribe(( otpDetails:any ) => {
    // do stuff with our data here.
    // ....

    // asign data to our class property in the end
    // so it will be available to our template                  
        if(otpDetails.status == 200){          
           
          this.pSData = otpDetails.pSData;
                    switch (otpDetails.pSData) {
                        case 1:
                            this.pwdPattern = this.commonService.HighpwdPattern;
                            break;
                        case 2:
                            this.pwdPattern = this.commonService.MediumpwdPattern;
                            break;
                        case 3:
                            this.pwdPattern = this.commonService.LowpwdPattern;
                            break;
                        default:
                            this.pwdPattern = this.commonService.HighpwdPattern;
                            break;
                    }
                    this.reintiPasswordForm();

          this.userDetailsEmp = otpDetails.pU;                    
          this.imagePathProfile = otpDetails.pU.user_profile_pick;    
                 
        }
        else{                    
          this.commonService.showToaster( otpDetails.message , 4 ); 
        }

        this.commonService.showLoader = 0;
        
    });


}  

 

onSelect(imageEvents) {    
  this.imagePathtmp = imageEvents;
}
onReset() {
  console.log('onReset');
}


  // uploading the profile / img url to the image tag

  uploadProfileImg() {

    this.commonService.showLoader = 1;
  
   let input = {
    //token:localStorage.getItem('auth_token'),                       
    uK:this.userDetails.user_key, 
    u_Pi: this.imagePathtmp                                              
  };  
  
  this.tradeApiService.ApiServices(this.urlProfileImg, input , null).subscribe(( profileImg:any ) => {
    // do stuff with our data here.
    // ....

    // asign data to our class property in the end
    // so it will be available to our template                  
        if(profileImg.status == 200){  
            this.imagePathProfile = profileImg.profile;    
            this.commonService.userProfileImg = profileImg.profile;
            this.commonService.showToaster( profileImg.message , 1 ); 
            this.getProfileDetails();
            this.modalService.dismissAll();
        }
        else{                    
          this.commonService.showToaster( profileImg.message , 4 ); 
        }

        this.commonService.showLoader = 0;
        
    });
  }

  removeProfileImage(){    
    this.commonService.showLoader = 1;
    let input = {
      //token:localStorage.getItem('auth_token'),                       
      user_key:this.userDetails.user_key                                               
    };  
    this.tradeApiService.ApiServices(this.urlDeleteProfileImg, input , null).subscribe(( profileImg:any ) => {                    
          if(profileImg.status == 200){                
              this.imagePathProfile = profileImg.profile;    
              this.commonService.userProfileImg = profileImg.profile;
              this.commonService.showToaster( profileImg.message , 1 ); 
              this.getProfileDetails();
              this.modalService.dismissAll();
          }
          else{                    
            this.commonService.showToaster( profileImg.message , 4 ); 
          }  
          this.commonService.showLoader = 0;          
      });
    }

}
