import { Component, OnInit } from '@angular/core';

import { CommonService } from '../../../common.service';
import { Router } from '@angular/router';
import { ApiTradeService } from '../../../api-trade.service';

@Component({
  selector: 'app-authorised-layout',
  templateUrl: './authorised-layout.component.html',
  styleUrls: ['./authorised-layout.component.scss']
})
export class AuthorisedLayoutComponent implements OnInit {

  public routerName: type[] = [
    { id: '/dashboard', moduleKey: '260d38111a22641841b6', allow: '1', menuType: '1', mainMenuKey: '260d38111a22641841b6' },
    { id: '/live-account-request', moduleKey: '95536e1e2a98deb6deea', allow: '1', menuType: '2', mainMenuKey: '975700ffa4907fe55c46' },
    { id: '/account-details', moduleKey: '4e914714e1ef3df1e05c', allow: '1', menuType: '2', mainMenuKey: '975700ffa4907fe55c46' },
    { id: '/live-position-details', moduleKey: '04ddef87f770df0ff855', allow: '1', menuType: '2', mainMenuKey: '975700ffa4907fe55c46' },
    { id: '/live-history-details', moduleKey: '3e8ecd5df9ab88e5fa22', allow: '1', menuType: '2', mainMenuKey: '975700ffa4907fe55c46' },
    { id: '/demo-account-details', moduleKey: '42b3ebf7aaf50ff5de5d', allow: '1', menuType: '2', mainMenuKey: '04369b301923d5220313' },
    { id: '/demo-position-details', moduleKey: 'e1ff50ebad0a131bc7ab', allow: '1', menuType: '2', mainMenuKey: '04369b301923d5220313' },
    { id: '/demo-history-details', moduleKey: 'f0d83066df19d974917f', allow: '1', menuType: '2', mainMenuKey: '04369b301923d5220313' },
    { id: '/country-list', moduleKey: '7cc92df5d608193c84f5', allow: '1', menuType: '2', mainMenuKey: '222b1d8f2679b5be89ce' },
    { id: '/city-list', moduleKey: 'd59cd8a93d5bd22e9ef7', allow: '1', menuType: '2', mainMenuKey: '222b1d8f2679b5be89ce' },
    { id: '/branch-list', moduleKey: '3ad60c8b22b1eefb4290', allow: '1', menuType: '2', mainMenuKey: '222b1d8f2679b5be89ce' },
    { id: '/balance-list', moduleKey: '3ad60c8b22b1eefb4290', allow: '1', menuType: '2', mainMenuKey: '222b1d8f2679b5be89ce' },
    { id: '/designation-list', moduleKey: '231e149229183e7c22fa', allow: '1', menuType: '2', mainMenuKey: '222b1d8f2679b5be89ce' },
    { id: '/mt5-account-list', moduleKey: 'bef2c546716297a60199', allow: '1', menuType: '2', mainMenuKey: '0785b308754a6a7d8be8' },
    { id: '/group-list', moduleKey: '39e1ba6bed975a07a085', allow: '1', menuType: '2', mainMenuKey: '222b1d8f2679b5be89ce' },
    { id: '/leverage-list', moduleKey: 'c76c4466f709ebdffe6e', allow: '1', menuType: '2', mainMenuKey: '222b1d8f2679b5be89ce' },
    { id: '/settings', moduleKey: '09a81ddcf44eb940e470', allow: '1', menuType: '1', mainMenuKey: '09a81ddcf44eb940e470' },
    { id: '/employee-list', moduleKey: 'a1f078bfc60af4ae7539', allow: '1', menuType: '2', mainMenuKey: '222b1d8f2679b5be89ce' },
    { id: '/customer-list', moduleKey: 'ba7c66056ab1968dc3d6', allow: '1', menuType: '2', mainMenuKey: '38dd759bf46d61011ab1' },
    { id: '/roles-list', moduleKey: '97f1c20cc72e9a905816', allow: '1', menuType: '2', mainMenuKey: '222b1d8f2679b5be89ce' },
    { id: '/deposit-withdrawal', moduleKey: '2c9282b41192ba9f49c9', allow: '1', menuType: '2', mainMenuKey: '52226e25019c7ac32a20' },
    { id: '/request-queue-list', moduleKey: 'b87dbea7970aedf579bf', allow: '1', menuType: '2', mainMenuKey: '52226e25019c7ac32a20' },
    { id: '/kyc-lists', moduleKey: 'e84653048336c0c71bd1', allow: '1', menuType: '2', mainMenuKey: '38dd759bf46d61011ab1' },
    { id: '/client-dashboard', moduleKey: '260d38111a22641841b6', allow: '1', menuType: '2', mainMenuKey: '260d38111a22641841b6' },
    { id: '/admin-dashboard', moduleKey: '260d38111a22641841b6', allow: '1', menuType: '2', mainMenuKey: '260d38111a22641841b6' },
    { id: '/profile-details', moduleKey: 'admin', allow: '1', menuType: '2', mainMenuKey: '260d38111a22641841b6' },
    { id: '/kyc-views?page=kyc-list', moduleKey: 'admin', allow: '1', menuType: '2', mainMenuKey: '38dd759bf46d61011ab1' },
    { id: '/kyc-views?page=customer', moduleKey: 'admin', allow: '1', menuType: '2', mainMenuKey: '38dd759bf46d61011ab1' },
    { id: '/packages-list', moduleKey: '97f1c20cc72e9a905816', allow: '1', menuType: '2', mainMenuKey: '222b1d8f2679b5be89ce' },
    { id: '/activity-logs', moduleKey: '74124dd7ef776ae56049', allow: '1', menuType: '1', mainMenuKey: '74124dd7ef776ae56049' },
    { id: '/individual-activity-log', moduleKey: 'admin', allow: '1', menuType: '1', mainMenuKey: '38dd759bf46d61011ab1' },
    { id: '/language-list', moduleKey: 'c373763d0cce5bf3d31e', allow: '1', menuType: '2', mainMenuKey: '222b1d8f2679b5be89ce' },
    { id: '/mt5-activity-log', moduleKey: 'c373763d0cce5bf3d31e', allow: '1', menuType: '2', mainMenuKey: '222b1d8f2679b5be89ce' }
  ];

  public routerNameClient: type[] = [
    { id: '/client-demo-account', moduleKey: '000e81bff1b6300b7ff4', allow: '2', menuType: '2', mainMenuKey: '5035cd1fc96ee61f7643' },
    { id: '/client-live-account', moduleKey: '7876b8e01ebc6ba73c02', allow: '2', menuType: '2', mainMenuKey: '5035cd1fc96ee61f7643' },
    { id: '/client-profile', moduleKey: 'others', allow: '2', menuType: '2', mainMenuKey: '260d38111a22641841b6' },
    { id: '/client-position', moduleKey: '9175b5f70de1b9d55b30', allow: '2', menuType: '2', mainMenuKey: '5035cd1fc96ee61f7643' },
    { id: '/client-history', moduleKey: 'ab12fe5ff9bc3457c580', allow: '2', menuType: '2', mainMenuKey: '5035cd1fc96ee61f7643' },
    { id: '/client-dashboard', moduleKey: '260d38111a22641841b6', allow: '2', menuType: '1', mainMenuKey: '260d38111a22641841b6' },
    { id: '/change-client-info', moduleKey: 'others', allow: '2', menuType: '2', mainMenuKey: '' },
    { id: '/activity-logs', moduleKey: '74124dd7ef776ae56049', allow: '2', menuType: '1', mainMenuKey: '' },
    { id: '/deposit-withdrawal-details', moduleKey: '000e81bff1b6300b7ff4', allow: '2', menuType: '2', mainMenuKey: '5035cd1fc96ee61f7643' },
    { id: '/balance-transfer', moduleKey: '7876b8e01ebc6ba73c02', allow: '2', menuType: '2', mainMenuKey: '5035cd1fc96ee61f7643' }
  ];

  public routerNameKyc: type[] = [
    { id: '/dashboard', moduleKey: '260d38111a22641841b6', allow: '3', menuType: '1`', mainMenuKey: '260d38111a22641841b6' },
    { id: '/live-account-request', moduleKey: '95536e1e2a98deb6deea', allow: '3', menuType: '2', mainMenuKey: '975700ffa4907fe55c46' },
    { id: '/account-details', moduleKey: '4e914714e1ef3df1e05c', allow: '3', menuType: '2', mainMenuKey: '975700ffa4907fe55c46' },
    { id: '/live-position-details', moduleKey: '04ddef87f770df0ff855', allow: '3', menuType: '2', mainMenuKey: '975700ffa4907fe55c46' },
    { id: '/live-history-details', moduleKey: '3e8ecd5df9ab88e5fa22', allow: '3', menuType: '2', mainMenuKey: '975700ffa4907fe55c46' },
    { id: '/demo-account-details', moduleKey: '42b3ebf7aaf50ff5de5d', allow: '3', menuType: '2', mainMenuKey: '04369b301923d5220313' },
    { id: '/demo-position-details', moduleKey: 'e1ff50ebad0a131bc7ab', allow: '3', menuType: '2', mainMenuKey: '04369b301923d5220313' },
    { id: '/demo-history-details', moduleKey: 'f0d83066df19d974917f', allow: '3', menuType: '2', mainMenuKey: '04369b301923d5220313' },
    { id: '/customer-list', moduleKey: 'ba7c66056ab1968dc3d6', allow: '3', menuType: '2', mainMenuKey: '38dd759bf46d61011ab1' },
    { id: '/kyc-lists', moduleKey: 'e84653048336c0c71bd1', allow: '3', menuType: '2', mainMenuKey: '38dd759bf46d61011ab1' },
    { id: '/profile-details', moduleKey: 'others', allow: '3', menuType: '2', mainMenuKey: '260d38111a22641841b6' },
    { id: '/kyc-views?page=kyc-list', moduleKey: 'others', allow: '3', menuType: '2', mainMenuKey: '38dd759bf46d61011ab1' },
    { id: '/kyc-views?page=customer', moduleKey: 'others', allow: '3', menuType: '2', mainMenuKey: '38dd759bf46d61011ab1' },
    { id: '/mt5-account-list', moduleKey: 'bef2c546716297a60199', allow: '3', menuType: '2', mainMenuKey: '0785b308754a6a7d8be8' },
    { id: '/packages-list', moduleKey: '4e399a7dfee7f64c436a', allow: '3', menuType: '2', mainMenuKey: '222b1d8f2679b5be89ce' },
    { id: '/activity-logs', moduleKey: '74124dd7ef776ae56049', allow: '3', menuType: '1', mainMenuKey: '' },
    { id: '/individual-activity-log', moduleKey: '74124dd7ef776ae56049', allow: '3', menuType: '1', mainMenuKey: '38dd759bf46d61011ab1' },
  ];


  constructor(private commonService: CommonService, private router: Router, public tradeApiService: ApiTradeService) { }

  ngOnInit() {

    /**
     * Here checking for the auth token for accessing the each urls
     * once user logged in successfully
     */


    this.commonService.checkforAuth();
    if (this.commonService.checkforAuthInPages()) {
      this.getRouteAccess();
    }


  }

  // Find the Current Matched URL And Check the access
  getRouteAccess() {

    var userDetails = JSON.parse(localStorage.getItem('user_details'));

    if (userDetails.user_type == 1) {
      var routerName = this.routerName.find(x => x.id == this.router.url);
    }
    if (userDetails.user_type == 2) {
      var routerName = this.routerNameClient.find(x => x.id == this.router.url);
    }
    if (userDetails.user_type == 3) {
      var routerName = this.routerNameKyc.find(x => x.id == this.router.url);
    }    

    var log = 0;
    if (routerName) {
     
      if (userDetails.user_type == 1 && routerName.allow == '1') { // for admin
        log = 1;
      }

      if (userDetails.user_type == 2 && routerName.allow == '2') { // for customer
        log = this.checkLoginRouters( routerName );
      }

      if (userDetails.user_type == 3 && routerName.allow == '3') {  // for other employee users 
        log = this.checkLoginRouters( routerName );
      }
    }

    if (log == 0) {
      this.commonService.logout();
    }

  }

  checkLoginRouters( routerName ){
    var log = 0;
    var skinName = this.tradeApiService.permissions.find(x => x.module_key == routerName.moduleKey);
    /***
     * Intially check for submenu OR Main Menu 
     */
    if (routerName.menuType == '2') {  // Submenu
      if (routerName.moduleKey == 'others') {
        log = 1;
      } else {
        var mainMenuKey = this.tradeApiService.permissions.find(x => x.module_key == routerName.mainMenuKey);
        if (skinName.manage == 1 && mainMenuKey.view == 1) {
          log = 1;
        }
      }
    }
    else { // Main Menu
      log = skinName.view;
    }
    return log;
  }

}


export interface type {
  id: string;
  moduleKey: string;
  allow: string;
  menuType: string;
  mainMenuKey: string;
}
