import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { SingleDataSet, Label } from 'ng2-charts';

import { ToastrService } from 'ngx-toastr'; // for notifications
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';


import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';


@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss']
})
export class ClientDashboardComponent implements OnInit {

  urlGetClientDashboradDetails = 'client-dashboard';

  userDetails;
  countDetails;
  AccuPendList;
  getCountsLiveAccountDetails;

  TotalbalanceDemo:any = '000';
  TotalbalanceLive:any = '000';

  //prod
  pieChartPlugins;


  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true; 

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];

  public barChartColors = [
    {
      backgroundColor: ['#eb880e', '#eb880e','#eb880e', '#eb880e','#eb880e','#eb880e','#eb880e'],
    },
  ];

   // Pie
   public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  // public pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  public pieChartLabelsDemo: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  public pieChartDataDemo: number[] = [300, 500, 100];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;  
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];

    // PolarArea
    public polarAreaChartLabelsLive: any[] = ['Download Sales', 'In-Store Sales', 'Mail Sales', 'Telesales', 'Corporate Sales'];
    public polarAreaChartDataLive: SingleDataSet = [300, 500, 100, 40, 120];
    public polarAreaLegend = true;
  
    public polarAreaChartType: ChartType = 'polarArea';


  constructor(private toastr: ToastrService, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) { 
    this.AuthorisedLayoutComponent.getRouteAccess();
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('user_details'));
    this.GetClientDashboradDetails();
  }


  
  GetClientDashboradDetails(){
    console.log( 'GetClientDashboradDetails' );
    this.commonService.showLoader = 1;

    let input = {
      uK: this.userDetails.user_key,      
    };

    this.tradeApiService.ApiServices(this.urlGetClientDashboradDetails, input, null).subscribe((otpDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (otpDetails.status == 200) {
        console.log( otpDetails );    
        this.countDetails = otpDetails.DashboardLists;   
        this.AccuPendList = otpDetails.AccuPendList;   
        this.TotalbalanceDemo = otpDetails.TotalbalanceDemo;
        this.TotalbalanceLive = otpDetails.TotalbalanceLive;
        this.getCountsLiveAccountDetails = otpDetails.getCountsLiveAccountDetails;

        this.pieChartDataDemo = otpDetails.chartbalanceDemo;
        this.pieChartLabelsDemo = otpDetails.chartBalanceDashboardAccountLists.demoAccount;

        this.polarAreaChartLabelsLive = otpDetails.chartBalanceDashboardAccountLists.liveAccount;
        this.polarAreaChartDataLive = otpDetails.chartbalanceLive;
      }
      else {
        const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
        for(var i=0; i<errorValues.length;i++){                                  
          this.commonService.showToaster( errorValues[i] , 4 ); 
        }
      }

      this.commonService.showLoader = 0;

    });

  }

  
  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public randomize(): void {
    // Only Change 3 values
    const data = [
      Math.round(Math.random() * 100),
      59,
      80,
      (Math.random() * 100),
      56,
      (Math.random() * 100),
      40];
    this.barChartData[0].data = data;
  }

}
