import { Component, Inject, ElementRef  } from '@angular/core';

import { CommonService } from './common.service';
import { ApiTradeService } from './api-trade.service';
import { Title  }     from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Welcome';
  getSettingList = 'g-l';
  color_scheme;
  constructor(@Inject(DOCUMENT) private _document: HTMLDocument, public commonService: CommonService, private tradeApiService: ApiTradeService, private titleService: Title, private translate: TranslateService, private elementRef: ElementRef) {    
  }

  ngOnInit() {
    document.body.classList.add("loaded-Content-Sch");
    this.getGeneralSettingDetailsList();       
  }

  ngAfterViewInit(){
    // this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = ' #5c7d99';
    // this.elementRef.nativeElement.style.setProperty('--background-default', 'green');
    // this.elementRef.nativeElement.style.setProperty( '--background-default', 'green' );
    /**
     * Applying the Theme Color Dynamicall
     */
    // document.documentElement.style.setProperty('--background_default', '#ffb822');
    // document.documentElement.style.setProperty('--colorPrimary', '#ffb822');   
}

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  getGeneralSettingDetailsList() {

    let userInput = {};

    this.commonService.showLoader = 1;

    this.tradeApiService.ApiServices(this.getSettingList, userInput, null).subscribe((GeneralList: any) => {
        if (GeneralList.status == 200) {
            localStorage.setItem('GeneralList', JSON.stringify(GeneralList.settingsData));
            this.commonService.siteLogoImg = GeneralList.settingsData.logo_url;
            this.commonService.favIconImg = GeneralList.settingsData.favicon_url;
            this._document.getElementById('appFavicon').setAttribute('href', this.commonService.favIconImg);
            this.title = GeneralList.settingsData.site_name;
            this.setTitle( this.title );

            /**
             * Default Language
             */
            this.commonService.LanguagesDrop = GeneralList.LDt;
            var lang = localStorage.getItem('language');
            this.commonService.enableLanguale =  GeneralList.settingsData.el;
            if (lang == null) {              
                this.commonService.enableLanguale = GeneralList.settingsData.el;  
                this.commonService.defaultLanguale = GeneralList.settingsData.dfl;     
                localStorage.setItem('language', GeneralList.settingsData.dfl);   
                this.translate.setDefaultLang( GeneralList.settingsData.dfl );   
            }
            // End

            this.color_scheme = GeneralList.settingsData.color_scheme;

            // console.log( GeneralList.settingsData.color_scheme );

            // document.documentElement.style.setProperty('--background_default', '#ffb822');
            // document.documentElement.style.setProperty('--colorPrimary', '#ffb822');             
            Object.keys(this.color_scheme).forEach(k =>               
                document.documentElement.style.setProperty('--'+k, this.color_scheme[k]) 
              );
        } else {
            const errorValues = Object.keys(GeneralList.message).map(key => GeneralList.message[key]);
            for (var i = 0; i < errorValues.length; i++) {
                //this.toastr.errorToastr( errorValues[i] , 'Alert!');
            }
        }
        this.commonService.showLoader = 0;
        document.body.classList.remove("loaded-Content-Sch");
    });
}

}
