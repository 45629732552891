import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})

export class RolesComponent implements OnInit {

    userDetails;
    urlGetRoleDetails = 'get-role-lists';
    urlAddEditRole = 'add-edit-role';
    urlBlockUnblockRole = 'block-unblock-role';
    urlGetIndividualRole = 'get-individual-role-details';
    urlGetIndividualPrivilege = 'get-privilege-details';
    urlUpdatePrivilegeDetails = 'update-privilege-details';
    roleLists; //for all role lists
    closeResult: string;
    roleData; //for block & unblock
    individualRoleData; //individual role details stored for edit role modal
    title; //Modal Popup header Title
    buttonName; //Modal Popup Button Name
    blockUnblockTitle;
    individualPrivilegeData;
    roleKey;

    addEditRoleForm: FormGroup;
    addEditSubmitted = false;

    items: FormArray;
    userRoleForm: FormGroup;

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    searchInputData = '';

    @ViewChild('contentAddNewRole', {static: false}) private contentAddNewRole: ElementRef;
    @ViewChild('blockUnblockRole', {static: false}) private blockUnblockRole: ElementRef;
    @ViewChild('contentUpdatePrivilege', { static: false }) private contentUpdatePrivilege: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService) { }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(mTy) {
        return this.commonService.getMenuPermission('97f1c20cc72e9a905816', mTy);
    }

    ngOnInit() {

        this.getRoleLists();

        this.userDetails = JSON.parse( localStorage.getItem('user_details'));

        this.addEditRoleForm = this.formBuilder.group({
            roleName: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
            rK: ['', []],
        });

        this.userRoleForm = this.formBuilder.group({
            items: this.formBuilder.array([]),
        });     
    }

    open(content, sizeS, modalTitleID){
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    /**
     * Fecting Role Lists
     */

    getRoleLists() {

        this.commonService.showLoader = 1;

        let input = {
            page: this.page,
            _sDl: this.searchInputData,
        };

        this.tradeApiService.ApiServices(this.urlGetRoleDetails, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.roleLists = responseData.roleLists.Records;
                this.totalItems = responseData.roleLists.TotalCount; 
            }
            else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Load pagination next page
     */

    loadPage(page: number) {   
        this.page = page;
        this.getRoleLists();
    }

    /**
     * Add Role Modal Popup
     */

    addNewRoleModal() {
        this.resetForm();
        this.open(this.contentAddNewRole, 'x', 'modal-basic-title');
        this.title = 'Add New';
        this.buttonName = 'Add';
    }

    /**
     * Reset add role form values and error messages
     */

    resetForm() {
        this.addEditSubmitted = false;
        this.addEditRoleForm.clearValidators();
        this.addEditRoleForm.reset();
        this.addEditRoleForm.markAsPristine()
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
    }

    /**
     * Angular error message form control
     */

    get f() { return this.addEditRoleForm.controls; } //Add Role Error Controller

    /**
     * Add & Edit Role form Submit
     */

    addEditRoleSubmit() {
        this.addEditSubmitted = true;

        // stop here if form is invalid
        if (this.addEditRoleForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.addEditRoleForm.value);

        this.tradeApiService.ApiServices(this.urlAddEditRole, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.getRoleLists();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for(var i=0; i<errorValues.length;i++){                                  
                    this.commonService.showToaster( errorValues[i] , 4 ); 
                }  
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Block & Unblock role Modal Popup
     */

    blockUnblock(data) {
        this.roleData = data;
        
        this.blockUnblockTitle = (data.role_status == 0) ? 'Unblock' : 'Block';

        this.open(this.blockUnblockRole, 'x', 'modal-blockunblock-title');
    }

    confirmBlockUnblock() {
        
        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
          rK: this.roleData.role_key,
          rS: this.roleData.role_status,
        };

        let userInput = Object.assign(input);

        this.tradeApiService.ApiServices(this.urlBlockUnblockRole, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.roleData = null;
                this.getRoleLists();
            } else {
                this.commonService.showToaster( responseData.message , 4 );
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Get Individual Role Detail
     */

    getIndividualRole(data) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            rK: data.role_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualRole, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.open(this.contentAddNewRole, 'x', 'modal-basic-title');
                this.title = 'Edit';
                this.buttonName = 'Update';

                this.individualRoleData = responseData.rD;

                this.addEditRoleForm.controls['rK'].setValue(data.role_key);
                this.addEditRoleForm.controls['roleName'].setValue(this.individualRoleData.role);
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Get Privilege Detail
     */
    formDetails(){
        this.userRoleForm = this.formBuilder.group({              
          items: this.formBuilder.array([])
        });     
      }


    getPrivilegeDetails(data) {
      
        this.formDetails();
        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            rK: data.role_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualPrivilege, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.open(this.contentUpdatePrivilege, 'xl', 'modal-basic-title');

                this.individualPrivilegeData = responseData.pD;
                this.roleKey = data.role_key;
                

                for( var i = 0; i < this.individualPrivilegeData.length; i++ ){
                    this.addItem( this.individualPrivilegeData[ i ] );
                }  

            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;

        });
    }

    createItem( addItem ): FormGroup {
        
        return this.formBuilder.group({
            view: addItem.view,
            manage: addItem.manage,
            create: addItem.create,
            edit: addItem.edit,
            block_unblock: addItem.block_unblock,
            update_privilege: addItem.update_privilege,
            accept: addItem.accept,
            reject: addItem.reject,
            module_key: addItem.module_key,
        });
    }

    addItem( addItem ): void {

        this.items = this.userRoleForm.get('items') as FormArray;

        this.items.push(this.createItem( addItem ));

        // console.log(this.items);
    }

    // selectAll(event) {
    //     $(document).ready(function() {
    //         $('.sAll').prop('checked', event.target.checked);
    //     });
    // }

    /**
     * Update Privilege Details
     */

    checkboxUpdate(event, i) {      
        if( event.target.checked ) {
            this.items.controls[i].patchValue({
                view: (this.items.controls[i].value.view != '') ? 1 : 0,
                manage: (this.items.controls[i].value.manage != '') ? 1 : 0,
                create: (this.items.controls[i].value.create != '') ? 1 : 0,
                edit: (this.items.controls[i].value.edit != '') ? 1 : 0,
                block_unblock:(this.items.controls[i].value.block_unblock != '') ? 1 : 0,
                update_privilege:(this.items.controls[i].value.update_privilege != '') ? 1 : 0,
                accept:(this.items.controls[i].value.accept != '') ? 1 : 0,
                reject:(this.items.controls[i].value.reject != '') ? 1 : 0,
                module_key: this.items.controls[i].value.module_key,
            });
        }
    }

    onSubmit() {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            rK: this.roleKey,
        };

        let userInput = Object.assign(input, this.userRoleForm.value);

        this.tradeApiService.ApiServices(this.urlUpdatePrivilegeDetails, userInput, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.getRoleLists();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
        });

    }

    /**
     * Clear Search Data's
     */

    clearSearchData() {
        this.searchInputData = "";
        this.getRoleLists();
    }
}
