import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';

import Swal from 'sweetalert2';

import {TranslateService} from '@ngx-translate/core';

import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

@Component({
  selector: 'app-bo-live-postion-account-details',
  templateUrl: './bo-live-postion-account-details.component.html',
  styleUrls: ['./bo-live-postion-account-details.component.scss']
})
export class BoLivePostionAccountDetailsComponent implements OnInit {

  model: any;

  groups = [];
  logins = [];

  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  @ViewChild('instanceLogins', {static: true}) instanceLogins: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  focusL$ = new Subject<string>();
  clickL$ = new Subject<string>();

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.groups
        : this.groups.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 100))
    );
  }

   searchLogins = (text$: Observable<string>) => {
    const debouncedTextL$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopupL$ = this.clickL$.pipe(filter(() => !this.instanceLogins.isPopupOpen()));
    const inputFocusL$ = this.focusL$;

    return merge(debouncedTextL$, inputFocusL$, clicksWithClosedPopupL$).pipe(
      map(term => (term === '' ? this.logins
        : this.logins.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 100))
    );
  }

  getPositionMt5Login = '';
  getGroupDetails = '';

  getPositionMt5LoginErr = 0;
  getGroupDetailsErr = 0;


  userDetails;

  page = 1;
  totalItems = 0;
  itemsPerPage = 10;

  urlGetMyPositionDetails = 'my-position-details';
  urlGetMyAccountLogin = 'bo-account-login-details';
  urlGetGroupAccount = 'bo-account-group-details';
  myPositionDetails;    
  myHLoginsDetails;


  constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private translate: TranslateService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) {
    this.AuthorisedLayoutComponent.getRouteAccess();
    translate.setDefaultLang( this.commonService.defaultLanguale );
   }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('user_details'));   
    this.getLiveGroupDetail(); 
  }

  getMypostionDetails(){  

      this.getPositionMt5LoginErr = 0;
      this.getGroupDetailsErr = 0;


    let err = 0;
    


    if( this.getPositionMt5Login === undefined || this.getPositionMt5Login == ''){
      this.getPositionMt5LoginErr = 1;
      err = 1;
    }
    if( this.getGroupDetails === undefined ){
      this.getGroupDetailsErr = 1;
      err = 1;
    }

   if( err == 0){   
    this.getMypostionDetailsFromMt5();
   }
  }

  dateFormates( dateObject ){
    let d = new Date(dateObject);
    let day:any = d.getDate();
    let month:any = d.getMonth() + 1;
    let year = d.getFullYear();
    if (day < 10) {
        day = "0" + day;
    }
    if (month < 10) {
        month = "0" + month;
    }
    let date = year + "-" + month + "-" + day;

    return date;
  }

  getMypostionDetailsFromMt5(){
    this.commonService.showLoader = 1;
    let input = {
      //token:localStorage.getItem('auth_token'),                       
      uK:  this.userDetails.user_key,
      _dL: this.getPositionMt5Login,
      _dA: 2, // change to live 2    
      _p: this.page,
      _ty: this.userDetails.user_type,
    };

    this.tradeApiService.ApiServices(this.urlGetMyPositionDetails, input, null).subscribe((positionDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (positionDetails.status == 200) {
          
        this.myPositionDetails = positionDetails.positionDetails;
        this.totalItems = positionDetails.total;
      }
      else {
        const errorValues = Object.keys(positionDetails.message).map(key => positionDetails.message[key]);
        for(var i=0; i<errorValues.length;i++){                                  
          this.commonService.showToaster( errorValues[i] , 4 ); 
        }
      }

      this.commonService.showLoader = 0;

    });


  }

  loadPage(page: number) {
   
    this.page = page;
    this.getMypostionDetailsFromMt5();
  }

  getAccountLogin( groupName ){  
    this.commonService.showLoader = 1;

    let input = {
      //token:localStorage.getItem('auth_token'),                       
      uK:  this.userDetails.user_key,
      _dL: 2, 
      _LGrp: groupName, 
      _ty: this.userDetails.user_type,  
    };

    this.tradeApiService.ApiServices(this.urlGetMyAccountLogin, input, null).subscribe((GroupDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (GroupDetails.status == 200) {
        // console.log( GroupDetails.logins );
        this.myHLoginsDetails =  GroupDetails._grpLogins;   
        this.logins =  GroupDetails._grpLogins;   
      }
      else {
        const errorValues = Object.keys(GroupDetails.message).map(key => GroupDetails.message[key]);
        for(var i=0; i<errorValues.length;i++){                                  
          this.commonService.showToaster( errorValues[i] , 4 ); 
        }
      }

      this.commonService.showLoader = 0;

    });

  }

  getLiveGroupDetail( ){  
    this.commonService.showLoader = 1;

    let input = {                            
      uK:  this.userDetails.user_key,
      _dL: 2,       
      _ty: this.userDetails.user_type,  
    };

    this.tradeApiService.ApiServices(this.urlGetGroupAccount, input, null).subscribe((GroupDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (GroupDetails.status == 200) {
        // console.log( GroupDetails.logins );
        this.groups =  GroupDetails._grp;   
        this.myHLoginsDetails =  GroupDetails._grpLogins;   
        this.logins =  GroupDetails._grpLogins;   
      }
      else {
        const errorValues = Object.keys(GroupDetails.message).map(key => GroupDetails.message[key]);
        for(var i=0; i<errorValues.length;i++){                                  
          this.commonService.showToaster( errorValues[i] , 4 ); 
        }
      }

      this.commonService.showLoader = 0;

    });

  }

  selectedItem(item){
        // console.log(item);
        this.getAccountLogin(item.item);
  }

}
