import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets, RadialChartOptions } from 'chart.js';
import { SingleDataSet, Label } from 'ng2-charts';

import { ToastrService } from 'ngx-toastr'; // for notifications
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import {TranslateService} from '@ngx-translate/core';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  

  urlGetClientDashboradDetails = 'dashboard';

  userDetails;
  countDetails;
  AccuPendList;
  getCountsLiveAccountDetails;
  getTotalWithDrawalDepositeAmt;

  TotalCustomerCounts:any = '000';
  TotalKycSubmitted:any = '000';

  //production
  pieChartPlugins;
  barChartPlugins;


  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabelsRequestLive: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true; 

  pend = [];
  cred = [];
  rej = [];

  public barChartDataRequestLive: ChartDataSets[] = [
    { data: this.pend, label: '' },
    { data: this.cred, label: '' },
    { data: this.rej, label: '' }
  ];

  public barChartColors = [
    {
      backgroundColor: ['#eb880e', '#eb880e','#eb880e', '#eb880e','#eb880e','#eb880e','#eb880e'],
    },
  ];

   // Pie
   public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: number[] = [0, 0, 0];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;  
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];

    // PolarArea
    public polarAreaChartLabels: Label[] = [];
    public polarAreaChartData: SingleDataSet = [0, 0, 0, 0, 0];
    public polarAreaLegend = true;
  
    public polarAreaChartType: ChartType = 'polarArea';

     // Radar
  public radarChartOptions: RadialChartOptions = {
    responsive: true,
  };
  public radarChartLabels: Label[] = [];

  public radarChartData: ChartDataSets[] = [
    { data: [], label: '' },
    { data: [], label: '' }
  ];
  public radarChartType: ChartType = 'radar';


  constructor(private toastr: ToastrService, private tradeApiService: ApiTradeService, private commonService: CommonService, private translate: TranslateService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) { 
    this.AuthorisedLayoutComponent.getRouteAccess();
    translate.setDefaultLang( this.commonService.defaultLanguale );

    //KYC Document Status

    this.translate.get('adminDashboard.Waiting').subscribe(lang =>{
      this.pieChartLabels.push(lang);
    });
    this.translate.get('adminDashboard.Approved').subscribe(lang =>{
      this.pieChartLabels.push(lang);
    });
    this.translate.get('adminDashboard.ReSubmitted').subscribe(lang =>{
      this.pieChartLabels.push(lang);
    });
    this.translate.get('adminDashboard.Rejected').subscribe(lang =>{
      this.pieChartLabels.push(lang);
    });

    //Total Deposit Withdrawal Request

    this.translate.get('adminDashboard.Pending').subscribe(lang =>{
      this.polarAreaChartLabels.push(lang);
    });
    this.translate.get('adminDashboard.Processed').subscribe(lang =>{
      this.polarAreaChartLabels.push(lang);
    });
    this.translate.get('adminDashboard.InProgress').subscribe(lang =>{
      this.polarAreaChartLabels.push(lang);
    });
    this.translate.get('adminDashboard.Rejected').subscribe(lang =>{
      this.polarAreaChartLabels.push(lang);
    });
    
    //Deposite / Withdrawal 

    this.translate.get('adminDashboard.Deposit').subscribe(lang =>{
      this.radarChartData[0]['label'] = lang;
    });
    this.translate.get('adminDashboard.Withdrawal').subscribe(lang =>{
      this.radarChartData[1]['label'] = lang;
    });

    //Live Account Request  

    this.translate.get('adminDashboard.Pending').subscribe(lang =>{
      this.barChartDataRequestLive[0]['label'] = lang;
    });
    this.translate.get('adminDashboard.Created').subscribe(lang =>{
      this.barChartDataRequestLive[1]['label'] = lang;
    });
    this.translate.get('adminDashboard.Rejected').subscribe(lang =>{
      this.barChartDataRequestLive[2]['label'] = lang;
    });
  }

  

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('user_details'));
    this.GetDashboradDetails();
  }

  
  
  GetDashboradDetails(){
    console.log( 'GetDashboradDetails' );
    this.commonService.showLoader = 1;

    let input = {
      uK: this.userDetails.user_key,      
      _typ: this.userDetails.user_type,      
    };

    this.tradeApiService.ApiServices(this.urlGetClientDashboradDetails, input, null).subscribe((otpDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (otpDetails.status == 200) {
        console.log( otpDetails );    
        this.countDetails = otpDetails.DashboardLists;   
        this.AccuPendList = otpDetails.AccuPendList;   
        this.TotalCustomerCounts = otpDetails.getCustomerCounts;
        this.TotalKycSubmitted = otpDetails.getCustomerDocumentSubmittedToKycCounts;
        this.getCountsLiveAccountDetails = otpDetails.getCountsLiveAccountDetails;
        this.getTotalWithDrawalDepositeAmt = otpDetails.getTotalWithDrawalDepositeAmt;

        this.pieChartData = otpDetails.getCustomerDocumentSubmittedToKycAllCounts;
        this.polarAreaChartData = otpDetails.getTotalWithDrawalDepositeAmtRequestStatus;

        this.barChartLabelsRequestLive = otpDetails.getCustomerRequestLiveAccounts[0];
        this.radarChartLabels = otpDetails.getTotalWithDrawalDepositeAmtMnt[0];
      

        this.barChartDataRequestLive[0].data = otpDetails.getCustomerRequestLiveAccounts[1];
        this.barChartDataRequestLive[1].data = otpDetails.getCustomerRequestLiveAccounts[2];
        this.barChartDataRequestLive[2].data = otpDetails.getCustomerRequestLiveAccounts[3];

        this.radarChartData[0].data = otpDetails.getTotalWithDrawalDepositeAmtMnt[1];
        this.radarChartData[1].data = otpDetails.getTotalWithDrawalDepositeAmtMnt[2];
      

      }
      else {
        const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
        for(var i=0; i<errorValues.length;i++){                                  
          this.commonService.showToaster( errorValues[i] , 4 ); 
        }
      }

      this.commonService.showLoader = 0;

    });

  }


  
  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public randomize(): void {
    // Only Change 3 values
    const data = [
      Math.round(Math.random() * 100),
      59,
      80,
      (Math.random() * 100),
      56,
      (Math.random() * 100),
      40];
    this.barChartDataRequestLive[0].data = data;
  }

}
