import { Component, OnInit, EventEmitter, Output, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications
import { HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';
import { DOCUMENT } from '@angular/common';

import { AppComponent } from '../../app.component';
import { from } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-elements',
  templateUrl: './elements.component.html',
  styleUrls: ['./elements.component.scss']
})
export class ElementsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
