import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications
import { Lightbox } from 'ngx-lightbox'; // for image viewer
import { HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'app-back-office-kyc-view',
    templateUrl: './back-office-kyc-view.component.html',
    styleUrls: ['./back-office-kyc-view.component.scss']
})
export class BackOfficeKycViewComponent implements OnInit {
    fileData: File = null;
    closeResult: string;
    imagePathtmp: string;
    imagePathProfile: string;
    modalReference;
    kyc_button = false;
    isEnabled = false;
    kycHistory;

    // for document image upload

    clientDocument_1: string;
    clientDocument_2: string;
    clientDocument_3: string;
    clientDocument_4: string;
    clientDocument_5: string;
    clientDocument_6: string;
    clientDocument_7: string;
    clientDocument_8: string;

    // end document Image upload

    _albums: any = []; // for image preview

    registerForm: FormGroup;
    addressForm: FormGroup;
    changeEmailForm: FormGroup;
    sourceForm: FormGroup;
    statusChangesForm: FormGroup;
    questionForm: FormGroup;

    submitted = false;
    address_submitted = false;
    source_submitted = false;
    status_submit = false;
    submittedChangeEmail = false;
    document_required = 1;
    primary_doc_uploaded = 1;
    questionInfo = false;

    confirm = false;
    pwdPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}';
    numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
    passwordUpdateForm: FormGroup;
    submittedPassword = false;

    disabled = 1;

    newEmaildisabled = 0;
    newEmailShowOtp = 0;
    countryList = [];
    show_security_question = 0;

    // Customer
    urlProfile = 'customer-profile-update';
    urlAddressUpdate = 'customer-address-update';
    urlSourceUpdate = 'customer-source-update';
    urlQuestionUpdate = 'customer-question-update';
    urlDocDelete = 'customer-document-delete';
    urlProfileEmailMobileVerify = 'customer-mobile-email-opt';
    urlProfileEmailMobileOtpVerify = 'customer-mobile-email-opt-verify';
    urlProfileDetails = 'customer-profile-details';
    urlProfileImg = 'customer-profile-img';
    urlDocumentsImg = 'customer-documents';
    urlDocuments = 'verify-documents';
    urlProfileChangePassword = 'customer-profile-password-reset';
    urlProfileToKyc = 'submit-to-kyc';
    urlKycStatusSubmit = 'kyc-status-submit';
    currentUser;

    urlProfileEmailChange = 'customer-email-update-opt';

    employmentList: any = [];
    industryList: any = [];
    questionList: any = [];

    userDetails;
    is_email_verified = 0;
    is_mobile_verified = 0;

    ShowTxtMob = 1;
    ShowEnterOtpMob = 0;
    mobileNumberOtp = '';
    OtpVerifyMobileError = 0;

    userDocumentDetails;
    userDocuments;
    userAddressDetails;
    changeEmailNumberOtp = '';
    changeEmailNumberOtpError = 0;
    documentForm;
    userSourceDetails;

    identity_image: any = [];
    profile_image: any = [];
    all_the_docs;
    dob: any = [];
    selectedRecord;
    status_reason = false;

    kycStatus;
    genderList: any = [];

    selectedGender;
    otpShowLabChange = 0;
    employment = 0;
    disabled_buttons = true;
    pageFrom;

    maxDate;

    constructor(public route: ActivatedRoute, private router: Router, private modalService: NgbModal, private toastr: ToastrService, private _lightbox: Lightbox, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private http: HttpClient, private AuthorisedLayoutComponent: AuthorisedLayoutComponent, private translate: TranslateService) { //
        // form controll start  
        translate.setDefaultLang(this.commonService.defaultLanguale);
        this.AuthorisedLayoutComponent.getRouteAccess();


        this.maxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };

        this.passwordUpdateForm = this.formBuilder.group({
            newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.pwdPattern)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.pwdPattern)]],
            oldPassword: ['', [Validators.required, Validators.minLength(8)]],
        }, {
                validator: this.MustMatch('newPassword', 'confirmPassword')
            });
        this.route.queryParams.subscribe(params => {
            if (params.page) {
                this.pageFrom = params.page;
            }
        });

        //  this.pageFrom = this.router.getCurrentNavigation().extras.state.page
    }

    ngOnInit() {

        // console.log(this.pageFrom);
        if (this.pageFrom == 'customer') {
            this.disabled_buttons = false;
        }
        this.selectedRecord = window.localStorage.getItem('currentRecord');

        $(document).ready(() => {
            $('.delete_button').css('display', 'none');
        });
        this.userDetails = JSON.parse(localStorage.getItem('user_details'));
        this.currentUser = this.userDetails.user_key;
        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            gender: ['3', Validators.required],
            dob: [null, Validators.required],
            birthPlace: ['', Validators.required],
            middleName: [''],
        });

        this.addressForm = this.formBuilder.group({
            addressLine: ['', Validators.required],
            addressLine2: [''],
            postalCode: [''],
            nationality: ['', Validators.required],
            country: ['', Validators.required],
            city: ['', Validators.required],
        });

        this.sourceForm = this.formBuilder.group({
            employment: ['', Validators.required],
            annual_investment: ['', Validators.required],
            companyName: [''],
            industry: [''],
            position: [''],
            annual_income: [''],
        });

        this.questionForm = this.formBuilder.group({
            questionOne: ['', Validators.required],
            questionTwo: ['', Validators.required],
            answerOne: ['', Validators.required],
            answerTwo: ['', Validators.required]
        });

        this.statusChangesForm = this.formBuilder.group({
            reason: ['', Validators.required]
        });

        // const reason = this.sourceForm.get('reason');
        //   this.sourceForm.get('kyc_status').valueChanges
        //   .subscribe(kyc_status => { 
        //     if (kyc_status == 3) {          
        //       reason.setValidators([Validators.required]);       
        //       return;   
        //     }
        //     reason.updateValueAndValidity();
        //   });

        this.getProfileDetails();

        this.changeEmailForm = this.formBuilder.group({
            newEmail: ['', [Validators.required, Validators.email]],
        });

        this.documentForm = this.formBuilder.group({
            clientDocument_2: [''],
        });

    }

    genderListArray() {

        var Male;
        this.translate.get('clientUserProfile.Male').subscribe(lang => {
            Male = lang;
        });

        var Female;
        this.translate.get('clientUserProfile.Female').subscribe(lang => {
            Female = lang;
        });

        this.genderList = [{
            'id': '1',
            'name': Male
        }, {
            'id': '2',
            'name': Female
        }];
    }

    employmentListArray() {

        var Salaried;
        this.translate.get('clientUserProfile.Salaried').subscribe(lang => {
            Salaried = lang;
        });

        var SelfEmployement;
        this.translate.get('clientUserProfile.SelfEmployement').subscribe(lang => {
            SelfEmployement = lang;
        });

        var Business;
        this.translate.get('clientUserProfile.Business').subscribe(lang => {
            Business = lang;
        });

        var UnEmployement;
        this.translate.get('clientUserProfile.UnEmployement').subscribe(lang => {
            UnEmployement = lang;
        });

        this.employmentList = [{
            'id': 1,
            'name': Salaried
        }, {
            'id': 2,
            'name': SelfEmployement
        }, {
            'id': 3,
            'name': Business
        }, {
            'id': 4,
            'name': UnEmployement
        }];
    }

    industryListListArray() {

        var Computer;
        this.translate.get('clientUserProfile.Computer').subscribe(lang => {
            Computer = lang;
        });
        var TeleCommunication;
        this.translate.get('clientUserProfile.TeleCommunication').subscribe(lang => {
            TeleCommunication = lang;
        });
        var Agriculture;
        this.translate.get('clientUserProfile.Agriculture').subscribe(lang => {
            Agriculture = lang;
        });
        var Construction;
        this.translate.get('clientUserProfile.Construction').subscribe(lang => {
            Construction = lang;
        });
        var Pharamedical;
        this.translate.get('clientUserProfile.Pharamedical').subscribe(lang => {
            Pharamedical = lang;
        });
        var Education;
        this.translate.get('clientUserProfile.Education').subscribe(lang => {
            Education = lang;
        });
        var Transport;
        this.translate.get('clientUserProfile.Transport').subscribe(lang => {
            Transport = lang;
        });
        var Aerospace;
        this.translate.get('clientUserProfile.Aerospace').subscribe(lang => {
            Aerospace = lang;
        });
        var Food;
        this.translate.get('clientUserProfile.Food').subscribe(lang => {
            Food = lang;
        });

        this.industryList = [{
            'id': 1,
            'name': Computer
        }, {
            'id': 2,
            'name': TeleCommunication
        }, {
            'id': 3,
            'name': Agriculture
        }, {
            'id': 4,
            'name': Construction
        }, {
            'id': 5,
            'name': Pharamedical
        }, {
            'id': 6,
            'name': Education
        }, {
            'id': 7,
            'name': Transport
        }, {
            'id': 8,
            'name': Aerospace
        }, {
            'id': 9,
            'name': Food
        }];
    }

    // public loadScript(url: string) {
    //   const body = <HTMLDivElement> document.body;
    //   const script = document.createElement('script');
    //   script.innerHTML = '';
    //   script.src = url;
    //   script.async = false;
    //   script.defer = true;
    //   body.appendChild(script);
    // }
    /**
     * Assigning the form values
     */

    formValueSet() {

        //this.selectedEmployment = this.userSourceDetails.employment
    }

    deleteDoc(docNumber) {

        var Delete;
        this.translate.get('clientUserProfile.Delete').subscribe(lang => {
            Delete = lang;
        });

        var ValidReasonRequired;
        this.translate.get('clientUserProfile.ValidReasonRequired').subscribe(lang => {
            ValidReasonRequired = lang;
        });

        var Warning;
        this.translate.get('clientUserProfile.Warning').subscribe(lang => {
            Warning = lang;
        });

        var YouCouldNotAbleRecoverOnceYouDeleteIt;
        this.translate.get('clientUserProfile.YouCouldNotAbleRecoverOnceYouDeleteIt').subscribe(lang => {
            YouCouldNotAbleRecoverOnceYouDeleteIt = lang;
        });

        var AreYouSureWantToDeleteThisPicture;
        this.translate.get('clientUserProfile.AreYouSureWantToDeleteThisPicture').subscribe(lang => {
            AreYouSureWantToDeleteThisPicture = lang;
        });

        Swal.mixin({
            confirmButtonText: Delete + ' &rarr;',
            showCancelButton: true,
            inputValidator: (result) => {
                return !result && ValidReasonRequired
            }
            // progressSteps: ['1']
        }).queue([{
            title: '<h5> <b>' + Warning + ' : </b> ' + YouCouldNotAbleRecoverOnceYouDeleteIt + ' \n ' + AreYouSureWantToDeleteThisPicture + '</h5>'
        }]).then((result) => {
            if (result.value) {
                this.confirmDelete(docNumber);
            }
        })
    }

    confirmDelete(docNumber) {
        var params = {
            user_key: this.userDetails.user_key,
            doc_number: docNumber
        };

        this.identity_image = this.identity_image.filter(x => x.dL !== docNumber);
        this.profile_image = this.profile_image.filter(x => x.dL !== docNumber);

        this.commonService.showLoader = 1;
        this.tradeApiService.ApiServices(this.urlDocDelete, params, null).subscribe((requestDetails: any) => {
            if (requestDetails.status == 200) {
                this.documentValueSet()
                this.commonService.showToaster(requestDetails.message, 1);
                $('#' + requestDetails.docNumber).parent().parent().find('img').attr('src', 'assets/media/image/image-not-found.png');
                //$('#'+requestDetails.docNumber).closest('p.card-text').css('display', 'none');
                $('#' + requestDetails.docNumber).css('display', 'none');
                $('#' + 'Preview_' + requestDetails.docNumber).css('display', 'none');
                // $('#'+'Upload_'+requestDetails.docNumber).css('display','none');              
                if (requestDetails.docNumber == 'clientDocument_1' || requestDetails.docNumber == 'clientDocument_3') {
                    $('#source-tab').not('.active').addClass('disabled');
                    $('#preview-tab').not('.active').addClass('disabled');
                    this.isEnabled = false;
                }
                this.getProfileDetails();
            } else {
                this.commonService.showToaster(requestDetails.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    open(content, kyc = 0) {
        this.kycStatus = kyc;
        this.modalReference = this.modalService.open(content);
        this.clearStatusForm();
        this.modalService.open(content, {
            ariaLabelledBy: 'modal-basic-title'
        }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    clearStatusForm() {
        this.status_submit = false;
        this.statusChangesForm.clearValidators();
        this.statusChangesForm.reset();
        this.statusChangesForm.markAsPristine();
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    onSelect(imageEvents) {
        this.imagePathtmp = imageEvents;
    }
    onReset() {
        console.log('onReset');
    }

    // scroll to div
    scroll(el: HTMLElement) {
        el.scrollIntoView();
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    // image validaions

    readURL(event: any, clientDocument: any, img_type) {
        // console.log(clientDocument);

        var FileExceedsTheMaximumSize;
        this.translate.get('clientUserProfile.FileExceedsTheMaximumSize').subscribe(lang => {
            FileExceedsTheMaximumSize = lang;
        });

        var UploadOnlyFormat;
        this.translate.get('clientUserProfile.UploadOnlyFormat').subscribe(lang => {
            UploadOnlyFormat = lang;
        });

        var info;
        this.translate.get('clientUserProfile.info').subscribe(lang => {
            info = lang;
        });

        if (event.target.files && event.target.files[0]) {
            var size = event.target.files[0].size;
            var type = event.target.files[0].type;

            if (Math.round(size / 1024) > 2000) {
                this.toastr.info(FileExceedsTheMaximumSize, info);
                return false;
            }

            if (type == 'image/jpeg' || type == 'image/png') {
                var reader = new FileReader();

                reader.onload = (event: any) => {
                    this.documentImageMapping(clientDocument, event.target.result);
                    // this.commonService.showLoader = 1;
                    /**
                     *  Uploading the documents
                     */
                    if (img_type == 1 || img_type == 2) {
                        var i1 = {
                            uK: this.userDetails.user_key,
                            u_Di: event.target.result,
                            dL: clientDocument,
                            type: img_type
                        };
                        this.identity_image.push(i1);
                        // this.identity_image = {
                        //   //token:localStorage.getItem('auth_token'),                       
                        //     uK:this.userDetails.user_key, 
                        //     u_Di: event.target.result,
                        //     dL: clientDocument,
                        //     type:img_type                                
                        //   };
                    } else if (img_type == 3 || img_type == 4 || img_type == 5 || img_type == 6 || img_type == 7 || img_type == 8) {
                        var i2 = {
                            uK: this.userDetails.user_key,
                            u_Di: event.target.result,
                            dL: clientDocument,
                            type: img_type
                        };
                        this.profile_image.push(i2);
                    }
                    this.uploadIdentity();
                }
                reader.readAsDataURL(event.target.files[0]);
            } else {
                this.toastr.info(UploadOnlyFormat, info);
                return false;
            }
        }
    }

    /* Documents Tab -  Upload Documents  */
    uploadIdentity() {
        /*	
         *   Begin Validate Primary Photo and Document	
         *   Variable document_required is checking already uploaded or not 	
         *   Then checking currently selected or not	
         */

        this.commonService.showLoader = 1;
        this.all_the_docs = {
            'identity': this.identity_image,
            'profile': this.profile_image
        };
        this.tradeApiService.ApiServices(this.urlDocuments, this.all_the_docs, null).subscribe((documentImg: any) => {
            if (documentImg.status == 200) {
                this.getProfileDetails();
                this.commonService.showToaster(documentImg.message, 1);
            } else {
                this.commonService.showToaster(documentImg.message, 1);
            }
            this.commonService.showLoader = 0;
        });
    }

    // open document image 
    Sopen(index: number): void {
        // open lightbox    
        this._lightbox.open(this._albums, index);
    }

    SopenView(val) {
        var skinName = this._albums.findIndex(x => x.src == val);
        this._lightbox.open(this._albums, skinName);
    }

    close(): void {
        // close lightbox programmatically
        this._lightbox.close();
    }
    // End document image 

    // convenience getter for easy access to form fields
    get f() {
        return this.registerForm.controls;
    }
    get address() {
        return this.addressForm.controls;
    }
    get sources() {
        return this.sourceForm.controls;
    }
    get fv() {
        return this.passwordUpdateForm.controls;
    }
    get fmail() {
        return this.changeEmailForm.controls;
    }
    get schanges() {
        return this.statusChangesForm.controls;
    }
    get questions() {
        return this.questionForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        this.commonService.showLoader = 1;

        let input = {
            //token:localStorage.getItem('auth_token'),  
            date_of_birth: this.registerForm.value.dob.year + '-' + this.registerForm.value.dob.month + '-' + this.registerForm.value.dob.day,
            uK: this.userDetails.user_key,
            clientKey: this.currentUser,
        };

        let userInput = Object.assign(input, this.registerForm.value);
        this.tradeApiService.ApiServices(this.urlProfile, userInput, null).subscribe((loginDetails: any) => {
            if (loginDetails.status == 200) {
                this.commonService.showToaster(loginDetails.message, 1);
                //localStorage.setItem('user_details', JSON.stringify(loginDetails.pU));
                this.userDetails = loginDetails.pU;
                this.showTabActive('address', 'timeline', 'address');
                $('#address-tab').not('.active').removeClass('disabled');
                this.getProfileDetails();
            } else {
                this.commonService.showToaster(loginDetails.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    onAddress() {
        this.address_submitted = true;
        // stop here if form is invalid

        if (this.addressForm.invalid) {
            return;
        }
        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            clientKey: this.currentUser,
        };

        let userInput = Object.assign(input, this.addressForm.value);
        this.tradeApiService.ApiServices(this.urlAddressUpdate, userInput, null).subscribe((loginDetails: any) => {
            if (loginDetails.status == 200) {
                this.commonService.showToaster(loginDetails.message, 1);
                $('#photos-tab').not('.active').removeClass('disabled');
                this.showTabActive('photos', 'address', 'photos');
                this.getProfileDetails();
                // localStorage.setItem('user_details',  JSON.stringify(loginDetails.pU));
                // this.userDetails = loginDetails.pU;        
            } else {
                this.commonService.showToaster(loginDetails.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    onSource() {
        this.source_submitted = true;
        // stop here if form is invalid      

        if (this.sourceForm.invalid) {
            return;
        }
        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            clientKey: this.currentUser,
        };

        let userInput = Object.assign(input, this.sourceForm.value);
        this.tradeApiService.ApiServices(this.urlSourceUpdate, userInput, null).subscribe((loginDetails: any) => {
            if (loginDetails.status == 200) {
                this.commonService.showToaster(loginDetails.message, 1);
                $('#source-tab').not('.active').removeClass('disabled');
                this.showTabActive('preview', 'source', 'preview');
                this.kyc_button = true;
                this.getProfileDetails();
                // localStorage.setItem('user_details',  JSON.stringify(loginDetails.pU));
                // this.userDetails = loginDetails.pU;        
            } else {
                this.commonService.showToaster(loginDetails.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    questionListArray() {

        let QuestionOne;
        this.translate.get('clientUserProfile.QuestionOne').subscribe(lang => {
            QuestionOne = lang;
        });

        let QuestionTwo;
        this.translate.get('clientUserProfile.QuestionTwo').subscribe(lang => {
            QuestionTwo = lang;
        });

        let QuestionThree;
        this.translate.get('clientUserProfile.QuestionThree').subscribe(lang => {
            QuestionThree = lang;
        });

        let QuestionFour;
        this.translate.get('clientUserProfile.QuestionFour').subscribe(lang => {
            QuestionFour = lang;
        });

        let QuestionFive;
        this.translate.get('clientUserProfile.QuestionFive').subscribe(lang => {
            QuestionFive = lang;
        });

        let QuestionSix;
        this.translate.get('clientUserProfile.QuestionSix').subscribe(lang => {
            QuestionSix = lang;
        });

        let QuestionSeven;
        this.translate.get('clientUserProfile.QuestionSeven').subscribe(lang => {
            QuestionSeven = lang;
        });

        let QuestionEight;
        this.translate.get('clientUserProfile.QuestionEight').subscribe(lang => {
            QuestionEight = lang;
        });

        this.questionList = [{
            id: 1,
            name: QuestionOne
        }, {
            id: 2,
            name: QuestionTwo
        }, {
            id: 3,
            name: QuestionThree
        }, {
            id: 4,
            name: QuestionFour
        }, {
            id: 5,
            name: QuestionFive
        }, {
            id: 6,
            name: QuestionSix
        }, {
            id: 7,
            name: QuestionSeven
        }, {
            id: 8,
            name: QuestionEight
        }];
    }

    //password compare

    MustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }

            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                this.confirm = true;
                matchingControl.setErrors({
                    mustMatch: true
                });
            } else {
                this.confirm = false;
                matchingControl.setErrors(null);
            }
        }
    }

    fileProgress(fileInput: any) {
        this.fileData = <File>fileInput.target.files[0];
    }

    onUpload() {
        const formData = new FormData();
        formData.append('file_name', this.fileData);
        this.tradeApiService.ApiServices(this.urlDocuments, formData, null).subscribe((documentImg: any) => {
            //this.http.post('verify-documents', formData)
            // if(documentImg.status == 200){  
            //   this.getProfileDetails();
            //   this.commonService.showToaster( documentImg.message , 4 ); 
            // }
            // else{                    
            //   this.commonService.showToaster( documentImg.message , 4 ); 
            // }
            // this.commonService.showLoader = 0;        
            // alert('SUCCESS !!');
        });
    }

    updatePassword() {

        this.submittedPassword = true;

        // stop here if form is invalid
        if (this.passwordUpdateForm.invalid) {
            return;
        }

        let input = {
            uK: this.userDetails.user_key,
        };

        this.commonService.showLoader = 1;

        let userInput = Object.assign(input, this.passwordUpdateForm.value);

        this.tradeApiService.ApiServices(this.urlProfileChangePassword, userInput, null).subscribe((changePassword: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (changePassword.status == 200) {
                this.commonService.showToaster(changePassword.message, 1);
                this.clearForm();
            } else {
                const errorValues = Object.keys(changePassword.message).map(key => changePassword.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }

            this.commonService.showLoader = 0;

        });


    }

    /**
     * Clearing the form data for change password
     */

    clearForm() {

        this.submittedPassword = false;
        this.passwordUpdateForm.clearValidators();
        this.passwordUpdateForm.reset();
        this.passwordUpdateForm.markAsPristine();

    }

    /**
     *  Function for sending the otp for verifying the mobile number
     * 
     */

    sendOtpVerifyMobileEmail(eM) {

        this.commonService.showLoader = 1;
        var emailOrMobile = '';
        if (eM == 1) {
            emailOrMobile = this.userDetails.user_email;
        }
        if (eM == 2) {
            emailOrMobile = this.userDetails.user_mobile;
        }

        let input = {
            //token:localStorage.getItem('auth_token'),                       
            uK: this.userDetails.user_key,
            uM: emailOrMobile,
            _eM: eM
        };

        this.tradeApiService.ApiServices(this.urlProfileEmailMobileVerify, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (otpDetails.status == 200) {

                this.ShowTxtMob = 0;
                this.ShowEnterOtpMob = 1;

                this.commonService.showToaster(otpDetails.message, 1);
            } else {
                this.commonService.showToaster(otpDetails.message, 4);
            }

            this.commonService.showLoader = 0;

        });


    }

    /**
     * Once User Received OPT
     */
    OtpVerifyMobileEmail(eM) {

        // console.log('OtpVerifyMobile');
        // console.log(this.mobileNumberOtp);
        this.OtpVerifyMobileError = 0;
        if (this.mobileNumberOtp == '') {
            this.OtpVerifyMobileError = 1;
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
            //token:localStorage.getItem('auth_token'),                       
            uK: this.userDetails.user_key,
            uO: this.mobileNumberOtp,
            _eM: eM
        };

        this.tradeApiService.ApiServices(this.urlProfileEmailMobileOtpVerify, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (otpDetails.status == 200) {

                this.ShowTxtMob = 0;
                this.ShowEnterOtpMob = 0;
                this.is_mobile_verified = 1;

                this.commonService.showToaster(otpDetails.message, 1);
                this.modalService.dismissAll();
                this.getProfileDetails();
            } else {
                this.OtpVerifyMobileError = 1;
                this.commonService.showToaster(otpDetails.message, 4);
            }

            this.commonService.showLoader = 0;
        });
    }

    /**
     * Fecting the profile Details
     */
    getProfileDetails() {
        this.commonService.showLoader = 1;

        let input = {
            //token:localStorage.getItem('auth_token'),                       
            uK: this.selectedRecord,
        };

        this.tradeApiService.ApiServices(this.urlProfileDetails, input, null).subscribe((otpDetails: any) => {

            if (otpDetails.status == 200) {

                this.genderListArray();
                this.employmentListArray();
                this.industryListListArray();
                this.questionListArray();

                //this.userDetails = otpDetails.pU;
                //localStorage.setItem('user_details', JSON.stringify(otpDetails.pU));
                this.countryList = otpDetails.countryList;
                this.userDetails = otpDetails.pU;
                this.userDocumentDetails = otpDetails.pD;
                this.userDocuments = otpDetails.pD;
                this.userAddressDetails = otpDetails.pK;
                this.userSourceDetails = otpDetails.pS;
                this.kycHistory = otpDetails.kH;
                this.show_security_question = otpDetails.sqw;
                if (this.userDetails != '') {
                    this.registerForm = this.formBuilder.group({
                        firstName: this.userDetails.user_name,
                        lastName: this.userDetails.last_name,
                        middleName: this.userDetails.middle_name,
                        nationality: this.userDetails.nationality,
                        gender: this.userDetails.gender.toString(),
                        birthPlace: this.userDetails.birth_place,
                        dob: null
                    });

                    if (this.userDetails.gender == 0 || this.userDetails.gender == 'null') {
                        this.registerForm.controls['gender'].setValue('1');
                    }

                    if (this.userDetails.dob == '' || this.userDetails.dob == 'null' || this.userDetails.dob == null) {
                        this.registerForm.controls['dob'].setValue(new Date());
                    } else {
                        this.registerForm.controls['dob'].setValue({
                            year: new Date(this.userDetails.dob).getUTCFullYear(),
                            month: new Date(this.userDetails.dob).getMonth() + 1,
                            day: new Date(this.userDetails.dob).getDate()
                        });
                    }
                }

                if (this.userSourceDetails != '') {
                    this.sourceForm = this.formBuilder.group({
                        employment: this.userSourceDetails.employment,
                        companyName: this.userSourceDetails.company,
                        industry: this.userSourceDetails.industry,
                        position: this.userSourceDetails.position,
                        annual_income: this.userSourceDetails.annual_income,
                        annual_investment: this.userSourceDetails.annual_investment
                    });
                    // if(this.userAddressDetails.kyc_status == 3 || this.userAddressDetails.kyc_status == 4){                
                    //   this.status_reason = true;
                    // }
                }

                if (this.userDocumentDetails != '') {
                    var j = 1;
                    for (let index = 0; index < this.userDocumentDetails.length; index++) {
                        const element = this.userDocumentDetails[index];
                        console.log(element.user_document_number)
                        if (element.user_document_number == 'clientDocument_1' || element.user_document_number == 'clientDocument_3') {
                            this.document_required = j;
                            j++;
                        }
                    }
                }

                if (this.userSourceDetails.employment != null && this.userSourceDetails.annual_investment != null) {
                    this.kyc_button = true;
                }

                // address form
                if (this.userAddressDetails != '') {
                    this.addressForm = this.formBuilder.group({
                        addressLine: this.userAddressDetails.address_one,
                        addressLine2: this.userAddressDetails.address_two,
                        postalCode: this.userAddressDetails.postal_code,
                        country: this.userAddressDetails.country_key,
                        city: this.userAddressDetails.city,
                        nationality: this.userAddressDetails.nationality
                    });

                    this.questionForm = this.formBuilder.group({
                        questionOne: this.userAddressDetails.question_one,
                        questionTwo: this.userAddressDetails.question_two,
                        answerOne: this.userAddressDetails.answer_one,
                        answerTwo: this.userAddressDetails.answer_two
                    });


                }
                this.documentValueSet();
            } else {
                this.commonService.showToaster(otpDetails.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Assing the document image URL to popup image viewer and thumline
     */

    documentValueSet() {
        this._albums = []; // for image preview
        // console.log(this.userDocumentDetails);
        var i = this.userDocumentDetails.length;

        for (var doc of this.userDocumentDetails) {

            // for image preview
            const src = doc.image;
            const caption = 'Document ' + i;
            const thumb = doc.image;
            const id = 'Document ' + i;
            const album = {
                src: src,
                // caption: caption,
                caption: '',
                thumb: thumb,
            };

            // Document upload #060
            $('#' + doc.user_document_number).css('display', 'block');
            $('#' + 'Preview_' + doc.user_document_number).css('display', 'block');

            this._albums.push(album);

            // end for image preview

            this.documentImageMapping(doc.user_document_number, doc.image);
            i--;
        }
    }

    /**
     * Here Mapping the document Image for thumline
     */

    documentImageMapping(docNuber, FileUrl) {

        switch (docNuber) {
            case 'clientDocument_1':
                this.clientDocument_1 = FileUrl;
                break;
            case 'clientDocument_2':
                this.clientDocument_2 = FileUrl;
                break;
            case 'clientDocument_3':
                this.clientDocument_3 = FileUrl;
                break;
            case 'clientDocument_4':
                this.clientDocument_4 = FileUrl;
                break;
            case 'clientDocument_5':
                this.clientDocument_5 = FileUrl;
                break;
            case 'clientDocument_6':
                this.clientDocument_6 = FileUrl;
                break;
            case 'clientDocument_7':
                this.clientDocument_7 = FileUrl;
                break;
            case 'clientDocument_8':
                this.clientDocument_8 = FileUrl;
                break;
        }

    }


    // uploading the profile / img url to the image tag

    uploadProfileImg() {

        this.commonService.showLoader = 1;

        let input = {
            //token:localStorage.getItem('auth_token'),                       
            uK: this.userDetails.user_key,
            u_Pi: this.imagePathtmp
        };

        this.tradeApiService.ApiServices(this.urlProfileImg, input, null).subscribe((profileImg: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (profileImg.status == 200) {
                this.imagePathProfile = profileImg.profile;
                this.commonService.userProfileImg = profileImg.profile;
                this.commonService.showToaster(profileImg.message, 1);
                this.getProfileDetails();
                this.modalService.dismissAll();
            } else {
                this.commonService.showToaster(profileImg.message, 4);
            }

            this.commonService.showLoader = 0;

        });

    }

    /**
     *  Edit Customer Email
     */

    editCustomerEmail(withOpt = 1) {
        // console.log('editCustomerEmail');
        this.submittedChangeEmail = true;

        // stop here if form is invalid
        if (this.changeEmailForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            wOpt: withOpt
        };


        let userInput = Object.assign(input, this.changeEmailForm.value);

        // console.log(userInput);

        if (withOpt == 2) {
            let Otpinput = {
                oE: this.changeEmailNumberOtp,
            };
            userInput = Object.assign(Otpinput, userInput);
        }

        this.tradeApiService.ApiServices(this.urlProfileEmailChange, userInput, null).subscribe((otpDetails: any) => {
            if (otpDetails.status == 200) {
                this.newEmaildisabled = 1;
                this.newEmailShowOtp = 1;
                this.commonService.showToaster(otpDetails.message, 1);
                if (withOpt == 2) {
                    this.getProfileDetails();
                    this.modalService.dismissAll();
                }

            } else {
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }
    /**
     * verify Change Email Otp
     */

    verifyChangeEmailOtp() {
        this.changeEmailNumberOtpError = 0;

        if (this.changeEmailNumberOtp == '') {
            this.changeEmailNumberOtpError = 1;
            return;
        }
        // console.log(this.changeEmailNumberOtp, this.changeEmailNumberOtpError)
        this.editCustomerEmail(2);
    }

    clearFormEmail() {

        this.submittedChangeEmail = false;
        this.changeEmailForm.clearValidators();
        this.changeEmailForm.reset();
        this.changeEmailForm.markAsPristine();

        this.newEmaildisabled = 0;
        this.newEmailShowOtp = 0;
        this.changeEmailNumberOtp = '';
        this.changeEmailNumberOtpError = 0;
    }

    changeNamePopOtp(showLab) {
        // console.log(showLab)
        this.otpShowLabChange = showLab;
    }

    /** 
     * Once Submitted Disable to the all the inputs.
     */
    submit_kyc() {
        var params = {
            'user_key': this.userDetails.user_key
        };
        this.tradeApiService.ApiServices(this.urlProfileToKyc, params, null).subscribe((otpDetails: any) => {
            if (otpDetails.status == 200) {
                $('#timeline :input, #address :input, #photos :input, #source :input').prop('disabled', true);
                this.kyc_button = false;
            } else {
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /** 
     * If person unemployment disabled the un-necessary inputs
     */
    employementStatus(event: any) {
        if (event.target.value == 4) {
            $('input[name=companyName], select#industry, input[name=position], input[name=annual_income]').prop('disabled', true);
        } else {
            $('input[name=companyName], select#industry, input[name=position], input[name=annual_income]').prop('disabled', false);
        }
    }

    historyBack() {
        window.history.go(-1);
        return false;
    }

    // kycUpdate(event:any){    
    //   const reason = this.sourceForm.get('reason');
    //   if(event.target.value == 3 || event.target.value == 4){
    //     reason.setValidators([Validators.required]);   
    //     this.status_reason = true;
    //   } else if(event.target.value == 2) {
    //     reason.setValidators(null);         
    //     this.status_reason = false;
    //   }       
    //   reason.updateValueAndValidity();
    // }

    getGender(val) {
        var Male;
        this.translate.get('clientUserProfile.Male').subscribe(lang => {
            Male = lang;
        });

        var Female;
        this.translate.get('clientUserProfile.Female').subscribe(lang => {
            Female = lang;
        });

        var Not_Interst_To_Say;
        this.translate.get('clientUserProfile.Not_Interst_To_Say').subscribe(lang => {
            Not_Interst_To_Say = lang;
        });

        if (val == 1) {
            return Male
        }
        if (val == 2) {
            return Female
        }
        if (val == 3) {
            return Not_Interst_To_Say
        }
    }

    getEmploymentStatus(id) {

        var Salaried;
        this.translate.get('clientUserProfile.Salaried').subscribe(lang => {
            Salaried = lang;
        });

        var SelfEmployement;
        this.translate.get('clientUserProfile.SelfEmployement').subscribe(lang => {
            SelfEmployement = lang;
        });

        var Business;
        this.translate.get('clientUserProfile.Business').subscribe(lang => {
            Business = lang;
        });

        var UnEmployement;
        this.translate.get('clientUserProfile.UnEmployement').subscribe(lang => {
            UnEmployement = lang;
        });

        if (id == 1) {
            return Salaried;
        }
        if (id == 2) {
            return SelfEmployement;
        }
        if (id == 3) {
            return Business;
        }
        if (id == 4) {
            return UnEmployement;
        }
    }

    getIndustry(id) {

        var Computer;
        this.translate.get('clientUserProfile.Computer').subscribe(lang => {
            Computer = lang;
        });
        var TeleCommunication;
        this.translate.get('clientUserProfile.TeleCommunication').subscribe(lang => {
            TeleCommunication = lang;
        });
        var Agriculture;
        this.translate.get('clientUserProfile.Agriculture').subscribe(lang => {
            Agriculture = lang;
        });
        var Construction;
        this.translate.get('clientUserProfile.Construction').subscribe(lang => {
            Construction = lang;
        });
        var Pharamedical;
        this.translate.get('clientUserProfile.Pharamedical').subscribe(lang => {
            Pharamedical = lang;
        });
        var Education;
        this.translate.get('clientUserProfile.Education').subscribe(lang => {
            Education = lang;
        });
        var Transport;
        this.translate.get('clientUserProfile.Transport').subscribe(lang => {
            Transport = lang;
        });
        var Aerospace;
        this.translate.get('clientUserProfile.Aerospace').subscribe(lang => {
            Aerospace = lang;
        });
        var Food;
        this.translate.get('clientUserProfile.Food').subscribe(lang => {
            Food = lang;
        });

        if (id == 1) {
            return Computer;
        }
        if (id == 2) {
            return TeleCommunication;
        }
        if (id == 3) {
            return Agriculture;
        }
        if (id == 4) {
            return Construction;
        }
        if (id == 5) {
            return Pharamedical;
        }
        if (id == 6) {
            return Education;
        }
        if (id == 7) {
            return Transport;
        }
        if (id == 8) {
            return Aerospace;
        }
        if (id == 9) {
            return Food;
        }
    }

    /* KYC Status Reject and Block */
    kycStatusSubmit() {
        this.status_submit = true;
        // stop here if form is invalid

        if (this.statusChangesForm.invalid) {
            return;
        }
        this.commonService.showLoader = 1;

        let input = {
            clientKey: this.userDetails.user_key,
            uK: this.currentUser,
            kyc_status: this.kycStatus,
        };

        let userInput = Object.assign(input, this.statusChangesForm.value);

        var StatusUpdatedSuccessfully;
        this.translate.get('clientUserProfile.StatusUpdatedSuccessfully').subscribe(lang => {
            StatusUpdatedSuccessfully = lang;
        });

        var TryAgainLater;
        this.translate.get('clientUserProfile.TryAgainLater').subscribe(lang => {
            TryAgainLater = lang;
        });

        this.tradeApiService.ApiServices(this.urlKycStatusSubmit, userInput, null).subscribe((response: any) => {
            if (response.status == 200) {
                this.commonService.showToaster(StatusUpdatedSuccessfully, 1);
            } else {
                this.commonService.showToaster(TryAgainLater, 4);
            }
            this.closeModal();
            this.commonService.showLoader = 0;
            this.router.navigate(['/kyc-lists']);
        });
    }



    /* KYC Status Approve */
    kycStatusApprove() {
        /* This is getting current logged user info */

        this.closeModal();
        let input = {
            clientKey: this.userDetails.user_key,
            uK: this.currentUser,
            kyc_status: 2
        };

        this.commonService.showLoader = 1;

        var StatusUpdatedSuccessfully;
        this.translate.get('clientUserProfile.StatusUpdatedSuccessfully').subscribe(lang => {
            StatusUpdatedSuccessfully = lang;
        });

        var SomethingWentWrongContactAdmin;
        this.translate.get('clientUserProfile.SomethingWentWrongContactAdmin').subscribe(lang => {
            SomethingWentWrongContactAdmin = lang;
        });

        this.tradeApiService.ApiServices(this.urlKycStatusSubmit, input, null).subscribe((response: any) => {
            if (response.status == 200) {
                this.commonService.showToaster(StatusUpdatedSuccessfully, 1);
            } else {
                this.commonService.showToaster(SomethingWentWrongContactAdmin, 4);
            }
            this.commonService.showLoader = 0;
            this.router.navigate(['/kyc-lists']);
        });
    }

    deleteConfirmation() {

        var Approve;
        this.translate.get('clientUserProfile.Approve').subscribe(lang => {
            Approve = lang;
        });
        var ValidReasonRequired;
        this.translate.get('clientUserProfile.ValidReasonRequired').subscribe(lang => {
            ValidReasonRequired = lang;
        });
        var AreYouSureWantApproveRequest;
        this.translate.get('clientUserProfile.AreYouSureWantApproveRequest').subscribe(lang => {
            AreYouSureWantApproveRequest = lang;
        });

        Swal.mixin({
            confirmButtonText: Approve + ' &rarr;',
            showCancelButton: true,
            inputValidator: (result) => {
                return !result && ValidReasonRequired
            }
            // progressSteps: ['1']
        }).queue([{
            title: '<h5> ' + AreYouSureWantApproveRequest + ' </h5>'
        }]).then((result) => {
            if (result.value) {
                this.kycStatusApprove();
            }
        });
    }

    onQuestion() {
        this.questionInfo = true;
        if (this.questionForm.invalid) {
            return;
        }
        const input = {
            uK: this.userDetails.user_key,
        };

        this.commonService.showLoader = 1;
        const userInput = Object.assign(input, this.questionForm.value);
        this.tradeApiService.ApiServices(this.urlQuestionUpdate, userInput, null).subscribe((loginDetails: any) => {
            if (loginDetails.status == 200) {
                this.commonService.showToaster(loginDetails.message, 1);
                this.getProfileDetails();
            } else {
                this.commonService.showToaster(loginDetails.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    kycStatusVal(val) {

        var WaitingApproval;
        this.translate.get('clientUserProfile.WaitingApproval').subscribe(lang => {
            WaitingApproval = lang;
        });

        var Approved;
        this.translate.get('clientUserProfile.Approved').subscribe(lang => {
            Approved = lang;
        });

        var ReSubmitted;
        this.translate.get('clientUserProfile.ReSubmitted').subscribe(lang => {
            ReSubmitted = lang;
        });

        var Rejected;
        this.translate.get('clientUserProfile.Rejected').subscribe(lang => {
            Rejected = lang;
        });

        if (val == 1) {
            return WaitingApproval
        } else if (val == 2) {
            return Approved
        } else if (val == 3) {
            return ReSubmitted
        } else if (val == 4) {
            return Rejected
        }
    }

    showTabActive(disabled, hide, show) {
        $('#' + disabled + '-tab').not('.active').removeClass('disabled');
        $('#' + hide + '-tab').removeClass('active');
        $('#' + show + '-tab').addClass('active');
        $('#' + hide).removeClass('show active');
        $('#' + show).addClass('show active');
    }
}
