import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';

import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})

export class CountryComponent implements OnInit {

    userDetails;
    urlGetCountryDetails = 'get-country-lists';
    urlAddEditCountry = 'add-edit-country';
    urlBlockUnblockCountry = 'block-unblock-country';
    urlGetIndividualCountry = 'get-individual-country-details';
    CountryLists; //for all country lists
    closeResult: string;
    countryData; //for block & unblock
    individualCountryData; //individual country details stored for edit country modal
    title; //Modal Popup header Title
    buttonName; //Modal Popup Button Name
    blockUnblockTitle;

    addEditCountryForm: FormGroup;
    addEditSubmitted = false;

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    searchInputData = '';

    @ViewChild('contentAddNewCountry', {static: false}) private contentAddNewCountry: ElementRef;
    @ViewChild('blockUnblockCountry', {static: false}) private blockUnblockCountry: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) {
        this.AuthorisedLayoutComponent.getRouteAccess();
     }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(mTy) {
        return this.commonService.getMenuPermission('7cc92df5d608193c84f5', mTy);
    }

    ngOnInit() {

        this.getCountryLists();

        this.userDetails = JSON.parse( localStorage.getItem('user_details'));

        this.addEditCountryForm = this.formBuilder.group({
            countryName: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
            isoCode: ['', [Validators.required, Validators.maxLength(3),Validators.pattern('^[a-zA-Z\-\']+')]],
            phoneCode: ['', [Validators.required, Validators.pattern('^[0-9\-\']+')]],
            cK: ['', []],
        });
    }

    open(content, sizeS, modalTitleID){
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    /**
     * Fecting Country Lists
     */

    getCountryLists() {

        this.commonService.showLoader = 1;

        let input = {
            page: this.page,
            _sDl: this.searchInputData,
        };

        this.tradeApiService.ApiServices(this.urlGetCountryDetails, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.CountryLists = responseData.countryLists.Records;
                this.totalItems = responseData.countryLists.TotalCount; 
            }
            else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Load pagination next page
     */

    loadPage(page: number) {   
        this.page = page;
        this.getCountryLists();
    }

    /**
     * Add Country Modal Popup
     */

    addNewCountryModal() {
        this.resetForm();
        this.open(this.contentAddNewCountry, 'x', 'modal-basic-title');
        this.title = 'Add New';
        this.buttonName = 'Add';
    }

    /**
     * Reset add country form values and error messages
     */

    resetForm() {
        this.addEditSubmitted = false;
        this.addEditCountryForm.clearValidators();
        this.addEditCountryForm.reset();
        this.addEditCountryForm.markAsPristine()
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
    }

    /**
     * Angular error message form control
     */

    get f() { return this.addEditCountryForm.controls; } //Add Country Error Controller

    /**
     * Add & Edit Country form Submit
     */

    addEditCountrySubmit() {
        this.addEditSubmitted = true;

        // stop here if form is invalid
        if (this.addEditCountryForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.addEditCountryForm.value);

        this.tradeApiService.ApiServices(this.urlAddEditCountry, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.getCountryLists();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for(var i=0; i<errorValues.length;i++){                                  
                    this.commonService.showToaster( errorValues[i] , 4 ); 
                }  
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Block & Unblock country Modal Popup
     */

    blockUnblock(data) {
        this.countryData = data;
        
        this.blockUnblockTitle = (data.country_status == 0) ? 'Unblock' : 'Block';

        this.open(this.blockUnblockCountry, 'x', 'modal-blockunblock-title');
    }

    confirmBlockUnblock() {
        
        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
          cK: this.countryData.country_key,
          cS: this.countryData.country_status,
        };

        let userInput = Object.assign(input);

        this.tradeApiService.ApiServices(this.urlBlockUnblockCountry, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.countryData = null;
                this.getCountryLists();
            } else {
                this.commonService.showToaster( responseData.message , 4 );
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Get Individual Country Detail
     */

    getIndividualCountry(data) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            cK: data.country_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualCountry, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.open(this.contentAddNewCountry, 'x', 'modal-basic-title');
                this.title = 'Edit';
                this.buttonName = 'Update';

                this.individualCountryData = responseData.cD;

                this.addEditCountryForm.controls['cK'].setValue(data.country_key);
                this.addEditCountryForm.controls['countryName'].setValue(this.individualCountryData.nicename);
                this.addEditCountryForm.controls['isoCode'].setValue(this.individualCountryData.iso);
                this.addEditCountryForm.controls['phoneCode'].setValue(this.individualCountryData.phonecode);
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Clear Search Data's
     */

    clearSearchData() {
        this.searchInputData = "";
        this.getCountryLists();
    }
}
