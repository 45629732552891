import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

@Component({
    selector: 'app-package',
    templateUrl: './package.component.html',
    styleUrls: ['./package.component.scss']
  })

export class PackageComponent implements OnInit {

    userDetails;
    urlGetPackageDetails = 'get-package-lists';
    urlAddEditPackage = 'add-edit-package';
    urlBlockUnblockPackage = 'block-unblock-package';
    urlGetIndividualPackage = 'get-individual-package-details';
    packageLists; //for all package lists
    groupList; //for all group lists
    closeResult: string;
    packageData; //for block & unblock
    individualPackageData; //individual package details stored for edit package modal
    title; //Modal Popup header Title
    buttonName; //Modal Popup Button Name
    blockUnblockTitle;

    addEditPackageForm: FormGroup;
    addEditSubmitted = false;

    selectGroup = true;

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    searchInputData = '';
    searchGK = '';

    @ViewChild('contentAddNewPackage', {static: false}) private contentAddNewPackage: ElementRef;
    @ViewChild('blockUnblockPackage', {static: false}) private blockUnblockPackage: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) {
        this.AuthorisedLayoutComponent.getRouteAccess();
     }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(mTy) {
        return this.commonService.getMenuPermission('4e399a7dfee7f64c436a', mTy);
    }

    ngOnInit() {

        this.getPackageLists();

        this.userDetails = JSON.parse( localStorage.getItem('user_details'));

        this.addEditPackageForm = this.formBuilder.group({
            packageName: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
            gK: ['', [Validators.required]], //Group Key
            pK: ['', []], //Package Key
        });
    }

    open(content, sizeS, modalTitleID) {
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    /**
     * Fecting Package Lists
     */

    getPackageLists() {

        this.commonService.showLoader = 1;

        let input = {
            page: this.page,
            _sDl: this.searchInputData,
            _sGK: this.searchGK,
        };

        this.tradeApiService.ApiServices(this.urlGetPackageDetails, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.packageLists = responseData.packageLists.Records;
                this.totalItems = responseData.packageLists.TotalCount;
                this.groupList = responseData.groupList;
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Load pagination next page
     */

    loadPage(page: number) {   
        this.page = page;
        this.getPackageLists();
    }

    /**
     * Add Package Modal Popup
     */

    addNewPackageModal() {
        this.resetForm();
        this.open(this.contentAddNewPackage, 'x', 'modal-basic-title');
        this.title = 'Add New';
        this.buttonName = 'Add';
        this.selectGroup = true;
        this.addEditPackageForm.controls['gK'].setValue('');
    }

    /**
     * Reset add Package form values and error messages
     */

    resetForm() {
        this.addEditSubmitted = false;
        this.addEditPackageForm.clearValidators();
        this.addEditPackageForm.reset();
        this.addEditPackageForm.markAsPristine()
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
    }

    /**
     * Angular error message form control
     */

    get f() { return this.addEditPackageForm.controls; } //Add Package Error Controller

    /**
     * Add & Edit Package form Submit
     */

    addEditPackageSubmit() {

        this.addEditSubmitted = true;

        // stop here if form is invalid
        if (this.addEditPackageForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.addEditPackageForm.value);

        this.tradeApiService.ApiServices(this.urlAddEditPackage, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.getPackageLists();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for(var i=0; i<errorValues.length;i++){                                  
                    this.commonService.showToaster( errorValues[i] , 4 ); 
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Block & Unblock Package Modal Popup
     */

    blockUnblock(data) {
        this.packageData = data;
        
        this.blockUnblockTitle = (data.package_status == 0) ? 'Unblock' : 'Block';

        this.open(this.blockUnblockPackage, 'x', 'modal-blockunblock-title');
    }

    confirmBlockUnblock() {
        
        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
          gK: this.packageData.group_key,
          pK: this.packageData.package_key,
          pS: this.packageData.package_status,
        };

        let userInput = Object.assign(input);

        this.tradeApiService.ApiServices(this.urlBlockUnblockPackage, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.packageData = null;
                this.getPackageLists();
            } else {
                this.commonService.showToaster( responseData.message , 4 );
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Get Individual Package Detail
     */

    getIndividualPackage(data) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            gK: data.group_key,
            pK: data.package_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualPackage, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.open(this.contentAddNewPackage, 'x', 'modal-basic-title');
                this.title = 'Edit';
                this.buttonName = 'Update';

                this.individualPackageData = responseData.pD;

                this.addEditPackageForm.controls['pK'].setValue(data.package_key);
                this.addEditPackageForm.controls['gK'].setValue(data.group_key);
                this.addEditPackageForm.controls['packageName'].setValue(this.individualPackageData.name);

                this.selectGroup = false;
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Clear Search Data's
     */

    clearSearchData() {
        this.searchInputData = '';
        this.searchGK = '';
        this.getPackageLists();
    }
}
