import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';
import { Router } from '@angular/router';

declare var $: any;

import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-new-account-register',
    templateUrl: './new-account-register.component.html',
    styleUrls: ['./new-account-register.component.scss']
})
export class NewAccountRegisterComponent implements OnInit {

    registerForm: FormGroup;
    submitted = false;

    pwdPattern = '';

    /**
     * Url for getting the country List
     */

    getCountryList = 'c-l';
    signupWithCcheck = 'signup-with-check';

    gCountrydetails: any = [];

    OtpVerifyMobileError = 0;
    mobileNumberOtp = '';
    mobileNumber = '';
    urlLogMobileOtpVerify = 'verify-otp-log-email';
    showMobileOtpPage = 0;
    mobUrl = 'resend-otp-log-email';
    countryErr = 0;
    countryCode = 0;
    terms_url;

    timeLeftToCreateLiveAccount = 60;
    showTimeResendOtp = 0;

    getLanguage = '';

    pSData = 1;

    termsAndConditionsError = 0;

    passwordStrengthSstatus = 0;


    constructor(private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, public commonService: CommonService, private translate: TranslateService, private Router: Router) {
        translate.setDefaultLang(this.commonService.defaultLanguale);
        this.getLanguage = this.commonService.defaultLanguale;
    }

    @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
        e.preventDefault();
    }


    ngOnInit() {
        this.initRegisterForm();
        this.getCounrtyDetailsList();
    }

    initRegisterForm() {

        this.registerForm = this.formBuilder.group({
            name: ['', Validators.required],
            //lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern(/^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
            mobile: ['', [Validators.required, Validators.minLength(5)]],
            //gender: ['', [Validators.required, Validators.minLength(10)]],
            country: ['', []],
            password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.pwdPattern)]],
            termsAndConditions: ['', Validators.required]
        });

    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
        this.submitted = true;
        this.termsAndConditionsError = 0;

        if (this.registerForm.value.termsAndConditions === false) {
            this.termsAndConditionsError = 1;
        }

        this.countryErr = 0;
        if ($('#byjson option:selected').val() == '') {
            this.countryErr = 1;
            return;
        } else {
            this.countryCode = $('#byjson option:selected').data('ccode');
        }

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        if (this.registerForm.value.termsAndConditions === false) {
            this.termsAndConditionsError = 1;
            return;
        }

        let input = {
            country: $('#byjson option:selected').val(),
        };

        let userInput = Object.assign(this.registerForm.value, input);

        this.commonService.showLoader = 1;
        this.tradeApiService.ApiServices(this.signupWithCcheck, userInput, null).subscribe((registerDetails: any) => {
            if (registerDetails.status == 200) {
                this.commonService.showToaster(registerDetails.message, 1);
                this.mobileNumber = this.countryCode + this.registerForm.controls.mobile.value;
                this.showMobileOtpPage = 1;
                // this.commonService.navigateToUrls('/login');                 
            }
            else {
                // const errorValues = Object.keys(registerDetails.message).map(key => registerDetails.message[key]);
                // for (var i = 0; i < errorValues.length; i++) {
                //     this.commonService.showToaster(errorValues[i], 4);
                // }
                if (typeof registerDetails.message !== 'string') {
                    const errorValues = Object.keys(registerDetails.message).map(key => registerDetails.message[key]);
                    for (var i = 0; i < errorValues.length; i++) {
                        this.commonService.showToaster(errorValues[i], 4);
                    }
                }
                else {
                    this.commonService.showToaster(registerDetails.message, 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /***
     *    Getting the Country List  
     *    @getCounrtyDetailsList()
     */
    getCounrtyDetailsList() {

        let userInput = {
            token: 'token to be passed',
        };

        this.commonService.showLoader = 1;

        this.tradeApiService.ApiServices(this.getCountryList, userInput, null).subscribe((CountryList: any) => {
            if (CountryList.status == 200) {
                this.pSData = CountryList.pSData;
                switch (CountryList.pSData) {
                    case 1:
                        this.pwdPattern = this.commonService.HighpwdPattern;
                        break;
                    case 2:
                        this.pwdPattern = this.commonService.MediumpwdPattern;
                        break;
                    case 3:
                        this.pwdPattern = this.commonService.LowpwdPattern;
                        break;
                    default:
                        this.pwdPattern = this.commonService.HighpwdPattern;
                        break;
                }

                this.initRegisterForm();
                this.gCountrydetails = CountryList.CountryInformations;
                if (CountryList.ScL !== '') {
                    this.registerForm.controls['country'].setValue(CountryList.ScL);
                }

                $(document).ready(function () {

                    $('.countries').msDropdown();

                    $('.countries').on('change', function () {
                        var str = $(this).val();
                        var splitted = str.split('-', 1);
                        $('#inputCountryCode').html('+' + splitted[0]);
                    });

                    if (CountryList.ScL !== '') {
                        $('.countries').trigger('change');
                    }

                });
                this.terms_url = (CountryList.terms_page_url) ? CountryList.terms_page_url : '#';

            } else {
                const errorValues = Object.keys(CountryList.message).map(key => CountryList.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    //this.toastr.errorToastr( errorValues[i] , 'Alert!');
                }
            }            
            this.commonService.showLoader = 0;
        });
    }

    OtpVerifyMobile() {

        this.OtpVerifyMobileError = 0;
        if (this.mobileNumberOtp == '') {
            this.OtpVerifyMobileError = 1;
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
            _m: this.mobileNumber,
            uO: this.mobileNumberOtp,
        };
        this.commonService.showLoader = 1;
        this.tradeApiService.ApiServices(this.urlLogMobileOtpVerify, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template
            if (otpDetails.status == 200) {
                this.commonService.showToaster(otpDetails.message, 1);
                this.mobileNumber = '';
                this.showMobileOtpPage = 0;
                this.commonService.navigateToUrls('/login');

            } else {
                this.commonService.showToaster(otpDetails.message, 4);
            }

            this.commonService.showLoader = 0;

        });

    }


    resendCodeLink() {
        // stop here if form is invalid
        if (this.mobileNumber == '') {

            var MobileRequired;
            this.translate.get('register.MobileRequired').subscribe(lang => {
                MobileRequired = lang;
            });
            this.commonService.showToaster(MobileRequired, 2);
            return;
        }

        let input = {
            _m: this.mobileNumber,
        };
        this.showTimeResendOtp = 0;
        this.commonService.showLoader = 1;
        this.tradeApiService.ApiServices(this.mobUrl, input, null).subscribe((loginDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template
            if (loginDetails.status == 200) {
                // console.log(loginDetails)
                this.showMobileOtpPage = 1;
                /*  this.showRensendActLink = 0;
                  this.showRensendActLinkEmail = ''; */
                this.commonService.showToaster(loginDetails.message, 1);
            } else {

                // const errorValues = Object.keys(loginDetails.message).map(key => loginDetails.message[key]);
                // for (var i = 0; i < errorValues.length; i++) {
                //     this.commonService.showToaster(errorValues[i], 4);
                // }

                if (typeof loginDetails.message !== 'string') {
                    const errorValues = Object.keys(loginDetails.message).map(key => loginDetails.message[key]);
                    for (var i = 0; i < errorValues.length; i++) {
                        this.commonService.showToaster(errorValues[i], 4);
                    }
                } else {
                    this.commonService.showToaster(loginDetails.message, 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }


    timeLeftHandleEvent(events) {
        if (events.action == 'done') {
            this.showTimeResendOtp = 1;
        }
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

    changeLanguage(lang = 'en') {
        this.getLanguage = lang;
        this.translate.setDefaultLang(this.getLanguage);
        this.commonService.changeCommonLanguage(this.getLanguage);

        this.Router.navigateByUrl('/login', { skipLocationChange: true }).then(() =>
            this.Router.navigate(['/register']));

    }
    getNameLangCountry(cntNam) {
        this.translate.get('language.' + cntNam).subscribe(lang => {
            return lang;
        });
    }

    getCountryValues() {
        console.log('getCountryValues()');
    }

    checkTermsSle($event) {
        this.termsAndConditionsError = 0;
        if ($event.target.checked === false) {
            this.termsAndConditionsError = 1;
        }
    }

    /**
     * Check Password Strenght Bar View
     */

    checkPasswordStrength() {
        this.passwordStrengthSstatus = 0; 
        var number = /([0-9])/;
        var alphabets = /([a-zA-Z])/;
        var special_characters = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
        if ($('#password').val().length > 0) {
            this.passwordStrengthSstatus = 1; // Week

            if ($('#password').val().length < 6) {
                this.passwordStrengthSstatus = 1; // Week
            } else {
                if ($('#password').val().match(number) && $('#password').val().match(alphabets) && $('#password').val().match(special_characters)) {
                    this.passwordStrengthSstatus = 3; // Strong
                } else {
                    this.passwordStrengthSstatus = 2; // Medium
                }
            }
        }
    }

    viewterms(){
        console.log("Terms");
    }
}
