import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr'; // for notifications
import { Router } from '@angular/router';
import { ApiTradeService } from './api-trade.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    userLoggedName = '';
    userProfileImg = '';
    siteLogoImg = '';
    favIconImg = '';

    uAdCount;
    uTlCount;

    showLoader = 0;

    defaultLanguale = 'en';
    enableLanguale = 1;
    LanguagesDrop = [];

    // aside wrapper toggler
    asideToggle: boolean = false;  
    
    HighpwdPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'; // High The password must consist of at least 8 characters, have one uppercase letter and one small letter, one number and one special character (!, \, /, Etc.). 

    MediumpwdPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{7,}'; // Medium Atleast one Small letter , one Number, one Capsletter

    LowpwdPattern = '(?=.*[a-z])(?=.*[0-9]).{7,}'; // low Atleast one Small letter , one Number

    constructor(public toastr: ToastrService, public router: Router, public tradeApiService: ApiTradeService, private translate: TranslateService) {
        // console.log( this.defaultLanguale );

        var lang = localStorage.getItem('language');
        if (lang != this.defaultLanguale && lang != null) {
            this.defaultLanguale = lang;           
        }  else { 
            this.defaultLanguale = 'en';
        }     

        if (this.checkforAuthInPages() == 1) {
            var userDetails = JSON.parse(localStorage.getItem('user_details'));
            var uAdCount = JSON.parse(localStorage.getItem('uAdCount'));
            var uTlCount = JSON.parse(localStorage.getItem('uTlCount'));
            var permission = JSON.parse(localStorage.getItem('permission'));
            this.userLoggedName = userDetails.user_name;
            this.userProfileImg = userDetails.profile_img;
            this.uAdCount = uAdCount;
            this.uTlCount = uTlCount;
            this.tradeApiService.permissions = permission;
            // console.log('userDetails', userDetails);
            // console.log('permission', permission);
        }
    }

    getMenuPermission(module_key, mTy) {

        var userDetails = JSON.parse(localStorage.getItem('user_details'));

        if (userDetails.user_type == 1) {
            return 1;
        } else {
            var p = 0;
            var skinName = this.tradeApiService.permissions.find(x => x.module_key == module_key);
            if( skinName !== undefined ){
                if (skinName.module_key == module_key) {
                    if (mTy == 1 && skinName.create == 1) { // Create
                        p = 1;
                    }
                    if (mTy == 2 && skinName.edit == 1) { // Edit
                        p = 1;
                    }
                    if (mTy == 3 && skinName.block_unblock == 1) { // Block unblock
                        p = 1;
                    }
                    if (mTy == 4 && skinName.view == 1) { // Detailed View
                        p = 1;
                    }
                    if (mTy == 5 && skinName.update_privilege == 1) { // Set Privilege
                        p = 1;
                    }
                    if (mTy == 6 && skinName.accept == 1) { // Accept
                        p = 1;
                    }
                    if (mTy == 7 && skinName.reject == 1) { // Reject
                        p = 1;
                    }
                }
            }
            
            return p;
        }
    }

    /**
     * Showing the toaster Message
     * this.toastr.success('Hello world!', 'success'); - 1
     * this.toastr.error('Hello world!', 'error'); - 2
     * this.toastr.info('Hello world!', 'info'); - 3
     * this.toastr.warning('Hello world!', 'warning'); - 4
     */
    showToaster(Mes, type) {

        var Success;
        this.translate.get('commonService.Success').subscribe(lang => {
            Success = lang;
        });

        var Errors;
        this.translate.get('commonService.Error').subscribe(lang => {
            Errors = lang;
        });

        var Info;
        this.translate.get('commonService.Info').subscribe(lang => {
            Info = lang;
        });

        var Warning;
        this.translate.get('commonService.Warning').subscribe(lang => {
            Warning = lang;
        });

        switch (type) {
            case 1:
                this.toastr.success(Mes, Success);
                break;
            case 2:
                this.toastr.error(Mes, Errors);
                break;
            case 3:
                this.toastr.info(Mes, Info);
                break;
            case 4:
                this.toastr.warning(Mes, Warning);
                break;
        }
    }

    /**
     * Here navigate to the urls
     * once user logged in / out user
     */

    navigateToUrls(url) {
        this.router.navigateByUrl(url);
    }

    /**
     * Here checking for the auth token for accessing the each urls
     * once user logged in successfully
     */
    checkforAuth() {
        var PleaseLoginContinue;
        this.translate.get('commonService.PleaseLoginContinue').subscribe(lang => {
            PleaseLoginContinue = lang;
        });

        var auth = localStorage.getItem('auth_token');
        if (auth == '' || auth == null) {
            this.showToaster(PleaseLoginContinue, 2);
            this.navigateToUrls('/login');
        }
    }

    /**
     * Here checking for the auth token for accessing the each urls
     * once user logged in successfully
     */
    checkforAuthInPages() {
        var auth = localStorage.getItem('auth_token');
        if (auth == '' || auth == null) {
            return 0;
        }
        return 1;
    }

    /**
     * Remove the local cache / session details
     * and move to the login page
     */

    logout() {
        var auth = localStorage.getItem('auth_token');


        let input = {
            token: auth,
        };
        this.showLoader = 1;

        this.tradeApiService.removeLocalStr();

        this.showLoader = 0;

        var LogOutSuccess;
        this.translate.get('commonService.LogOutSuccess').subscribe(lang => {
            LogOutSuccess = lang;
        });

        this.showToaster(LogOutSuccess, 1);
        this.navigateToUrls('/login');
    }

    changeCommonLanguage(getLanguage) {
        this.defaultLanguale = getLanguage;
        localStorage.setItem('language', getLanguage);
    }

    moveToPageReload() {
        var userDetails = JSON.parse(localStorage.getItem('user_details'));
        switch (userDetails.user_type) {
            case 1:
                this.navigateToUrls('/admin-dashboard');
                break;
            case 2:
                this.navigateToUrls('/client-dashboard');
                break;
            case 3:
                this.navigateToUrls('/dashboard');
                break;
        }

    }

    checkDiv(event = '') {


        // Get all buttons with class="btn" inside the container
        var btns = document.getElementsByClassName("active");

        // Loop through the buttons and add the active class to the current/clicked button
        for (var i = 0; i < btns.length; i++) {

            var current = document.getElementsByClassName("active");

            // If there's no active class
            if (current.length > 0) {
                current[0].className = current[0].className.replace(" active", "");
            }

        }

        // Get all buttons with class="btn" inside the container
        var menu = document.getElementsByClassName("navigation-active");

        // Loop through the buttons and add the active class to the current/clicked button
        for (var i = 0; i < menu.length; i++) {

            var current = document.getElementsByClassName("navigation-active");

            // If there's no active class
            if (current.length > 0) {
                current[0].className = current[0].className.replace("navigation-active", "");
            }

        }

        if (event != '') {
            var element = document.getElementById(event);
            element.classList.add("active");
        }



    }

    asideToogerFunct() {
        this.asideToggle = !this.asideToggle;
    }


}
