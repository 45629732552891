import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class LoginComponent implements OnInit {


    LoginForm: FormGroup;
    submitted = false;
    Url = 'signin-with-check';
    ActUrl = 'activation-email';
    mobUrl = 'resend-otp-log-email';
    urlLogMobileOtpVerify = 'verify-otp-log-email';
    urlForcePwdChange = 'signin-pwd-change-with-check';
    getCountryList = 'c-l';

    showRensendActLink = 0;
    showRensendActLinkEmail = '';

    showMobileOtpPage = 0;
    showPwdForceChange = 0;

    OtpVerifyMobileError = 0;
    mobileNumberOtp = '';

    timeLeftToCreateLiveAccount = 60;
    showTimeResendOtp = 0;

    getLanguage = '';

    pwdPattern = '';

    pSData = 1;
    gCountrydetails: any = [];

    /**
     * Force Password Update
     */
    passwordUpdateForm: FormGroup;
    submittedPassword = false;
    confirm = false;

    showConty = 0;
    countryErr = 0;
    ScL = '';
    terms_url = '';

    passwordStrengthSstatus = 0; 

    constructor(private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, public commonService: CommonService, private translate: TranslateService) {
        translate.setDefaultLang(this.commonService.defaultLanguale);
        this.getLanguage = this.commonService.defaultLanguale;
        this.commonService.showLoader = 0;
    }

    // @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    //     e.preventDefault();
    //   }

    ngOnInit() {

        //Validators.pattern(this.pwdPattern)

        this.LoginForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{5,15}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
            password: ['', [Validators.required, Validators.minLength(8)]],
            country: ['', []],
        });

        this.reintiPasswordForm();
        this.getCounrtyDetailsList();

    }

    reintiPasswordForm() {

        this.passwordUpdateForm = this.formBuilder.group({
            newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.pwdPattern)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.pwdPattern)]],
            email: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{5,15}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
        }, {
                validator: this.MustMatch('newPassword', 'confirmPassword')
            });

    }


    // convenience getter for easy access to form fields
    get f() { return this.LoginForm.controls; }
    get fv() { return this.passwordUpdateForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.LoginForm.invalid) {
            return;
        }

        if ($('#byjson option:selected').val() == '') {
            this.countryErr = 1;
            return;
        }     

        this.commonService.showLoader = 1;

        var email = this.LoginForm.value.email;

        if (this.showConty == 1) {
            // console.log( this.LoginForm.value.email );
           var email = $('#byjson option:selected').val() + this.LoginForm.value.email;
        }      

        let input = {
            email: email,
            password: this.LoginForm.value.password,
        };       
        

        this.tradeApiService.ApiServices(this.Url, input, null).subscribe((loginDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (loginDetails.status == 200) {

                if (loginDetails.fCl == 1) {

                    this.pSData = loginDetails.pSData;
                    switch (loginDetails.pSData) {
                        case 1:
                            this.pwdPattern = this.commonService.HighpwdPattern;
                            break;
                        case 2:
                            this.pwdPattern = this.commonService.MediumpwdPattern;
                            break;
                        case 3:
                            this.pwdPattern = this.commonService.LowpwdPattern;
                            break;
                        default:
                            this.pwdPattern = this.commonService.HighpwdPattern;
                            break;
                    }
                    this.reintiPasswordForm();
                    // console.log(loginDetails);

                    this.showMobileOtpPage = 3;
                    this.showPwdForceChange = 1;
                    // console.log( email );
                    this.passwordUpdateForm.controls['email'].setValue( email );
                } else {
                    // console.log(loginDetails)
                    localStorage.setItem('auth_token', loginDetails.token);
                    localStorage.setItem('user_details', JSON.stringify(loginDetails.uD));
                    localStorage.setItem('uAdCount', JSON.stringify(loginDetails.uAdCount));
                    localStorage.setItem('uTlCount', JSON.stringify(loginDetails.uTlCount));
                    localStorage.setItem('RUpdated', loginDetails.RUpdated);
                    localStorage.setItem('rolKey', loginDetails.uD.user_role);

                    // console.log(loginDetails.uD);

                    this.commonService.userLoggedName = loginDetails.uD.user_name;
                    this.commonService.userProfileImg = loginDetails.uD.profile_img;
                    this.commonService.uAdCount = loginDetails.uAdCount;
                    this.commonService.uTlCount = loginDetails.uTlCount;

                    this.tradeApiService.permissions = loginDetails.privileges;
                    localStorage.setItem('permission', JSON.stringify(loginDetails.privileges));

                    this.commonService.showToaster(loginDetails.message, 1);

                    switch (loginDetails.uD.user_type) {
                        case 1:
                            this.commonService.navigateToUrls('/admin-dashboard');
                            break;
                        case 2:
                            this.commonService.navigateToUrls('/client-dashboard');
                            break;
                        case 3:
                            this.commonService.navigateToUrls('/dashboard');
                            break;
                    }

                }

            }
            else {
                this.showRensendActLinkEmail = email;
                this.showRensendActLink = loginDetails.sl;
                if (typeof loginDetails.message !== 'string') {
                    const errorValues = Object.keys(loginDetails.message).map(key => loginDetails.message[key]);
                    for (var i = 0; i < errorValues.length; i++) {
                        this.commonService.showToaster(errorValues[i], 4);
                    }
                }
                else {
                    this.commonService.showToaster(loginDetails.message, 4);
                }

            }
            this.commonService.showLoader = 0;
        });
    }

    resendActivationLink() {
        // stop here if form is invalid
        if (this.showRensendActLinkEmail == '') {
            this.commonService.showToaster('Email is required', 2);
            return;
        }

        this.showConty = 0;

        let input = {
            email: this.showRensendActLinkEmail,
        };

        this.commonService.showLoader = 1;

        this.tradeApiService.ApiServices(this.ActUrl, input, null).subscribe((loginDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template
            if (loginDetails.status == 200) {
                this.showRensendActLink = 0;
                this.showRensendActLinkEmail = '';              
                this.commonService.showToaster(loginDetails.message, 1);
            } else {
                // const errorValues = Object.keys(loginDetails.message).map(key => loginDetails.message[key]);
                // for (var i = 0; i < errorValues.length; i++) {
                //     this.commonService.showToaster(errorValues[i], 4);
                // }

                if (typeof loginDetails.message !== 'string') {
                    const errorValues = Object.keys(loginDetails.message).map(key => loginDetails.message[key]);
                    for (var i = 0; i < errorValues.length; i++) {
                        this.commonService.showToaster(errorValues[i], 4);
                    }
                } else {
                    this.commonService.showToaster(loginDetails.message, 4);
                }
            }

            this.commonService.showLoader = 0;

        });

    }

    resendCodeLink() {
        // stop here if form is invalid
        if (this.showRensendActLinkEmail == '') {
            this.commonService.showToaster('Mobile is required', 2);
            return;
        }
        this.showTimeResendOtp = 0;
        this.showConty = 0;

        let input = {
            _m: this.showRensendActLinkEmail,
        };
        this.commonService.showLoader = 1;
        this.tradeApiService.ApiServices(this.mobUrl, input, null).subscribe((loginDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template
            if (loginDetails.status == 200) {
                this.showMobileOtpPage = 1;
                /*  this.showRensendActLink = 0;
                  this.showRensendActLinkEmail = ''; */
                this.commonService.showToaster(loginDetails.message, 1);
            } else {

                // const errorValues = Object.keys(loginDetails.message).map(key => loginDetails.message[key]);
                // for (var i = 0; i < errorValues.length; i++) {
                //     this.commonService.showToaster(errorValues[i], 4);
                // }

                if (typeof loginDetails.message !== 'string') {
                    const errorValues = Object.keys(loginDetails.message).map(key => loginDetails.message[key]);
                    for (var i = 0; i < errorValues.length; i++) {
                        this.commonService.showToaster(errorValues[i], 4);
                    }
                } else {
                    this.commonService.showToaster(loginDetails.message, 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    OtpVerifyMobile() {
        this.OtpVerifyMobileError = 0;
        if (this.mobileNumberOtp == '') {
            this.OtpVerifyMobileError = 1;
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
            _m: this.showRensendActLinkEmail,
            uO: this.mobileNumberOtp,
        };

        this.tradeApiService.ApiServices(this.urlLogMobileOtpVerify, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template
            if (otpDetails.status == 200) {

                this.commonService.showToaster(otpDetails.message, 1);
                this.showMobileOtpPage = 0;
                this.showRensendActLink = 0;
                this.showRensendActLinkEmail = '';
                this.clearForm();

            } else {
                this.OtpVerifyMobileError = 1;
                this.commonService.showToaster(otpDetails.message, 4);
            }

            this.commonService.showLoader = 0;

        });

    }

    clearForm() {

        this.submitted = false;
        this.LoginForm.clearValidators();
        this.LoginForm.reset();
        this.LoginForm.markAsPristine();
        this.showTimeResendOtp = 0;

        this.submittedPassword = false;
        this.passwordUpdateForm.clearValidators();
        this.passwordUpdateForm.reset();
        this.passwordUpdateForm.markAsPristine();
        this.showConty = 0;

    }



    timeLeftHandleEvent(events) {
        if (events.action == 'done') {
            this.showTimeResendOtp = 1;
        }
    }

    changeLanguage(lang = 'en') {
        this.getLanguage = lang;
        this.translate.setDefaultLang(this.getLanguage);
        this.commonService.changeCommonLanguage(this.getLanguage);
    }

    // Password compare

    MustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }

            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                this.confirm = true;
                matchingControl.setErrors({ mustMatch: true });
            } else {
                this.confirm = false;
                matchingControl.setErrors(null);
            }
        }
    }



    updatePassword() {

        this.submittedPassword = true;

        // stop here if form is invalid
        if (this.passwordUpdateForm.invalid) {
            return;
        }

        let input = {
            uF: '',
        };

        this.commonService.showLoader = 1;

        let userInput = Object.assign(input, this.passwordUpdateForm.value);

        // console.log( userInput );

        this.tradeApiService.ApiServices(this.urlForcePwdChange, userInput, null).subscribe((changePassword: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (changePassword.status == 200) {
                this.commonService.showToaster(changePassword.message, 1);

                this.showMobileOtpPage = 0;
                this.showPwdForceChange = 0;

                this.clearForm();
            }
            else {
                const errorValues = Object.keys(changePassword.message).map(key => changePassword.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }

            this.commonService.showLoader = 0;

        });


    }

    checkTextEnterValue(searchValue) {
        if (Number(searchValue)) {
            this.showConty = 1;

            if (this.ScL !== '') {                
                var str = this.ScL;
                var splitted = str.split('-', 1);
                this.LoginForm.controls['country'].setValue(splitted[0]);
            }

            $(document).ready(function () {
                $('.countries').msDropdown();
            });
        }
        else {
            // console.log('// Your code if not number');
            this.showConty = 0;

        }
    }

    /***
*    Getting the Country List  
*    @getCounrtyDetailsList()
*/
    getCounrtyDetailsList() {

        let userInput = {
            token: 'token to be passed',
        };

        this.commonService.showLoader = 1;

        this.tradeApiService.ApiServices(this.getCountryList, userInput, null).subscribe((CountryList: any) => {
            if (CountryList.status == 200) {

                this.gCountrydetails = CountryList.CountryInformations;
                if (CountryList.ScL !== '') {
                    this.ScL = CountryList.ScL;
                    var str = CountryList.ScL;
                    var splitted = str.split('-', 1);
                    this.LoginForm.controls['country'].setValue(splitted[0]);
                }

                $(document).ready(function () {
                    $('.countries').msDropdown();
                    if (CountryList.ScL !== '') {
                        $('.countries').trigger('change');
                    }
                });

            } else {
                const errorValues = Object.keys(CountryList.message).map(key => CountryList.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    //this.toastr.errorToastr( errorValues[i] , 'Alert!');
                }
            }
            this.terms_url = (CountryList.terms_page_url) ? CountryList.terms_page_url : '#';
            this.commonService.showLoader = 0;
        });
    }

     /**
     * Check Password Strenght Bar View
     */

    checkPasswordStrength() {
        this.passwordStrengthSstatus = 0; 
        var number = /([0-9])/;
        var alphabets = /([a-zA-Z])/;
        var special_characters = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
        if ($('#confirmPassword').val().length > 0) {
            this.passwordStrengthSstatus = 1; // Week

            if ($('#confirmPassword').val().length < 6) {
                this.passwordStrengthSstatus = 1; // Week
            } else {
                if ($('#confirmPassword').val().match(number) && $('#confirmPassword').val().match(alphabets) && $('#confirmPassword').val().match(special_characters)) {
                    this.passwordStrengthSstatus = 3; // Strong
                } else {
                    this.passwordStrengthSstatus = 2; // Medium
                }
            }
        }
    }

}
