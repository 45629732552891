import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

@Component({
  selector: 'app-mt5-accounts',
  templateUrl: './mt5-accounts.component.html',
  styleUrls: ['./mt5-accounts.component.scss']
})

export class Mt5AccountsComponent implements OnInit {

    userDetails;
    urlGetMt5AccountDetails = 'get-mt5-account-lists';
    urlAddEditMt5Account = 'add-edit-mt5-account';
    urlBlockUnblockMt5Account = 'block-unblock-mt5-account';
    urlGetIndividualMt5Account = 'get-individual-mt5-account-details';
    mt5AccountLists; //for all city lists
    closeResult: string;
    individualMt5AccountData; //individual city details stored for edit city modal
    title; //Modal Popup header Title
    buttonName; //Modal Popup Button Name
    blockUnblockTitle;

    addEditMt5AccountForm: FormGroup;
    addEditSubmitted = false;

    page = 1;
    totalItems = 0;
    itemsPerPage = 20;

    searchInputData = '';
    searchAccountType = '';
    searchStatus = '';
    searchDate;

    @ViewChild('contentAddNewMt5Account', {static: false}) private contentAddNewMt5Account: ElementRef;
    @ViewChild('blockUnblockMt5Account', {static: false}) private blockUnblockMt5Account: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) {
        this.AuthorisedLayoutComponent.getRouteAccess();
     }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(mTy) {
        // return this.commonService.getMenuPermission('0785b308754a6a7d8be8', mTy);
        return this.commonService.getMenuPermission('bef2c546716297a60199', mTy);
    }
    
    ngOnInit() {

        this.getMt5AccountLists();

        this.userDetails = JSON.parse( localStorage.getItem('user_details'));

        this.addEditMt5AccountForm = this.formBuilder.group({
            mt5_username: ['', [Validators.required]], //User Name
            mt5_password: ['', [Validators.required]], //Password
            mt5_user_key: ['', [Validators.required]], //User Key
            mt5_token: ['', [Validators.required]], //Token
            mt5_api_url: ['', [Validators.required]], //Api URL
            mt5_account_type: ['', [Validators.required]], //Account Type
            mt5_status: ['', [Validators.required]], //Status
            mt5K: ['', []], //MT5 Key
        });
    }

    open(content, sizeS, modalTitleID){
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    dateFormates( dateObject ) {
        let d = new Date(dateObject);
        let day:any = dateObject.day;
        let month:any = dateObject.month;
        let year = dateObject.year;
        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        let date = year + "-" + month + "-" + day;
        return date;
    }

    /**
     * Fecting Mt5 Account Lists
     */

    getMt5AccountLists() {

        this.commonService.showLoader = 1;

        var CreationDate;
        if( this.searchDate !== undefined && this.searchDate !== null ) {
            CreationDate = this.dateFormates( this.searchDate );
        }

        let input = {
            page: this.page,
            _sAT: this.searchAccountType,
            _sAS: this.searchStatus,
            _sDat: CreationDate
        };

        this.tradeApiService.ApiServices(this.urlGetMt5AccountDetails, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.mt5AccountLists = responseData.mt5AccountLists.Records;
                this.totalItems = responseData.mt5AccountLists.TotalCount;
            }
            else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Load pagination next page
     */

    loadPage(page: number) {   
        this.page = page;
        this.getMt5AccountLists();
    }

    /**
     * Add Mt5 Account Modal Popup
     */

    addNewMt5AccountModal() {
        this.resetForm();
        this.open(this.contentAddNewMt5Account, 'x', 'modal-basic-title');
        this.title = 'Add New';
        this.buttonName = 'Add';
        this.addEditMt5AccountForm.controls['mt5_account_type'].setValue('');
        this.addEditMt5AccountForm.controls['mt5_status'].setValue('');
    }

    /**
     * Reset add Mt5 Account form values and error messages
     */

    resetForm() {
        this.addEditSubmitted = false;
        this.addEditMt5AccountForm.clearValidators();
        this.addEditMt5AccountForm.reset();
        this.addEditMt5AccountForm.markAsPristine()
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
    }

    /**
     * Angular error message form control
     */

    get f() { return this.addEditMt5AccountForm.controls; } //Add Mt5 Account Error Controller

    /**
     * Add & Edit Mt5 Account form Submit
     */

    addEditMt5AccountSubmit() {
        this.addEditSubmitted = true;

        // stop here if form is invalid
        if (this.addEditMt5AccountForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.addEditMt5AccountForm.value);

        this.tradeApiService.ApiServices(this.urlAddEditMt5Account, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.getMt5AccountLists();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for(var i=0; i<errorValues.length;i++){                                  
                    this.commonService.showToaster( errorValues[i] , 4 ); 
                }  
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Get Individual Mt5 Account Detail
     */

    getIndividualMt5Account(data) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            mt5K: data.mt5_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualMt5Account, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.open(this.contentAddNewMt5Account, 'x', 'modal-basic-title');
                this.title = 'Edit';
                this.buttonName = 'Update';

                this.individualMt5AccountData = responseData.mt5D;

                this.addEditMt5AccountForm.controls['mt5K'].setValue(data.mt5_key);
                this.addEditMt5AccountForm.controls['mt5_username'].setValue(this.individualMt5AccountData.mt5_username);
                this.addEditMt5AccountForm.controls['mt5_password'].setValue(this.individualMt5AccountData.mt5_password);
                this.addEditMt5AccountForm.controls['mt5_user_key'].setValue(this.individualMt5AccountData.mt5_user_key);
                this.addEditMt5AccountForm.controls['mt5_token'].setValue(this.individualMt5AccountData.mt5_token);
                this.addEditMt5AccountForm.controls['mt5_api_url'].setValue(this.individualMt5AccountData.mt5_api_url);
                this.addEditMt5AccountForm.controls['mt5_account_type'].setValue(this.individualMt5AccountData.is_demo_live);
                this.addEditMt5AccountForm.controls['mt5_status'].setValue(this.individualMt5AccountData.is_active);

            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Clear Search Data's
     */

    clearSearchData() {
        this.searchAccountType = '';
        this.searchStatus = '';
        this.searchDate = undefined;
        this.getMt5AccountLists();
    }
    confirmBlockUnblock(){
        
    }
}

