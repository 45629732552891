import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild
} from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons
} from '@ng-bootstrap/ng-bootstrap';
import {
  ToastrService
} from 'ngx-toastr'; // for notifications
import {
  Lightbox
} from 'ngx-lightbox'; // for image viewer
import {
  HttpClient
} from '@angular/common/http';
import {
  NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import {
  NgbDate,
  NgbCalendar,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  Validators,ValidatorFn 
} from '@angular/forms';
import Swal from 'sweetalert2';
import {
  ApiTradeService
} from '../../api-trade.service';
import {
  CommonService
} from '../../common.service';
import {
  OwlOptions
} from 'ngx-owl-carousel-o';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  AuthorisedLayoutComponent
} from '../../layout/authorised/authorised-layout/authorised-layout.component';
declare var $: any;
@Component({
  selector: 'app-client-user-profile',
  templateUrl: './client-user-profile.component.html',
  styleUrls: ['./client-user-profile.component.scss']
})
export class ClientUserProfileComponent implements OnInit {
  fileData: File = null;
  closeResult: string;
  imagePathtmp: string;
  imagePathProfile: string;
  modalReference;
  kycButton = false;
  isEnabled = false;
  documentVisible = true;

  maxDate; // setting dob year range

  // for document image upload

  clientDocument_1: string;
  clientDocument_2: string;
  clientDocument_3: string;
  clientDocument_4: string;
  clientDocument_5: string;
  clientDocument_6: string;
  clientDocument_7: string;
  clientDocument_8: string;
  // end document Image upload

  albums: any = []; // for image preview

  registerForm: FormGroup;
  addressForm: FormGroup;
  changeEmailForm: FormGroup;
  sourceForm: FormGroup;
  questionForm: FormGroup;

  submitted = false;
  addressInfo = false;
  sourceInfo = false;
  submittedChangeEmail = false;
  documentRequired = 1;
  questionInfo = false;
  genderStatus = true;
  //    primary_doc_uploaded = 1;

  confirm = false;
  pwdPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}';
  numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
  passwordUpdateForm: FormGroup;
  submittedPassword = false;


  disabled = 1;

  newEmaildisabled = 0;
  newEmailShowOtp = 0;
  countryList = [];

  // Customer
  urlProfile = 'customer-profile-update';
  urlAddressUpdate = 'customer-address-update';
  urlSourceUpdate = 'customer-source-update';
  urlQuestionUpdate = 'customer-question-update';
  urlDocDelete = 'customer-document-delete';
  urlProfileEmailMobileVerify = 'customer-mobile-email-opt';
  urlProfileEmailMobileOtpVerify = 'customer-mobile-email-opt-verify';
  urlProfileDetails = 'customer-profile-details';
  urlProfileImg = 'customer-profile-img';
  urlDocumentsImg = 'customer-documents';
  urlDocuments = 'verify-documents';
  urlProfileChangePassword = 'customer-profile-password-reset';
  urlProfileToKyc = 'submit-to-kyc';

  employment = 0;
  urlProfileEmailChange = 'customer-email-update-opt';

  employmentList: any = [];
  industryList: any = [];
  genderList: any = [];
  questionList: any = [];

  userDetails;
  // is_email_verified = 0;
  isMobileVerified = 0;

  ShowTxtMob = 1;
  ShowEnterOtpMob = 0;
  mobileNumberOtp = '';
  OtpVerifyMobileError = 0;

  userDocumentDetails;
  userDocuments;
  userAddressDetails;
  changeEmailNumberOtp = '';
  changeEmailNumberOtpError = 0;
  documentForm;
  userSourceDetails;

  identityImage: any = [];
  profileImage: any = [];
  allTheDocs: any;
  dob: any = [];
  selectedRecord;
  statusReason = false;
  employList: any = {};
  nextSuccess = false;
  nextError = false;
  questionDiv = true;

  selectedGender;
  otpShowLabChange = 0;
  questionSame = false;
  // private title: string = 'Browser Push Notifications!';

  @ViewChild('contentDemoAccountsDetails', {
    static: false
  }) private contentDemoAccountsDetails: ElementRef;

  constructor(private modalService: NgbModal, private toastr: ToastrService, private _lightbox: Lightbox, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private http: HttpClient, public translate: TranslateService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) { // 
    this.AuthorisedLayoutComponent.getRouteAccess();
    translate.setDefaultLang(this.commonService.defaultLanguale);
    this.maxDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };

    this.passwordUpdateForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.pwdPattern)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.pwdPattern)]],
      oldPassword: ['', [Validators.required, Validators.minLength(8)]],
    }, {
      validator: this.MustMatch('newPassword', 'confirmPassword')
    });
  }

  ngOnInit() {
    $(document).ready(() => {
      const colors = {
        primary: $('.colors .bg-primary').css('background-color'),
        primaryLight: $('.colors .bg-primary-bright').css('background-color'),
        secondary: $('.colors .bg-secondary').css('background-color'),
        secondaryLight: $('.colors .bg-secondary-bright').css('background-color'),
        info: $('.colors .bg-info').css('background-color'),
        infoLight: $('.colors .bg-info-bright').css('background-color'),
        success: $('.colors .bg-success').css('background-color'),
        successLight: $('.colors .bg-success-bright').css('background-color'),
        danger: $('.colors .bg-danger').css('background-color'),
        dangerLight: $('.colors .bg-danger-bright').css('background-color'),
        warning: $('.colors .bg-warning').css('background-color'),
        warningLight: $('.colors .bg-warning-bright').css('background-color'),
      };
      if ($('#circle-1').length) {
        $('#circle-1').circleProgress({
          startAngle: 1.55,
          value: 0.65,
          size: 110,
          thickness: 8,
          fill: {
            color: '#09bb87'
          }
        });
      }
      this.questionSame = false;
      $('.delete_button').css('display', 'none');

    });
    this.userDetails = JSON.parse(localStorage.getItem('user_details'));
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      dob: [null, Validators.required],
      birthPlace: ['', Validators.required],
      middleName: [''],
    });

    this.addressForm = this.formBuilder.group({
      addressLine: ['', Validators.required],
      addressLine2: [''],
      postalCode: [''],
      nationality: ['', Validators.required],
      country: ['', Validators.required],
      city: ['', Validators.required],
    });

    this.sourceForm = this.formBuilder.group({
      employment: ['', Validators.required],
      annual_investment: ['', Validators.required],
      companyName: [''],
      industry: [''],
      position: [''],
      annual_income: ['']
    });

    this.questionForm = this.formBuilder.group({
        questionOne : ['', Validators.required],
        questionTwo : ['', Validators.required],
        answerOne : ['', Validators.required],
        answerTwo : ['', Validators.required]
    });

    this.getProfileDetails();

    this.changeEmailForm = this.formBuilder.group({
      newEmail: ['', [Validators.required, Validators.email]],
    });

    this.documentForm = this.formBuilder.group({
      clientDocument_2: [''],
    });
  }


  getGender(val) {

    let Male;
    this.translate.get('clientUserProfile.Male').subscribe(lang => {
      Male = lang;
    });

    let Female;
    this.translate.get('clientUserProfile.Female').subscribe(lang => {
      Female = lang;
    });

    let NotInterstToSay;
    this.translate.get('clientUserProfile.Not_Interst_To_Say').subscribe(lang => {
      NotInterstToSay = lang;
    });

    if (val == 1) {
      return Male;
    }
    if (val == 2) {
      return Female;
    }
    if (val == 3) {
      return NotInterstToSay;
    }
  }

  genderListArray() {

    let Male;
    this.translate.get('clientUserProfile.Male').subscribe(lang => {
      Male = lang;
    });

    let Female;
    this.translate.get('clientUserProfile.Female').subscribe(lang => {
      Female = lang;
    });

    let NotInterstToSay;
    this.translate.get('clientUserProfile.Not_Interst_To_Say').subscribe(lang => {
      NotInterstToSay = lang;
    });

    this.genderList = [{
      id: '1',
      name: Male
    }, {
      id: '2',
      name: Female
    }];
  }

//   questionListArray() {
//     let Male;
//     this.translate.get('clientUserProfile.Male').subscribe(lang => {
//       Male = lang;
//     });

//     let Female;
//     this.translate.get('clientUserProfile.Female').subscribe(lang => {
//       Female = lang;
//     });

//     let NotInterstToSay;
//     this.translate.get('clientUserProfile.Not_Interst_To_Say').subscribe(lang => {
//       NotInterstToSay = lang;
//     });    
//   }


  employmentListArray() {

    let Salaried;
    this.translate.get('clientUserProfile.Salaried').subscribe(lang => {
      Salaried = lang;
    });

    let SelfEmployement;
    this.translate.get('clientUserProfile.SelfEmployement').subscribe(lang => {
      SelfEmployement = lang;
    });

    let Business;
    this.translate.get('clientUserProfile.Business').subscribe(lang => {
      Business = lang;
    });

    let UnEmployement;
    this.translate.get('clientUserProfile.UnEmployement').subscribe(lang => {
      UnEmployement = lang;
    });

    this.employmentList = [{
      id: 1,
      name: Salaried
    }, {
      id: 2,
      name: SelfEmployement
    }, {
      id: 3,
      name: Business
    }, {
      id: 4,
      name: UnEmployement
    }];
  }

  industryListListArray() {

    let Computer;
    this.translate.get('clientUserProfile.Computer').subscribe(lang => {
      Computer = lang;
    });
    let TeleCommunication;
    this.translate.get('clientUserProfile.TeleCommunication').subscribe(lang => {
      TeleCommunication = lang;
    });
    let Agriculture;
    this.translate.get('clientUserProfile.Agriculture').subscribe(lang => {
      Agriculture = lang;
    });
    let Construction;
    this.translate.get('clientUserProfile.Construction').subscribe(lang => {
      Construction = lang;
    });
    let Pharamedical;
    this.translate.get('clientUserProfile.Pharamedical').subscribe(lang => {
      Pharamedical = lang;
    });
    let Education;
    this.translate.get('clientUserProfile.Education').subscribe(lang => {
      Education = lang;
    });
    let Transport;
    this.translate.get('clientUserProfile.Transport').subscribe(lang => {
      Transport = lang;
    });
    let Aerospace;
    this.translate.get('clientUserProfile.Aerospace').subscribe(lang => {
      Aerospace = lang;
    });
    let Food;
    this.translate.get('clientUserProfile.Food').subscribe(lang => {
      Food = lang;
    });

    this.industryList = [{
      id: 1,
      name: Computer
    }, {
      id: 2,
      name: TeleCommunication
    }, {
      id: 3,
      name: Agriculture
    }, {
      id: 4,
      name: Construction
    }, {
      id: 5,
      name: Pharamedical
    }, {
      id: 6,
      name: Education
    }, {
      id: 7,
      name: Transport
    }, {
      id: 8,
      name: Aerospace
    }, {
      id: 9,
      name: Food
    }];
  }

  questionListArray() {

    let QuestionOne;
    this.translate.get('clientUserProfile.QuestionOne').subscribe(lang => {
        QuestionOne = lang;
    });

    let QuestionTwo;
    this.translate.get('clientUserProfile.QuestionTwo').subscribe(lang => {
        QuestionTwo = lang;
    });

    let QuestionThree;
    this.translate.get('clientUserProfile.QuestionThree').subscribe(lang => {
        QuestionThree = lang;
    });

    let QuestionFour;
    this.translate.get('clientUserProfile.QuestionFour').subscribe(lang => {
        QuestionFour = lang;
    });

    let QuestionFive;
    this.translate.get('clientUserProfile.QuestionFive').subscribe(lang => {
        QuestionFive = lang;
    });

    let QuestionSix;
    this.translate.get('clientUserProfile.QuestionSix').subscribe(lang => {
        QuestionSix = lang;
    });

    let QuestionSeven;
    this.translate.get('clientUserProfile.QuestionSeven').subscribe(lang => {
        QuestionSeven = lang;
    });

    let QuestionEight;
    this.translate.get('clientUserProfile.QuestionEight').subscribe(lang => {
        QuestionEight = lang;
    });
    

    this.questionList = [{
        id: 1,
        name: QuestionOne
    }, {
        id: 2,
        name: QuestionTwo
    }, {
        id: 3,
        name: QuestionThree
    }, {
        id: 4,
        name: QuestionFour
    }, {
        id: 5,
        name: QuestionFive
    }, {
        id: 6,
        name: QuestionSix
    }, {
        id: 7,
        name: QuestionSeven
    }, {
        id: 8,
        name: QuestionEight
    }];
  }


  /**
   * Assigning the form values
   */
  // formValueSet(){
  //  this.selectedEmployment = this.userSourceDetails.employment
  // }

deleteDoc(docNumber) {

    let Delete;
    this.translate.get('clientUserProfile.Delete').subscribe(lang => {
      Delete = lang;
    });

    let ValidReasonRequired;
    this.translate.get('clientUserProfile.ValidReasonRequired').subscribe(lang => {
      ValidReasonRequired = lang;
    });

    let Warning;
    this.translate.get('clientUserProfile.Warning').subscribe(lang => {
      Warning = lang;
    });

    let YouCouldNotAbleRecoverOnceYouDeleteIt;
    this.translate.get('clientUserProfile.YouCouldNotAbleRecoverOnceYouDeleteIt').subscribe(lang => {
      YouCouldNotAbleRecoverOnceYouDeleteIt = lang;
    });

    let AreYouSureWantToDeleteThisPicture;
    this.translate.get('clientUserProfile.AreYouSureWantToDeleteThisPicture').subscribe(lang => {
      AreYouSureWantToDeleteThisPicture = lang;
    });

    Swal.mixin({
      confirmButtonText: Delete + ' &rarr;',
      showCancelButton: true,
      inputValidator: (result) => {
        return !result && ValidReasonRequired
      }
      // progressSteps: ['1']
    }).queue([{
      title: '<h5> <b>' + Warning + ' : </b> ' + YouCouldNotAbleRecoverOnceYouDeleteIt + ' \n ' + AreYouSureWantToDeleteThisPicture + '</h5>'
    }]).then((result) => {
      if (result.value) {
        this.confirmDelete(docNumber);
      }
    })
  }

confirmDelete(docNumber) {
    this.nextSuccess = false;
    const params = {
      user_key: this.userDetails.user_key,
      doc_number: docNumber
    };

    // Remove image from the Event
    this.identityImage = this.identityImage.filter(x => x.dL !== docNumber);
    this.profileImage = this.profileImage.filter(x => x.dL !== docNumber);
    this.commonService.showLoader = 1;
    this.tradeApiService.ApiServices(this.urlDocDelete, params, null).subscribe((requestDetails: any) => {
      if (requestDetails.status == 200) {
        this.documentValueSet()
        this.commonService.showToaster(requestDetails.message, 1);

        $('#' + requestDetails.docNumber).parent().parent().find('img').attr('src', 'assets/media/image/image-not-found.png');
        $('#' + requestDetails.docNumber).css('display', 'none');
        $('#' + 'Preview_' + requestDetails.docNumber).css('display', 'none');
        // $('#'+'Upload_'+requestDetails.docNumber).css('display','none');              
        if (requestDetails.docNumber == 'clientDocument_1' || requestDetails.docNumber == 'clientDocument_3') {
          $('#source-tab').not('.active').addClass('disabled');
          $('#preview-tab').not('.active').addClass('disabled');
          this.isEnabled = false;
        }
        this.getProfileDetails();
      } else {
        this.commonService.showToaster(requestDetails.message, 4);
      }
      this.commonService.showLoader = 0;
    });
  }


open(content) {
    this.modalReference = this.modalService.open(content);
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title'
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

onSelect(imageEvents) {
    this.imagePathtmp = imageEvents;
  }
onReset() {
    console.log('onReset');
  }

  // scroll to div
scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

closeModal() {
    this.modalService.dismissAll();
  }

  // image validaions

readURL(event: any, clientDocument: any, img_type) { // console.log('clientDocument');

    let FileExceedsTheMaximumSize;
    this.translate.get('clientUserProfile.FileExceedsTheMaximumSize').subscribe(lang => {
      FileExceedsTheMaximumSize = lang;
    });

    let UploadOnlyFormat;
    this.translate.get('clientUserProfile.UploadOnlyFormat').subscribe(lang => {
      UploadOnlyFormat = lang;
    });

    let info;
    this.translate.get('clientUserProfile.info').subscribe(lang => {
      info = lang;
    });

    if (event.target.files && event.target.files[0]) {
      const size = event.target.files[0].size;
      const type = event.target.files[0].type;

      if (Math.round(size / 1024) > 2000) {
        this.toastr.info(FileExceedsTheMaximumSize, info);
        return false;
      }

      if (type == 'image/jpeg' || type == 'image/png') {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.documentImageMapping(clientDocument, event.target.result);
          /**
           *  Uploading the documents
           */
          if (img_type == 1 || img_type == 2) {
            const i1 = {
              uK: this.userDetails.user_key,
              u_Di: event.target.result,
              dL: clientDocument,
              type: img_type
            };
            // console.log(this.identityImage);
            this.identityImage.push(i1);

          } else if (img_type == 3 || img_type == 4 || img_type == 5 || img_type == 6 || img_type == 7 || img_type == 8) {
            const i2 = {
              uK: this.userDetails.user_key,
              u_Di: event.target.result,
              dL: clientDocument,
              type: img_type
            };
            this.profileImage.push(i2);
          }
          this.uploadIdentity();
        }
        reader.readAsDataURL(event.target.files[0]);
      } else {
        this.toastr.info(UploadOnlyFormat, info);
        return false;
      }

    }
  }


  /* Documents Tab -  Upload Documents  */

uploadIdentity() {
    /*
     *   Begin Validate Primary Photo and Document
     *   Variable documentRequired is checking already uploaded or not 
     *   Then checking currently selected or not
     */

    // if(this.identityImage.length > 0 || this.profileImage.length > 0) {      
    // if (Object.values(this.identityImage).indexOf('clientDocument_1') != -1 || Object.values(this.profileImage).indexOf('clientDocument_3') != -1) {
    //   this.commonService.showToaster('Primary Photo & Document Should Not Empty', 4);
    // }

    this.commonService.showLoader = 1;
    this.allTheDocs = {
      identity: this.identityImage,
      profile: this.profileImage
    };
    this.tradeApiService.ApiServices(this.urlDocuments, this.allTheDocs, null).subscribe((documentImg: any) => {
      if (documentImg.status == 200) {
        this.getProfileDetails();
        this.commonService.showToaster(documentImg.message, 1);
      } else {
        this.commonService.showToaster(documentImg.message, 1);
      }
      this.commonService.showLoader = 0;
    });
    // } else {
    //   this.commonService.showToaster('Please Choose File and then Submit' , 4); 
    // }
  }

  // open document image 
Sopen(index: number): void {
    // open lightbox    
    this._lightbox.open(this.albums, index);
  }

SopenView(val) {

    var skinName = this.albums.findIndex(x => x.src == val);

    this._lightbox.open(this.albums, skinName);

  }

close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  // End document image 

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }
  get address() {
    return this.addressForm.controls;
  }
  get sources() {
    return this.sourceForm.controls;
  }
  get fv() {
    return this.passwordUpdateForm.controls;
  }
  get fmail() {
    return this.changeEmailForm.controls;
  }
  get questions() {
      return this.questionForm.controls;
  }

onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.commonService.showLoader = 1;

    const input = {
      date_of_birth: this.registerForm.value.dob.year + '-' + this.registerForm.value.dob.month + '-' + this.registerForm.value.dob.day,
      uK: this.userDetails.user_key,
    };

    const userInput = Object.assign(input, this.registerForm.value);
    this.tradeApiService.ApiServices(this.urlProfile, userInput, null).subscribe((loginDetails: any) => {
      if (loginDetails.status == 200) {
        this.commonService.showToaster(loginDetails.message, 1);
        localStorage.setItem('user_details', JSON.stringify(loginDetails.pU));
        this.userDetails = loginDetails.pU;
        this.commonService.userLoggedName = this.userDetails.user_name + ' ' + this.userDetails.last_user_name;
        this.showTabActive('address', 'timeline', 'address');
        this.getProfileDetails();
      } else {
        this.commonService.showToaster(loginDetails.message, 4);
      }
      this.commonService.showLoader = 0;
    });
  }

onAddress() {
    this.addressInfo = true;
    // stop here if form is invalid

    if (this.addressForm.invalid) {
      return;
    }
    this.commonService.showLoader = 1;

    let input = {
      uK: this.userDetails.user_key,
    };

    let userInput = Object.assign(input, this.addressForm.value);
    this.tradeApiService.ApiServices(this.urlAddressUpdate, userInput, null).subscribe((loginDetails: any) => {
      if (loginDetails.status == 200) {
        this.commonService.showToaster(loginDetails.message, 1);
        $('#photos-tab').not('.active').removeClass('disabled');
        this.showTabActive('photos', 'address', 'photos');
        this.getProfileDetails();
        // localStorage.setItem('user_details',  JSON.stringify(loginDetails.pU));
        // this.userDetails = loginDetails.pU;
        //this.commonService.userLoggedName = this.userDetails.user_name+' '+this.userDetails.last_user_name;              
      } else {
        this.commonService.showToaster(loginDetails.message, 4);
      }
      this.commonService.showLoader = 0;
    });
  }

onSource() {
    this.sourceInfo = true;
    // stop here if form is invalid

    if (this.sourceForm.invalid) {
      return;
    }
    this.commonService.showLoader = 1;

    const input = {
      uK: this.userDetails.user_key,
    };

    const userInput = Object.assign(input, this.sourceForm.value);
    this.tradeApiService.ApiServices(this.urlSourceUpdate, userInput, null).subscribe((loginDetails: any) => {
      if (loginDetails.status == 200) {
        this.commonService.showToaster(loginDetails.message, 1);
        $('#source-tab').not('.active').removeClass('disabled');
        this.showTabActive('preview', 'source', 'preview');
        this.kycButton = true;
        this.getProfileDetails();
        // localStorage.setItem('user_details',  JSON.stringify(loginDetails.pU));
        // this.userDetails = loginDetails.pU;
        // this.commonService.userLoggedName = this.userDetails.user_name+' '+this.userDetails.last_user_name;              
      } else {
        this.commonService.showToaster(loginDetails.message, 4);
      }
      this.commonService.showLoader = 0;
    });

  }

onQuestion(){
    this.questionInfo = true;
    if (this.questionForm.invalid) {
      return;
    }
    const input = {
      uK: this.userDetails.user_key,
    };

    this.commonService.showLoader = 1;
    const userInput = Object.assign(input, this.questionForm.value);    
    this.tradeApiService.ApiServices(this.urlQuestionUpdate, userInput, null).subscribe((loginDetails: any) => {
      if (loginDetails.status == 200) {
        this.commonService.showToaster(loginDetails.message, 1);
        this.getProfileDetails();
      } else {
        this.commonService.showToaster(loginDetails.message, 4);
      }
      this.commonService.showLoader = 0;
    });
    }
  // password compare

MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        this.confirm = true;
        matchingControl.setErrors({
          mustMatch: true
        });
      } else {
        this.confirm = false;
        matchingControl.setErrors(null);
      }
    }
  }



fileProgress(fileInput: any) {
    this.fileData = < File > fileInput.target.files[0];
  }

onUpload() {
    const formData = new FormData();
    formData.append('file_name', this.fileData);
    this.tradeApiService.ApiServices(this.urlDocuments, formData, null).subscribe((documentImg: any) => {
    });
  }



updatePassword() {

    this.submittedPassword = true;

    // stop here if form is invalid
    if (this.passwordUpdateForm.invalid) {
      return;
    }

    let input = {
      uK: this.userDetails.user_key,
    };

    this.commonService.showLoader = 1;

    let userInput = Object.assign(input, this.passwordUpdateForm.value);


    this.tradeApiService.ApiServices(this.urlProfileChangePassword, userInput, null).subscribe((changePassword: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (changePassword.status == 200) {
        this.commonService.showToaster(changePassword.message, 1);
        this.clearForm();
      } else {
        const errorValues = Object.keys(changePassword.message).map(key => changePassword.message[key]);
        for (var i = 0; i < errorValues.length; i++) {
          this.commonService.showToaster(errorValues[i], 4);
        }
      }

      this.commonService.showLoader = 0;

    });


  }

  /**
   * Clearing the form data for change password
   */

clearForm() {

    this.submittedPassword = false;
    this.passwordUpdateForm.clearValidators();
    this.passwordUpdateForm.reset();
    this.passwordUpdateForm.markAsPristine();

  }

  /**
   *  Function for sending the otp for verifying the mobile number
   * 
   */

sendOtpVerifyMobileEmail(eM) {

    this.commonService.showLoader = 1;
    let emailOrMobile = '';
    if (eM == 1) {
      emailOrMobile = this.userDetails.user_email;
    }
    if (eM == 2) {
      emailOrMobile = this.userDetails.user_mobile;
    }

    let input = {
      //token:localStorage.getItem('auth_token'),                       
      uK: this.userDetails.user_key,
      uM: emailOrMobile,
      _eM: eM
    };

    this.tradeApiService.ApiServices(this.urlProfileEmailMobileVerify, input, null).subscribe((otpDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (otpDetails.status == 200) {

        this.ShowTxtMob = 0;
        this.ShowEnterOtpMob = 1;

        this.commonService.showToaster(otpDetails.message, 1);
      } else {
        this.commonService.showToaster(otpDetails.message, 4);
      }

      this.commonService.showLoader = 0;

    });


  }

  /**
   * Once User Received OPT
   */
OtpVerifyMobileEmail(eM) {
    this.OtpVerifyMobileError = 0;
    if (this.mobileNumberOtp == '') {
      this.OtpVerifyMobileError = 1;
      return;
    }

    this.commonService.showLoader = 1;

    let input = {
      //token:localStorage.getItem('auth_token'),                       
      uK: this.userDetails.user_key,
      uO: this.mobileNumberOtp,
      _eM: eM
    };

    this.tradeApiService.ApiServices(this.urlProfileEmailMobileOtpVerify, input, null).subscribe((otpDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (otpDetails.status == 200) {

        this.ShowTxtMob = 0;
        this.ShowEnterOtpMob = 0;
        this.isMobileVerified = 1;

        this.commonService.showToaster(otpDetails.message, 1);
        this.modalService.dismissAll();
        this.getProfileDetails();
      } else {
        this.OtpVerifyMobileError = 1;
        this.commonService.showToaster(otpDetails.message, 4);
      }

      this.commonService.showLoader = 0;

    });


  }


  /**
   * Fecting the profile Details
   */
getProfileDetails() {
    this.commonService.showLoader = 1;

    let input = {
      // token:localStorage.getItem('auth_token'),                       
      uK: this.userDetails.user_key,
    };

    this.tradeApiService.ApiServices(this.urlProfileDetails, input, null).subscribe((otpDetails: any) => {

      if (otpDetails.status == 200) {

        this.genderListArray();
        this.employmentListArray();
        this.industryListListArray();
        this.questionListArray();

        this.userDetails = otpDetails.pU;
        localStorage.setItem('user_details', JSON.stringify(otpDetails.pU));
        this.countryList = otpDetails.countryList;
        this.userDetails = otpDetails.pU;
        this.userDocumentDetails = otpDetails.pD;
        this.userDocuments = otpDetails.pD;
        this.userAddressDetails = otpDetails.pK;
        this.userSourceDetails = otpDetails.pS;
        
        
        if (this.userDetails != '') {
          // My Info Form
          this.registerForm = this.formBuilder.group({
            firstName: this.userDetails.user_name,
            lastName: this.userDetails.last_name,
            middleName: this.userDetails.middle_name,
            nationality: this.userDetails.nationality,
            gender: this.userDetails.gender.toString(),
            birthPlace: this.userDetails.birth_place,
            dob: null,
          });

          if (this.userDetails.gender == 0 || this.userDetails.gender == 'null') {
            this.registerForm.controls['gender'].setValue('1');
          }

          if (this.userDetails.dob == '' || this.userDetails.dob == 'null' || this.userDetails.dob == null) {
            this.registerForm.controls['dob'].setValue(new Date());
          } else {
            this.registerForm.controls['dob'].setValue({
              year: new Date(this.userDetails.dob).getUTCFullYear(),
              month: new Date(this.userDetails.dob).getMonth() + 1,
              day: new Date(this.userDetails.dob).getDate()
            });
          }

          // Address Form 
          this.addressForm = this.formBuilder.group({
            country: this.userDetails.country_key
          });
        }

        if (this.userSourceDetails != '') {
          this.sourceForm = this.formBuilder.group({
            employment: this.userSourceDetails.employment,
            companyName: this.userSourceDetails.company,
            industry: this.userSourceDetails.industry,
            position: this.userSourceDetails.position,
            annual_income: this.userSourceDetails.annual_income,
            annual_investment: this.userSourceDetails.annual_investment
          });
          if (this.userSourceDetails.employment == 4) {
            $('input[name=companyName], select#industry, input[name=position], input[name=annual_income]').prop('disabled', true);
            $(".form-check-input").attr('disabled', true);
          } else {
            $('input[name=companyName], select#industry, input[name=position], input[name=annual_income]').prop('disabled', false);
            $(".form-check-input").attr('disabled', false);
          }
        }

        if (this.userSourceDetails.employment != null && this.userSourceDetails.annual_investment != null) {
          this.kycButton = true;
        }

        this.checkRequiredDocument();
        if(this.userAddressDetails.length == 0){
          this.genderStatus = false;          
        }
        
        // address form
        console.log(this.genderStatus);
        if (this.userAddressDetails != '') {
          
          // To disable all the inputs depende on status
          if (this.userAddressDetails.kyc_status == 3 || this.userAddressDetails.kyc_status == 0) {
            $('#timeline :input, #address :input, #photos :input, #source :input').prop('disabled', false);            
            this.genderStatus = false;
            this.kycButton = true;
          } else if (this.userAddressDetails.kyc_status == 2 || this.userAddressDetails.kyc_status == 4) {              
            $('#timeline :input, #address :input, #photos :input, #source :input').prop('disabled', true);
            $('#timeline :button, #address :button, #photos :button, #source :button, #preview :button').remove();
            this.genderStatus = true;
            this.kycButton = false;
            if(otpDetails.dS == 0){              
              this.documentVisible = false;
            }           
          } else if (this.userAddressDetails.kyc_status == 1) {
            $('#timeline :button, #address :button, #photos :button, #source :button, #preview :button').remove();
            $('#timeline :input, #address :input, #photos :input, #source :input').prop('disabled', true);
            this.genderStatus = true;
            this.kycButton = false;
          }

         
          if (otpDetails.sqw == 0 || this.userAddressDetails.question_one != null && this.userAddressDetails.answer_one != null && this.userAddressDetails.question_one != '' && this.userAddressDetails.answer_one != '') {
            this.questionDiv = false;
          } else {
            this.kycButton = false;
          }

          this.addressForm = this.formBuilder.group({
            addressLine: this.userAddressDetails.address_one,
            addressLine2: this.userAddressDetails.address_two,
            postalCode: this.userAddressDetails.postal_code,
            country: this.userDetails.country_key,
            city: this.userAddressDetails.city,
            nationality: this.userAddressDetails.nationality
          });
        }
        if (this.userDetails.birth_place != '' && this.userDetails.birth_place != null) {
          $('#address-tab').not('.active').removeClass('disabled');
          $('#photos-tab').not('.active').addClass('disabled');
          $('#source-tab').not('.active').addClass('disabled');
          $('#preview-tab').not('.active').addClass('disabled');
        } else {
          $('#address-tab').not('.active').addClass('disabled');
        }

        if (this.userAddressDetails['nationality'] != '' && this.userAddressDetails['nationality'] != null) {
          $('#photos-tab').not('.active').removeClass('disabled');
          $('#source-tab').not('.active').addClass('disabled');
          $('#preview-tab').not('.active').addClass('disabled');
        } else {
          $('#photos-tab').not('.active').addClass('disabled');
        }
        
        if (this.userSourceDetails['annual_investment'] != null && this.userSourceDetails['annual_investment'] >= 0) {
          $('#preview-tab').not('.active').removeClass('disabled');
        } else {
          $('#preview-tab').not('.active').addClass('disabled');
        }
        if (this.userDetails['doc_valid_count'] == 2) {
          this.isEnabled = true;
          $('#source-tab').not('.active').removeClass('disabled');
        } else {
          $('#source-tab').not('.active').addClass('disabled');
          $('#preview-tab').not('.active').addClass('disabled');
        }

        
        this.documentValueSet();
      } else {
        this.commonService.showToaster(otpDetails.message, 4);
      }
      this.commonService.showLoader = 0;
    });
  }

  // currentKycStatus() {    
  //   if (this.userAddressDetails.kyc_status == 0 || this.userAddressDetails.kyc_status == null || this.userAddressDetails.kyc_status == '') {
  //     return 'Your Profile Has Been Not Submitted To KYC.'
  //   } else if (this.userAddressDetails.kyc_status == 1) {
  //     return 'Your Profile Has Been Submitted To KYC.'
  //   } else if (this.userAddressDetails.kyc_status == 2) {
  //     return 'Your Profile Has Been Approved By KYC.'
  //   } else if (this.userAddressDetails.kyc_status == 3) {
  //     return 'You have to Re-submit your document, Reason ' + this.userAddressDetails.kyc_reason + '.';
  //   } else {
  //     return '';
  //   }
  // }

checkRequiredDocument() {
    if (this.userDocumentDetails != '') {
      var j = 0;
      for (let index = 0; index < this.userDocumentDetails.length; index++) {
        const element = this.userDocumentDetails[index];
        if (element.user_document_number == 'clientDocument_1' || element.user_document_number == 'clientDocument_3') {
          this.documentRequired = j;
          j++;
        }
        if (j == 2) {
          this.nextSuccess = true;
          this.nextError = false;
          // $('#nextValid').show();          
        } else {
          this.nextError = true;
          this.nextSuccess = false;
          //  $('#nextValid').show();          
        }
      }
    }
  }
  /**
   * Assing the document image URL to popup image viewer and thumline
   */

documentValueSet() {
    this.albums = []; // for image preview  
    var i = this.userDocuments.length;

    for (var doc of this.userDocuments) {
      // for image preview
      const src = doc.image;
      const caption = '';
      const thumb = doc.document_url;
      const id = 'Document ' + i;
      const album = {
        src: src,
        caption: caption,
        thumb: thumb,
      };

      // Document upload #060
      $('#' + doc.user_document_number).css('display', 'block');
      $('#' + 'Preview_' + doc.user_document_number).css('display', 'block');
      //$('#'+'Upload_'+doc.user_document_number).css('display','block');        
      if (album.src != null || album.src != 'null') {

        this.albums.push(album);
      }


      // end for image preview

      this.documentImageMapping(doc.user_document_number, doc.image);
      i--;
    }
  }

  /**
   * Here Mapping the document Image for thumline
   */

documentImageMapping(docNuber, FileUrl) {

    switch (docNuber) {
      case 'clientDocument_1':
        this.clientDocument_1 = FileUrl;
        break;
      case 'clientDocument_2':
        this.clientDocument_2 = FileUrl;
        break;
      case 'clientDocument_3':
        this.clientDocument_3 = FileUrl;
        break;
      case 'clientDocument_4':
        this.clientDocument_4 = FileUrl;
        break;
      case 'clientDocument_5':
        this.clientDocument_5 = FileUrl;
        break;
      case 'clientDocument_6':
        this.clientDocument_6 = FileUrl;
        break;
      case 'clientDocument_7':
        this.clientDocument_7 = FileUrl;
        break;
      case 'clientDocument_8':
        this.clientDocument_8 = FileUrl;
        break;
    }

  }


  // uploading the profile / img url to the image tag

uploadProfileImg() {

    this.commonService.showLoader = 1;

    let input = {
      //token:localStorage.getItem('auth_token'),                       
      uK: this.userDetails.user_key,
      u_Pi: this.imagePathtmp
    };

    this.tradeApiService.ApiServices(this.urlProfileImg, input, null).subscribe((profileImg: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (profileImg.status == 200) {
        this.imagePathProfile = profileImg.profile;
        this.commonService.userProfileImg = profileImg.profile;
        this.commonService.showToaster(profileImg.message, 1);
        this.getProfileDetails();
        this.modalService.dismissAll();
      } else {
        this.commonService.showToaster(profileImg.message, 4);
      }

      this.commonService.showLoader = 0;

    });

  }

  /**
   *  Edit Customer Email
   */

editCustomerEmail(withOpt = 1) {
    this.submittedChangeEmail = true;

    // stop here if form is invalid
    if (this.changeEmailForm.invalid) {
      return;
    }

    this.commonService.showLoader = 1;

    let input = {
      uK: this.userDetails.user_key,
      wOpt: withOpt
    };


    let userInput = Object.assign(input, this.changeEmailForm.value);

    if (withOpt == 2) {
      let Otpinput = {
        oE: this.changeEmailNumberOtp,
      };
      userInput = Object.assign(Otpinput, userInput);
    }

    this.tradeApiService.ApiServices(this.urlProfileEmailChange, userInput, null).subscribe((otpDetails: any) => {
      if (otpDetails.status == 200) {
        this.newEmaildisabled = 1;
        this.newEmailShowOtp = 1;
        this.commonService.showToaster(otpDetails.message, 1);
        if (withOpt == 2) {
          this.getProfileDetails();
          this.modalService.dismissAll();
        }

      } else {
        const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
        for (var i = 0; i < errorValues.length; i++) {
          this.commonService.showToaster(errorValues[i], 4);
        }
      }
      this.commonService.showLoader = 0;
    });
  }
  /**
   * verify Change Email Otp
   */

verifyChangeEmailOtp() {
    this.changeEmailNumberOtpError = 0;

    if (this.changeEmailNumberOtp == '') {
      this.changeEmailNumberOtpError = 1;
      return;
    }
    this.editCustomerEmail(2);
  }

clearFormEmail() {

    this.submittedChangeEmail = false;
    this.changeEmailForm.clearValidators();
    this.changeEmailForm.reset();
    this.changeEmailForm.markAsPristine();

    this.newEmaildisabled = 0;
    this.newEmailShowOtp = 0;
    this.changeEmailNumberOtp = '';
    this.changeEmailNumberOtpError = 0;
  }

changeNamePopOtp(showLab) {
    this.otpShowLabChange = showLab;
  }

  /** 
   * Once Submitted Disable to the all the inputs.
   */
submit_kyc() {
    this.commonService.showLoader = 1;
    var params = {
      user_key: this.userDetails.user_key
    };

    var Your_Profile_Has_Been_Submitted_To_KYC;
    this.translate.get('clientUserProfile.Your_Profile_Has_Been_Submitted_To_KYC').subscribe(lang => {
      Your_Profile_Has_Been_Submitted_To_KYC = lang;
    });

    this.tradeApiService.ApiServices(this.urlProfileToKyc, params, null).subscribe((otpDetails: any) => {
      if (otpDetails.status == 200) {
        this.commonService.showToaster(Your_Profile_Has_Been_Submitted_To_KYC, 1);
        $('#timeline :input, #address :input, #photos :input, #source :input').prop('disabled', true);
        $(".form-check-input").attr('disabled', true);
        $('#timeline :button, #address :button, #photos :button, #source :button, #preview :button').remove();
        this.kycButton = false;
      } else {
        const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
        for (var i = 0; i < errorValues.length; i++) {
          this.commonService.showToaster(errorValues[i], 4);
        }
      }
      this.commonService.showLoader = 0;
    });
    setTimeout(() => {
      location.reload();
    }, 2000);
  }

  /** 
   * If person unemployment disabled the un-necessary inputs
   */
  // employementStatus(event:any){
  //   if(event.target.value == 4)
  //   {
  //     $('input[name=companyName], select#industry, input[name=position], input[name=annual_income]').prop('disabled', true);
  //   } else {
  //     $('input[name=companyName], select#industry, input[name=position], input[name=annual_income]').prop('disabled', false);
  //   }
  // }

showTabActive(disabled, hide, show) {
    $('#' + disabled + '-tab').not('.active').removeClass('disabled');
    $('#' + hide + '-tab').removeClass('active');
    $('#' + show + '-tab').addClass('active');
    $('#' + hide).removeClass('show active');
    $('#' + show).addClass('show active');
  }

getEmploymentStatus(id) {

    var Salaried;
    this.translate.get('clientUserProfile.Salaried').subscribe(lang => {
      Salaried = lang;
    });

    var SelfEmployement;
    this.translate.get('clientUserProfile.SelfEmployement').subscribe(lang => {
      SelfEmployement = lang;
    });

    var Business;
    this.translate.get('clientUserProfile.Business').subscribe(lang => {
      Business = lang;
    });

    var UnEmployement;
    this.translate.get('clientUserProfile.UnEmployement').subscribe(lang => {
      UnEmployement = lang;
    });

    if (id == 1) {
      return Salaried;
    }
    if (id == 2) {
      return SelfEmployement;
    }
    if (id == 3) {
      return Business;
    }
    if (id == 4) {
      return UnEmployement;
    }
  }

getIndustry(id) {

    var Computer;
    this.translate.get('clientUserProfile.Computer').subscribe(lang => {
      Computer = lang;
    });
    var TeleCommunication;
    this.translate.get('clientUserProfile.TeleCommunication').subscribe(lang => {
      TeleCommunication = lang;
    });
    var Agriculture;
    this.translate.get('clientUserProfile.Agriculture').subscribe(lang => {
      Agriculture = lang;
    });
    var Construction;
    this.translate.get('clientUserProfile.Construction').subscribe(lang => {
      Construction = lang;
    });
    var Pharamedical;
    this.translate.get('clientUserProfile.Pharamedical').subscribe(lang => {
      Pharamedical = lang;
    });
    var Education;
    this.translate.get('clientUserProfile.Education').subscribe(lang => {
      Education = lang;
    });
    var Transport;
    this.translate.get('clientUserProfile.Transport').subscribe(lang => {
      Transport = lang;
    });
    var Aerospace;
    this.translate.get('clientUserProfile.Aerospace').subscribe(lang => {
      Aerospace = lang;
    });
    var Food;
    this.translate.get('clientUserProfile.Food').subscribe(lang => {
      Food = lang;
    });

    if (id == 1) {
      return Computer;
    }
    if (id == 2) {
      return TeleCommunication;
    }
    if (id == 3) {
      return Agriculture;
    }
    if (id == 4) {
      return Construction;
    }
    if (id == 5) {
      return Pharamedical;
    }
    if (id == 6) {
      return Education;
    }
    if (id == 7) {
      return Transport;
    }
    if (id == 8) {
      return Aerospace;
    }
    if (id == 9) {
      return Food;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;

}

miniCarouselSlider: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    margin: 15, // margin-left of slide
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 3
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: true
  }
}
