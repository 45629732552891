import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})

export class GroupComponent implements OnInit {

    userDetails;
    urlGetGroupDetails = 'get-group-lists';
    urlAddEditGroup = 'add-edit-group';
    urlBlockUnblockGroup = 'block-unblock-group';
    urlGetIndividualGroup = 'get-individual-group-details';
    groupLists; //for all Group lists
    closeResult: string;
    groupData; //for block & unblock
    individualGroupData; //individual Group details stored for edit Group modal
    title; //Modal Popup header Title
    buttonName; //Modal Popup Button Name
    blockUnblockTitle;

    addEditGroupForm: FormGroup;
    addEditSubmitted = false;

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    searchInputData = '';
    searchGroupType = '';
    searchDate;

    @ViewChild('contentAddNewGroup', {static: false}) private contentAddNewGroup: ElementRef;
    @ViewChild('blockUnblockGroup', {static: false}) private blockUnblockGroup: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) {
        this.AuthorisedLayoutComponent.getRouteAccess();
     }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(mTy) {
        return this.commonService.getMenuPermission('39e1ba6bed975a07a085', mTy);
    }

    ngOnInit() {

        this.getGroupLists();

        this.userDetails = JSON.parse( localStorage.getItem('user_details'));

        this.addEditGroupForm = this.formBuilder.group({
            groupName: ['', [Validators.required]],
            groupType: ['', [Validators.required]],
            gK: ['', []],
        });
    }

    open(content, sizeS, modalTitleID){
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    dateFormates( dateObject ) {
        let d = new Date(dateObject);
        let day:any = dateObject.day;
        let month:any = dateObject.month;
        let year = dateObject.year;
        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        let date = year + "-" + month + "-" + day;
        return date;
    }

    /**
     * Fecting Group Lists
     */

    getGroupLists() {

        this.commonService.showLoader = 1;

        var CreationDate;
        if ( this.searchDate !== undefined && this.searchDate !== null ) {
            CreationDate = this.dateFormates( this.searchDate );
        }

        let input = {
            page: this.page,
            _sDl: this.searchInputData,
            _sGT: this.searchGroupType,
            _sDat: CreationDate
        };

        this.tradeApiService.ApiServices(this.urlGetGroupDetails, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.groupLists = responseData.groupLists.Records;
                this.totalItems = responseData.groupLists.TotalCount; 
            }
            else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Load pagination next page
     */

    loadPage(page: number) {   
        this.page = page;
        this.getGroupLists();
    }

    /**
     * Add Group Modal Popup
     */

    addNewGroupModal() {
        this.resetForm();
        this.open(this.contentAddNewGroup, 'x', 'modal-basic-title');
        this.title = 'Add New';
        this.buttonName = 'Add';
        this.addEditGroupForm.controls['groupType'].setValue('');
    }

    /**
     * Reset add Group form values and error messages
     */

    resetForm() {
        this.addEditSubmitted = false;
        this.addEditGroupForm.clearValidators();
        this.addEditGroupForm.reset();
        this.addEditGroupForm.markAsPristine()
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
    }

    /**
     * Angular error message form control
     */

    get f() { return this.addEditGroupForm.controls; } //Add Group Error Controller

    /**
     * Add & Edit Group form Submit
     */

    addEditGroupSubmit() {
        this.addEditSubmitted = true;

        // stop here if form is invalid
        if (this.addEditGroupForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.addEditGroupForm.value);

        this.tradeApiService.ApiServices(this.urlAddEditGroup, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.getGroupLists();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for(var i=0; i<errorValues.length;i++){                                  
                    this.commonService.showToaster( errorValues[i] , 4 ); 
                }  
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Block & Unblock Group Modal Popup
     */

    blockUnblock(data) {
        this.groupData = data;
        
        this.blockUnblockTitle = (data.group_status == 0) ? 'Unblock' : 'Block';

        this.open(this.blockUnblockGroup, 'x', 'modal-blockunblock-title');
    }

    /**
     * Change the Group Status
     */

    confirmBlockUnblock() {
        
        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
          gK: this.groupData.group_key,
          gS: this.groupData.group_status,
        };

        let userInput = Object.assign(input);

        this.tradeApiService.ApiServices(this.urlBlockUnblockGroup, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.groupData = null;
                this.getGroupLists();
            } else {
                this.commonService.showToaster( responseData.message , 4 );
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Get Individual Group Detail
     */

    getIndividualGroup(data) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            gK: data.group_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualGroup, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.open(this.contentAddNewGroup, 'x', 'modal-basic-title');
                this.title = 'Edit';
                this.buttonName = 'Update';

                this.individualGroupData = responseData.gD;

                this.addEditGroupForm.controls['gK'].setValue(data.group_key);
                this.addEditGroupForm.controls['groupName'].setValue(this.individualGroupData.group_name);
                this.addEditGroupForm.controls['groupType'].setValue(this.individualGroupData.group_type);
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Clear Search Data's
     */

    clearSearchData() {
        this.searchInputData = "";
        this.searchGroupType = '';
        this.searchDate = undefined;
        this.getGroupLists();
    }
}
