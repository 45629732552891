import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { CommonService } from '../../../common.service';
import { ApiTradeService } from '../../../api-trade.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-authorised-top-nav',
    templateUrl: './authorised-top-nav.component.html',
    styleUrls: ['./authorised-top-nav.component.scss']
})
export class AuthorisedTopNavComponent implements OnInit {

    // Add the URL Route here for breafCrum Details

    public routerName: type[] = [
        { id: '/dashboard', breadCrum: 'Dashboard' },
        { id: '/client-demo-account', breadCrum: 'ClientDemoAccount' },
        { id: '/client-live-account', breadCrum: 'ClientLiveAccount' },
        { id: '/client-profile', breadCrum: 'ClientClientProfile' },
        { id: '/client-position', breadCrum: 'ClientClientPosition' },
        { id: '/client-history', breadCrum: 'ClientClientHistory' },
        { id: '/live-account-request', breadCrum: 'LiveAccountRequest' },
        { id: '/account-details', breadCrum: 'LiveAccountDetails' },
        { id: '/live-position-details', breadCrum: 'LivePositionDetails' },
        { id: '/live-history-details', breadCrum: 'LiveHistoryDetails' },
        { id: '/demo-account-details', breadCrum: 'DemoAccountDetails' },
        { id: '/demo-position-details', breadCrum: 'DemoPositionDetails' },
        { id: '/demo-history-details', breadCrum: 'DemoHistoryDetails' },
        { id: '/country-list', breadCrum: 'LocationManagementCountriesList' },
        { id: '/city-list', breadCrum: 'LocationManagementCitiesList' },
        { id: '/branch-list', breadCrum: 'LocationManagementBranchesList' },
        { id: '/designation-list', breadCrum: 'GeneralManagementDesignationsList' },
        { id: '/mt5-account-list', breadCrum: 'GeneralManagementMt5AccountsList' },
        { id: '/group-list', breadCrum: 'GeneralManagementGroupsList' },
        { id: '/leverage-list', breadCrum: 'GeneralManagementLeveragesList' },
        { id: '/settings', breadCrum: 'Settings' },
        { id: '/employee-list', breadCrum: 'GeneralManagementEmployeesList' },
        { id: '/profile-details', breadCrum: 'ProfileDetail' },
        { id: '/roles-list', breadCrum: 'GeneralManagementRolesPrivileges' },
        { id: '/deposit-withdrawal', breadCrum: 'TransactionDepositWithdrawalRequest' },
        { id: '/request-queue-list', breadCrum: 'TransactionDepositWithdrawalQueueList' },
        { id: '/kyc-lists', breadCrum: 'KYCInformationDocumentRequestQueue' },
        { id: '/customer-list', breadCrum: 'KYCInformationCustomersProfile' },
        { id: '/client-dashboard', breadCrum: 'MyDashboard' },
        { id: '/admin-dashboard', breadCrum: 'Dashboard' },
        { id: '/change-client-info', breadCrum: 'ProfileInformation' },
        { id: '/packages-list', breadCrum: 'PackagesList' },
        { id: '/individual-activity-log', breadCrum: 'IndividualActivityLogs' },
        { id: '/activity-logs', breadCrum: 'ActivityLogs' },
        { id: '/language-list', breadCrum: 'LanguagesList' },
        { id: '/deposit-withdrawal-details', breadCrum: 'DepositDetails' },
        { id: '/balance-transfer', breadCrum: 'BalanceTransfer' },
    ];

    userDetails;
    usertype = 0;
    lastRoute = '';

    constructor(private router: Router, private _location: Location, public commonService: CommonService, public tradeApiService: ApiTradeService, private translate: TranslateService,private route: ActivatedRoute) {
        this.commonService.checkforAuth();
        this.userDetails = JSON.parse(localStorage.getItem('user_details'));
        this.usertype = this.userDetails.user_type;

        translate.setDefaultLang(this.commonService.defaultLanguale);
    }

    getMenuPermission(module_key, mTy) {
        var userDetails = JSON.parse(localStorage.getItem('user_details'));

        if (userDetails.user_type == 1) {
            return 1;
        }
        var p = 0;
        var skinName = this.tradeApiService.permissions.find(x => x.module_key == module_key);
        if( skinName !== undefined ){
            if (skinName.module_key == module_key) {
                if (mTy == 1) { // Main Menu
                    if (skinName.view == 1) {
                        p = 1;
                    }
                } else { // Sub Menu
                    if (skinName.manage == 1) {
                        p = 1;
                    }
                }
            }
        }
        return p;
    }

    ngOnInit() {
    }

    // Find the Current Matched URL And Display the BreadCrum Name
    getBreadCrumName() {

        var breadCrumName = this.routerName.find(x => x.id == this.router.url);
        let breadCrumText = '';
        if (breadCrumName) {

            this.translate.get('breadCrum.' + breadCrumName.breadCrum).subscribe(lang => {
                breadCrumText = lang;
            });
            return breadCrumText;
        } //  breadCrumName

    }

    backClicked() {
        this._location.back();
    }

    changeLanguage(lang) {
        this.translate.setDefaultLang(lang);
        this.commonService.changeCommonLanguage(lang);

        if( this.router.url == '/client-profile' ) {
            this.router.navigateByUrl('/client-dashboard', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/client-profile']);
            });
        }
        if( this.router.url == '/kyc-views?page=kyc-list' || this.router.url == '/kyc-views?page=customer') {
            let pageFrom;
            this.route.queryParams.subscribe(params => {
                if (params.page) {
                    pageFrom = params.page;
                }
            });

            this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/kyc-views'], {
                    queryParams: {
                        page: pageFrom
                    }
                });
            });
        }

        if( this.router.url == '/customer-list' ) {
            this.router.navigateByUrl('/kyc-lists', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/customer-list']);
            });
        }
    }

    // For removing the active menu and reproduce
    checkMenuReproduce( url) {
        this.commonService.checkDiv();

        if( this.lastRoute == '' || this.lastRoute !== url){
            this.lastRoute = this.lastRoute;
        }
        this.router.navigateByUrl(this.lastRoute, { skipLocationChange: true }).then(() => {
            this.router.navigate([url]);
        });
    }
}

export interface type {
    id: string;
    breadCrum: string;
}
