import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GuestLayoutComponent } from './layout/guest/guest-layout/guest-layout.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';

import { AuthorisedLayoutComponent } from './layout/authorised/authorised-layout/authorised-layout.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ClientUserProfileComponent } from './pages/client-user-profile/client-user-profile.component';
import { ClientDemoAccountsDetailsComponent } from './pages/client-demo-accounts-details/client-demo-accounts-details.component';
import { ClientLiveAccountsDetailsComponent } from './pages/client-live-accounts-details/client-live-accounts-details.component';
import { MyPositionsComponent } from './pages/my-positions/my-positions.component';
import { MyHistoryComponent } from './pages/my-history/my-history.component';
import { AccountDetailsComponent } from './pages/account-details/account-details.component';

import { BackOfficeLiveAccountRequestComponent } from './pages/back-office-live-account-request/back-office-live-account-request.component';
import { BoLivePostionAccountDetailsComponent } from './pages/bo-live-postion-account-details/bo-live-postion-account-details.component';
import { BoLiveTradeHistoryDetailsComponent } from './pages/bo-live-trade-history-details/bo-live-trade-history-details.component';

import { LoginComponent } from './pages/login/login.component';
import { NewAccountRegisterComponent } from './pages/new-account-register/new-account-register.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { LogoutComponent } from './pages/logout/logout.component';
// import { FileuploadComponent } from './pages/fileupload/fileupload.component';
import { BackOfficeKycListsComponent } from './pages/back-office-kyc-lists/back-office-kyc-lists.component';

import { CountryComponent } from './pages/country/country.component';
import { CityComponent } from './pages/city/city.component';
import { BranchComponent } from './pages/branch/branch.component';
import { DesignationComponent } from './pages/designation/designation.component';
import { Mt5AccountsComponent } from './pages/mt5-accounts/mt5-accounts.component';
import { GroupComponent } from './pages/group/group.component';
import { LeverageComponent } from './pages/leverage/leverage.component';
import { BalanceComponent } from './pages/balance/balance.component';

import { DemoAccountDetailsComponent } from './pages/demo-account-details/demo-account-details.component';
import { BoDemoPostionAccountDetailsComponent } from './pages/bo-demo-postion-account-details/bo-demo-postion-account-details.component';
import { BoDemoTradeHistoryDetailsComponent } from './pages/bo-demo-trade-history-details/bo-demo-trade-history-details.component';
import { BackOfficeKycViewComponent } from './pages/back-office-kyc-view/back-office-kyc-view.component';
import { BoTransactionWithdrawalDepositeComponent } from './pages/bo-transaction-withdrawal-deposite/bo-transaction-withdrawal-deposite.component';
import { BoTransactionQueueListWithdrawalDepositeComponent } from './pages/bo-transaction-queue-list-withdrawal-deposite/bo-transaction-queue-list-withdrawal-deposite.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ChangeClientInfoComponent } from './pages/change-client-info/change-client-info.component';
import { EmployeesComponent } from './pages/employees/employees.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { RolesComponent } from './pages/roles/roles.component';

import { ClientDashboardComponent } from './pages/client-dashboard/client-dashboard.component';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';

import { AdminProfilePageComponent } from './pages/admin-profile-page/admin-profile-page.component';
import { PackageComponent } from './pages/package/package.component';
import { ActivityLogsComponent } from './pages/activity-logs/activity-logs.component';
import { IndividualActivityLogComponent } from './pages/individual-activity-log/individual-activity-log.component';
import { LanguagesComponent } from './pages/languages/languages.component';
import { Mt5ActivityLogsComponent } from './pages/mt5-activity-logs/mt5-activity-logs.component';
import { ElementsComponent } from './pages/elements/elements.component';
import { ElementLogoComponent } from './pages/element-logo/element-logo.component';
import { ElementColorsComponent } from './pages/element-colors/element-colors.component';
import { DepositWithdrawalComponent } from './pages/deposit-withdrawal/deposit-withdrawal.component';
import { BalanceTransferComponent } from './pages/balance-transfer/balance-transfer.component';

const routes: Routes = [
    {
        path: '',
        component: GuestLayoutComponent,
        children: [
            // { path: '', component: LandingPageComponent, pathMatch: 'full'},
            { path: '', component: LoginComponent, pathMatch: 'full' },
            { path: 'login', component: LoginComponent, pathMatch: 'full' },
            { path: 'register', component: NewAccountRegisterComponent, pathMatch: 'full' },
            { path: 'forget-password', component: ForgetPasswordComponent, pathMatch: 'full' },
            { path: 'logout', component: LogoutComponent },
        ]
    },
    {
        path: '',
        component: AuthorisedLayoutComponent,
        children: [
            { path: 'dashboard', component: DashboardComponent }, // for other employees
            { path: 'client-profile', component: ClientUserProfileComponent },
            { path: 'client-demo-account', component: ClientDemoAccountsDetailsComponent },
            { path: 'client-live-account', component: ClientLiveAccountsDetailsComponent },
            // { path: 'upload', component: FileuploadComponent },
            { path: 'client-position', component: MyPositionsComponent },
            { path: 'client-history', component: MyHistoryComponent },
            { path: 'live-account-request', component: BackOfficeLiveAccountRequestComponent },
            { path: 'country-lists', component: CountryComponent },
            { path: 'kyc-lists', component: BackOfficeKycListsComponent },
            { path: 'account-details', component: AccountDetailsComponent }, // live account details
            { path: 'live-position-details', component: BoLivePostionAccountDetailsComponent }, // live position details      
            { path: 'country-list', component: CountryComponent },
            { path: 'city-list', component: CityComponent },
            { path: 'branch-list', component: BranchComponent },
            { path: 'designation-list', component: DesignationComponent },
            { path: 'mt5-account-list', component: Mt5AccountsComponent },
            { path: 'group-list', component: GroupComponent },
            { path: 'leverage-list', component: LeverageComponent },
            { path: 'balance-list', component: BalanceComponent },
            { path: 'live-history-details', component: BoLiveTradeHistoryDetailsComponent }, // live history details
            // { path: 'demo-account-details', component: DemoAccountDetailsComponent }, // live history details
            { path: 'demo-position-details', component: BoDemoPostionAccountDetailsComponent }, // live history details
            { path: 'demo-history-details', component: BoDemoTradeHistoryDetailsComponent }, // live history details
            { path: 'kyc-views', component: BackOfficeKycViewComponent, pathMatch: 'full' },
            { path: 'settings', component: SettingsComponent },
            { path: 'employee-list', component: EmployeesComponent },
            { path: 'customer-list', component: CustomersComponent },
            { path: 'roles-list', component: RolesComponent },
            { path: 'packages-list', component: PackageComponent },
            { path: 'demo-account-details', component: DemoAccountDetailsComponent }, // demo account details
            { path: 'demo-position-details', component: BoDemoPostionAccountDetailsComponent }, // demo position details
            { path: 'demo-history-details', component: BoDemoTradeHistoryDetailsComponent }, // demo history details
            { path: 'deposit-withdrawal', component: BoTransactionWithdrawalDepositeComponent }, // Transaction Withdrawal Deposite
            { path: 'request-queue-list', component: BoTransactionQueueListWithdrawalDepositeComponent }, // Transaction Withdrawal Deposite
            { path: 'change-client-info', component: ChangeClientInfoComponent },
            { path: 'client-dashboard', component: ClientDashboardComponent }, // for client Dashboard
            { path: 'admin-dashboard', component: AdminDashboardComponent }, // for admin Dashboard
            { path: 'profile-details', component: AdminProfilePageComponent }, // for other admin / employee users
            { path: 'activity-logs', component: ActivityLogsComponent },
            { path: 'individual-activity-log', component: IndividualActivityLogComponent },
            { path: 'language-list', component: LanguagesComponent },
            { path: 'mt5-activity-log', component: Mt5ActivityLogsComponent },
            { path: 'elements', component: ElementsComponent },
            { path: 'logo-settings', component: ElementLogoComponent },
            { path: 'color-settings', component: ElementColorsComponent },
            { path: 'deposit-withdrawal-details', component: DepositWithdrawalComponent },
            { path: 'balance-transfer', component: BalanceTransferComponent },
        ]
    },
    {
        path: 'msg', component: GuestLayoutComponent, pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
