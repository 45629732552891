import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-deposit-withdrawal',
  templateUrl: './deposit-withdrawal.component.html',
  styleUrls: ['./deposit-withdrawal.component.scss']
})
export class DepositWithdrawalComponent implements OnInit {

  userDetails;
  urlGetMyAccountLogin = 'my-account-login-details';
  myHLoginsDetails;
  withdrawalUpdateForm: FormGroup;
  submittedW = false;
  urSendWithDrawal = 'send-withdr-request';


  constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent, private translate: TranslateService) {
    this.AuthorisedLayoutComponent.getRouteAccess();
    translate.setDefaultLang( this.commonService.defaultLanguale );

    this.withdrawalUpdateForm = this.formBuilder.group({
      login: ['', [Validators.required]],
      amount: ['', [Validators.required]],  
      bankdetails: [''], // bank address       
      Beneficiary_Name: ['', [Validators.required]],  
      // Account_number: ['', [Validators.required]],
      Account_number: [''],  
      Beneficiary_Address: ['', [Validators.required]],  
      // Ifsc: ['', [Validators.required]],
      Ifsc: [''],  
      Swift : ['',],  
      Bank_Name : ['', [Validators.required]],        
    }); 

   }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('user_details'));
    this.getAccountLogin();

    

  }

  
  getAccountLogin(){
    // console.log( this.getDemoLive );
    // console.log( 'getAccountLogin' );
    this.commonService.showLoader = 1;

    let input = {
      //token:localStorage.getItem('auth_token'),                       
      uK:  this.userDetails.user_key,
      _dL: 2,   
      _ty: this.userDetails.user_type,
    };    

    this.tradeApiService.ApiServices(this.urlGetMyAccountLogin, input, null).subscribe((HistoryDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (HistoryDetails.status == 200) {
        console.log( HistoryDetails.logins );
        this.myHLoginsDetails =  HistoryDetails.logins;   
      }
      else {
        const errorValues = Object.keys(HistoryDetails.message).map(key => HistoryDetails.message[key]);
        for(var i=0; i<errorValues.length;i++){                                  
          this.commonService.showToaster( errorValues[i] , 4 ); 
        }
      }

      this.commonService.showLoader = 0;

    });

  }

      get fv() { return this.withdrawalUpdateForm.controls; }
  

  withdrawalRequest() {

  this.submittedW = true;

  // stop here if form is invalid
  if (this.withdrawalUpdateForm.invalid) {
      return;
  }

  let input = {                        
    uK:this.userDetails.user_key,                       
  };  
  
  this.commonService.showLoader = 1;
  
  let userInput = Object.assign(input, this.withdrawalUpdateForm.value);

  console.log( userInput );


  this.tradeApiService.ApiServices(this.urSendWithDrawal, userInput , null).subscribe(( changePassword:any ) => {
    // do stuff with our data here.
    // ....

    // asign data to our class property in the end
    // so it will be available to our template                  
        if(changePassword.status == 200){  
          this.commonService.showToaster( changePassword.message , 1 );   
          this.clearForm();
        }
        else{                    
          const errorValues = Object.keys(changePassword.message).map(key => changePassword.message[key]);
          for(var i=0; i<errorValues.length;i++){                                  
            this.commonService.showToaster( errorValues[i] , 4 ); 
          }
        }

        this.commonService.showLoader = 0;
        
    });


}


/**
 * Clearing the form data for change password
 */

clearForm(){

  this.submittedW = false;
  this.withdrawalUpdateForm.clearValidators();  
  this.withdrawalUpdateForm.reset();  
  this.withdrawalUpdateForm.markAsPristine(); 
  this.withdrawalUpdateForm.controls['login'].setValue('');

}

//for Decimal you can use this as

onlyDecimalNumberKey(event) {
  let charCode = (event.which) ? event.which : event.keyCode;
  if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
  return true;
}

}
