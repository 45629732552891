import { Component, OnInit } from '@angular/core';

import { CommonService } from '../../../common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-guest-layout',
  templateUrl: './guest-layout.component.html',
  styleUrls: ['./guest-layout.component.scss']
})
export class GuestLayoutComponent implements OnInit {
  getLanguage = '';
  constructor(private commonService: CommonService, private route: ActivatedRoute, private router: Router, private translate: TranslateService) { 
    translate.setDefaultLang(this.commonService.defaultLanguale);
    this.getLanguage = this.commonService.defaultLanguale;
  }

  ngOnInit() {    
   
    this.route.queryParams.subscribe(params => {    
      if( params.h ){        
        this.commonService.showToaster( params.h , 3);
      }
    
  });    
    var auth = localStorage.getItem('auth_token');   
    if( auth == '' || auth == null){      
      // console.log(this.router.url); //  /routename
      if( this.router.url == '/register' ){    
        this.commonService.navigateToUrls('/register');
      } 
      else{
        this.commonService.navigateToUrls('/login');
      }      
    }else{      
        this.commonService.moveToPageReload();
    }
    
  }

}
