import { Component, OnInit } from '@angular/core';

import { CommonService } from '../../../common.service';
import { ApiTradeService } from '../../../api-trade.service';
import { TranslateService } from '@ngx-translate/core';

import { AuthorisedLayoutComponent } from '../authorised-layout/authorised-layout.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-authorised-side-nav',
    templateUrl: './authorised-side-nav.component.html',
    styleUrls: ['./authorised-side-nav.component.scss']
})
export class AuthorisedSideNavComponent implements OnInit {

    userType = 0;
    logout = 0;
    userTypeKey = '';
    depositMenu = 0;

    constructor(public commonService: CommonService, public tradeApiService: ApiTradeService, private translate: TranslateService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent, private router: Router) {
        translate.setDefaultLang(this.commonService.defaultLanguale);
        this.commonService.checkforAuth();
        var userDetails = JSON.parse(localStorage.getItem('user_details'));
        this.userTypeKey = userDetails.user_role;
        this.userType = userDetails.user_type;

        // console.log(userDetails.kyc_status);

        this.depositMenu = userDetails.kyc_status;
    }

    getMenuPermission(module_key, mTy) {
        
       
        var userDetails = JSON.parse(localStorage.getItem('user_details'));

        if (userDetails.user_type == 1) {
            return 1;
        }
        
        var p = 0;
        var skinName = this.tradeApiService.permissions.find(x => x.module_key == module_key);
        if( skinName !== undefined ){
            if (skinName.module_key == module_key) {
                if (mTy == 1) { // Main Menu
                    if (skinName.view == 1) {
                        p = 1;
                    }
                }
                else { // Sub Menu
                    if (skinName.manage == 1) {
                        p = 1;
                    }
                }
            }
        }     

        return p;
    }

    ngOnInit() {        
    }

    routeActiveClass(menu, isSingel = 0) {
      
        var userDetails = JSON.parse(localStorage.getItem('user_details'));


        if (userDetails.user_type == 1) {
            var routerName = this.AuthorisedLayoutComponent.routerName.find(x => x.id == this.router.url);
        }
        if (userDetails.user_type == 2) {
            var routerName = this.AuthorisedLayoutComponent.routerNameClient.find(x => x.id == this.router.url);
        }
        if (userDetails.user_type == 3) {
            var routerName = this.AuthorisedLayoutComponent.routerNameKyc.find(x => x.id == this.router.url);
        }
        if (routerName.mainMenuKey == menu) {
            return 'active';
        }
        if ((isSingel == 1) && (menu == routerName.moduleKey)) {
            return 'active';
        }

    }
    routeActiveClassNavigation(menu) {

        var userDetails = JSON.parse(localStorage.getItem('user_details'));


        if (userDetails.user_type == 1) {
            var routerName = this.AuthorisedLayoutComponent.routerName.find(x => x.id == this.router.url);
        }
        if (userDetails.user_type == 2) {
            var routerName = this.AuthorisedLayoutComponent.routerNameClient.find(x => x.id == this.router.url);
        }
        if (userDetails.user_type == 3) {
            var routerName = this.AuthorisedLayoutComponent.routerNameKyc.find(x => x.id == this.router.url);
        }
        //38dd759bf46d61011ab1

        if (routerName.mainMenuKey == menu) {
            return 'navigation-active';
        }

    }

    checkDiv(event = '') {
        this.commonService.checkDiv( event );
    }

    sideMenuToggler(){

        var navigation = document.getElementsByClassName('navigation');
                
        for (var i = 0; i < navigation.length; i++) {

            var current = document.getElementsByClassName('open');

            // If there's no active class
            if (current.length > 0) {
                current[0].className = current[0].className.replace(' open', '');
            }

        }             

        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('no-scroll');
    

        var list = document.getElementsByClassName('overlay');
            for(var i = list.length - 1; 0 <= i; i--)
            if(list[i] && list[i].parentElement)
            list[i].parentElement.removeChild(list[i]);
            
    }

}
