import { Component, OnInit } from '@angular/core';

import { CommonService } from '../../common.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor( private commonService: CommonService) { }

  ngOnInit() {    
    this.commonService.logout();
  }

}
