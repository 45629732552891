import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';

import Swal from 'sweetalert2';

import {TranslateService} from '@ngx-translate/core';

import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';


@Component({
  selector: 'app-bo-demo-trade-history-details',
  templateUrl: './bo-demo-trade-history-details.component.html',
  styleUrls: ['./bo-demo-trade-history-details.component.scss']
})
export class BoDemoTradeHistoryDetailsComponent implements OnInit {

  groups = [];
  logins = [];

  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  @ViewChild('instanceLogins', {static: true}) instanceLogins: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  focusL$ = new Subject<string>();
  clickL$ = new Subject<string>();

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.groups
        : this.groups.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 100))
    );
  }

   searchLogins = (text$: Observable<string>) => {
    const debouncedTextL$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopupL$ = this.clickL$.pipe(filter(() => !this.instanceLogins.isPopupOpen()));
    const inputFocusL$ = this.focusL$;

    return merge(debouncedTextL$, inputFocusL$, clicksWithClosedPopupL$).pipe(
      map(term => (term === '' ? this.logins
        : this.logins.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 100))
    );
  }

  

  getHistoryMt5Login = '';
  getHistoryStartDate;
  getHistoryEndDate;
  getDemoLive = 1;

  getHistoryMt5LoginErr = 0;
  getHistoryStartDateErr = 0;
  getHistoryEndDateErr = 0;
  getDemoLiveErr = 1;

  userDetails;

  page = 1;
  totalItems = 0;
  itemsPerPage = 10;

  urlGetMyHistoryDetails = 'my-History-details';
  // urlGetMyAccountLogin = 'my-account-login-details';
  myHistoryDetails;
  myHLoginsDetails;

  urlGetMyAccountLogin = 'bo-account-login-details';
  urlGetGroupAccount = 'bo-account-group-details';

  getGroupDetails;


  constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private translate: TranslateService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) { 
    this.AuthorisedLayoutComponent.getRouteAccess();
    translate.setDefaultLang( this.commonService.defaultLanguale );
   }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('user_details'));
    this.getLiveGroupDetail(); 
  }

  // --- 

  getLiveGroupDetail( ){  
    this.commonService.showLoader = 1;

    let input = {                            
      uK:  this.userDetails.user_key,
      _dL: 1,       
      _ty: this.userDetails.user_type,  
    };

    this.tradeApiService.ApiServices(this.urlGetGroupAccount, input, null).subscribe((GroupDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (GroupDetails.status == 200) {
        // console.log( GroupDetails.logins );
        this.groups =  GroupDetails._grp;   
        this.myHLoginsDetails =  GroupDetails._grpLogins;   
        this.logins =  GroupDetails._grpLogins;   
      }
      else {
        const errorValues = Object.keys(GroupDetails.message).map(key => GroupDetails.message[key]);
        for(var i=0; i<errorValues.length;i++){                                  
          this.commonService.showToaster( errorValues[i] , 4 ); 
        }
      }

      this.commonService.showLoader = 0;

    });

  }

  selectedItem(item){
        // console.log(item);
        this.getHistoryMt5Login = '';
        this.getAccountLogin(item.item);
  }

  
  getAccountLogin( groupName ){  
    this.commonService.showLoader = 1;

    let input = {
      //token:localStorage.getItem('auth_token'),                       
      uK:  this.userDetails.user_key,
      _dL: 1, 
      _LGrp: groupName, 
      _ty: this.userDetails.user_type,  
    };

    this.tradeApiService.ApiServices(this.urlGetMyAccountLogin, input, null).subscribe((GroupDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (GroupDetails.status == 200) {
        // console.log( GroupDetails.logins );
        this.myHLoginsDetails =  GroupDetails._grpLogins;   
        this.logins =  GroupDetails._grpLogins;   
      }
      else {
        const errorValues = Object.keys(GroupDetails.message).map(key => GroupDetails.message[key]);
        for(var i=0; i<errorValues.length;i++){                                  
          this.commonService.showToaster( errorValues[i] , 4 ); 
        }
      }

      this.commonService.showLoader = 0;

    });
  }

  getMyHistoryDetails(){
    
      this.getHistoryMt5LoginErr = 0;
      this.getHistoryStartDateErr = 0;
      this.getHistoryEndDateErr = 0;
      this.getDemoLiveErr = 0;


    let err = 0;
    if( this.getHistoryStartDate === undefined && this.getHistoryEndDate === undefined && this.getHistoryMt5Login == '' && this.getDemoLive == 0){
      this.getHistoryMt5LoginErr = 1;
      this.getHistoryStartDateErr = 1;
      this.getHistoryEndDateErr = 1;
      this.getDemoLiveErr = 1;
      err = 1;
    }

    if( this.getHistoryStartDate === undefined || this.getHistoryStartDate === null ){
      this.getHistoryStartDateErr = 1;
      err = 1;
    }
    if( this.getHistoryEndDate === undefined || this.getHistoryEndDate === null ){
      this.getHistoryEndDateErr = 1;
      err = 1;
    }
    if( this.getHistoryMt5Login == '' ){
      this.getHistoryMt5LoginErr = 1;
      err = 1;
    }
    if( this.getDemoLive == 0 ){
      this.getDemoLiveErr = 1;
      err = 1;
    }

   if( err == 0){        
        var getHistoryStartDate = this.getHistoryStartDate.year+'-'+this.getHistoryStartDate.month+'-'+this.getHistoryStartDate.day;

        var getHistoryEndDate = this.getHistoryEndDate.year+'-'+this.getHistoryEndDate.month+'-'+this.getHistoryEndDate.day;
      
        // var getHistoryEndDate = new Date( '2019-10-30' ); // pass all the parameters you need to create the time
        if (new Date( getHistoryStartDate ).getTime() < new Date( getHistoryEndDate ).getTime() ) {
     
          this.getMyHistoryDetailsFromMt5();
      

        }
        else if (new Date( getHistoryStartDate ).getTime() == new Date( getHistoryEndDate ).getTime() ) {
        
          this.getMyHistoryDetailsFromMt5();
        }
        else{      
          this.getHistoryEndDateErr = 2;
        }
   }
  }

  dateFormates( dateObject ){
    let d = new Date(dateObject);
    let day:any = d.getDate();
    let month:any = d.getMonth() + 1;
    let year = d.getFullYear();
    if (day < 10) {
        day = "0" + day;
    }
    if (month < 10) {
        month = "0" + month;
    }
    let date = year + "-" + month + "-" + day;

    return date;
  }

  getMyHistoryDetailsFromMt5(){
    // console.log( 'getMyHistoryDetailsFromMt5' );
    this.commonService.showLoader = 1;

    var getHistoryStartDate = this.getHistoryStartDate.year+'-'+this.getHistoryStartDate.month+'-'+this.getHistoryStartDate.day;

    var getHistoryEndDate = this.getHistoryEndDate.year+'-'+this.getHistoryEndDate.month+'-'+this.getHistoryEndDate.day;
  


    let s = this.dateFormates( new Date( getHistoryStartDate ).getTime())
    let e = this.dateFormates( new Date( getHistoryEndDate ).getTime())


    let input = {
      //token:localStorage.getItem('auth_token'),                       
      uK:  this.userDetails.user_key,
      _dL: this.getHistoryMt5Login,
      _sD: s,
      _eD: e,
      _p: this.page,
      _dA: this.getDemoLive,
      _ty: this.userDetails.user_type,
    };

    this.tradeApiService.ApiServices(this.urlGetMyHistoryDetails, input, null).subscribe((HistoryDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (HistoryDetails.status == 200) {
        console.log( HistoryDetails.HistoryDetails );    
        this.myHistoryDetails = HistoryDetails.historyDetails;
        this.totalItems = HistoryDetails.total;
      }
      else {
        const errorValues = Object.keys(HistoryDetails.message).map(key => HistoryDetails.message[key]);
        for(var i=0; i<errorValues.length;i++){                                  
          this.commonService.showToaster( errorValues[i] , 4 ); 
        }
      }

      this.commonService.showLoader = 0;

    });


  }

  loadPage(page: number) {
    // console.log( page );
    this.page = page;
    this.getMyHistoryDetailsFromMt5();
  }

  getAccountLogin1(){
    // console.log( this.getDemoLive );
    // console.log( 'getAccountLogin' );
    this.commonService.showLoader = 1;

    let input = {
      //token:localStorage.getItem('auth_token'),                       
      uK:  this.userDetails.user_key,
      _dL: this.getDemoLive,   
      _ty: this.userDetails.user_type,
    };

    this.tradeApiService.ApiServices(this.urlGetMyAccountLogin, input, null).subscribe((HistoryDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (HistoryDetails.status == 200) {
        console.log( HistoryDetails.logins );
        this.myHLoginsDetails =  HistoryDetails.logins;   
      }
      else {
        const errorValues = Object.keys(HistoryDetails.message).map(key => HistoryDetails.message[key]);
        for(var i=0; i<errorValues.length;i++){                                  
          this.commonService.showToaster( errorValues[i] , 4 ); 
        }
      }

      this.commonService.showLoader = 0;

    });

  }

}
