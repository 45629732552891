import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})

export class CityComponent implements OnInit {

    userDetails;
    urlGetCityDetails = 'get-city-lists';
    urlAddEditCity = 'add-edit-city';
    urlBlockUnblockCity = 'block-unblock-city';
    urlGetIndividualCity = 'get-individual-city-details';
    cityLists; //for all city lists
    countryList; //for all country lists
    closeResult: string;
    cityData; //for block & unblock
    individualCityData; //individual city details stored for edit city modal
    title; //Modal Popup header Title
    buttonName; //Modal Popup Button Name
    blockUnblockTitle;

    addEditCityForm: FormGroup;
    addEditSubmitted = false;

    selectCountry = true;

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    searchInputData = '';
    searchCK = '';

    @ViewChild('contentAddNewCity', {static: false}) private contentAddNewCity: ElementRef;
    @ViewChild('blockUnblockCity', {static: false}) private blockUnblockCity: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) { 
        this.AuthorisedLayoutComponent.getRouteAccess();
    }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(mTy) {
        return this.commonService.getMenuPermission('d59cd8a93d5bd22e9ef7', mTy);
    }

    ngOnInit() {

        this.getCityLists();

        this.userDetails = JSON.parse( localStorage.getItem('user_details'));

        this.addEditCityForm = this.formBuilder.group({
            cityName: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
            cK: ['', [Validators.required]], //Country Key
            ciK: ['', []], //City Key
        });
    }

    open(content, sizeS, modalTitleID){
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    /**
     * Fecting City Lists
     */

    getCityLists() {

        this.commonService.showLoader = 1;

        let input = {
            page: this.page,
            _sDl: this.searchInputData,
            _sCK: this.searchCK,
        };

        this.tradeApiService.ApiServices(this.urlGetCityDetails, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.cityLists = responseData.cityLists.Records;
                this.totalItems = responseData.cityLists.TotalCount;
                this.countryList = responseData.countryList;
            }
            else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Load pagination next page
     */

    loadPage(page: number) {   
        this.page = page;
        this.getCityLists();
    }

    /**
     * Add City Modal Popup
     */

    addNewCityModal() {
        this.resetForm();
        this.open(this.contentAddNewCity, 'x', 'modal-basic-title');
        this.title = 'Add New';
        this.buttonName = 'Add';
        this.selectCountry = true;
        this.addEditCityForm.controls['cK'].setValue('');
    }

    /**
     * Reset add City form values and error messages
     */

    resetForm() {
        this.addEditSubmitted = false;
        this.addEditCityForm.clearValidators();
        this.addEditCityForm.reset();
        this.addEditCityForm.markAsPristine()
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
    }

    /**
     * Angular error message form control
     */

    get f() { return this.addEditCityForm.controls; } //Add City Error Controller

    /**
     * Add & Edit City form Submit
     */

    addEditCitySubmit() {
        this.addEditSubmitted = true;

        // stop here if form is invalid
        if (this.addEditCityForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.addEditCityForm.value);

        this.tradeApiService.ApiServices(this.urlAddEditCity, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.getCityLists();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for(var i=0; i<errorValues.length;i++){                                  
                    this.commonService.showToaster( errorValues[i] , 4 ); 
                }  
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Block & Unblock City Modal Popup
     */

    blockUnblock(data) {
        this.cityData = data;
        
        this.blockUnblockTitle = (data.city_status == 0) ? 'Unblock' : 'Block';

        this.open(this.blockUnblockCity, 'x', 'modal-blockunblock-title');
    }

    confirmBlockUnblock() {
        
        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
          cK: this.cityData.country_key,
          ciK: this.cityData.city_key,
          cS: this.cityData.city_status,
        };

        let userInput = Object.assign(input);

        this.tradeApiService.ApiServices(this.urlBlockUnblockCity, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.cityData = null;
                this.getCityLists();
            } else {
                this.commonService.showToaster( responseData.message , 4 );
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Get Individual City Detail
     */

    getIndividualCity(data) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            cK: data.country_key,
            ciK: data.city_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualCity, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.open(this.contentAddNewCity, 'x', 'modal-basic-title');
                this.title = 'Edit';
                this.buttonName = 'Update';

                this.individualCityData = responseData.cD;

                this.addEditCityForm.controls['ciK'].setValue(data.city_key);
                this.addEditCityForm.controls['cK'].setValue(data.country_key);
                this.addEditCityForm.controls['cityName'].setValue(this.individualCityData.nickname);

                this.selectCountry = false;
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Clear Search Data's
     */

    clearSearchData() {
        this.searchInputData = '';
        this.searchCK = '';
        this.getCityLists();
    }
}
