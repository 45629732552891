import { Component, OnInit, EventEmitter, Output, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications
import { HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';
import { DOCUMENT } from '@angular/common';

import { AppComponent } from '../../app.component';
import { from } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit {

    userDetails;

    settingsForm: FormGroup;

    testEmailForm: FormGroup;
    testEmailSubmitted = false;
    showTestEmailResponse = false;
    testEmailresponse = '';

    testSMSForm: FormGroup;
    testMobileSubmitted = false;
    showTestSMSResponse = false;
    testSMSresponse = '';

    generalSettingsSubmitted = false;
    additionalSettingsSubmitted = false;
    smtpSettingsSubmitted = false;
    smsSettingsSubmitted = false;
    gSettingsSubmitted = false;
    uploadSettingsSubmitted = false;
    deleteSettingsSubmitted = false;
    downloadSettingsSubmitted = false;

    urlGetSettingsData = 'get-settings-data';
    urlUpdateGeneralSettings = 'update-general-settings';
    urlUpdateAdditionalSettings = 'update-additional-settings';
    urlUpdateSmtpSettings = 'update-smtp-settings';
    urlUpdateSmsSettings = 'update-sms-settings';
    urlUpdateGSettings = 'update-g-settings';
    urlUpdateUploadSettings = 'update-upload-settings';
    urlUpdateDeleteSettings = 'update-delete-settings';
    urlUpdateDownloadSettings = 'update-download-settings';
    urlTestEmail = 'test-email';
    urlTestSMS = 'test-sms';
    urlThemeColor = 'theme-color';

    PreviewLogo: string;
    PreviewFavicon: string;

    uploadLogo: string;
    uploadFavicon: string;
    closeResult: string;

    counts = [];
    language = [];
    country = [];




    /* COLORS */
    colorPrimary: string = '#BF9B5C';
    colorSecondary: string = '#6a6e92';
    colorInfo: string = '#55a6eb';
    colorSuccess: string = '#0abb87';
    colorWarning: string = '#ffb822';
    colorDanger: string = '#e04b4b';
    colorDark: string = '#000000';
    colorLight: string = '#f2f2f2';

    colorPrimaryHover: string = '#ad8643';
    colorSecondaryHover: string = '#4c5074';
    colorInfoHover: string = '#3788cd';
    colorSuccessHover: string = '#009d69';
    colorWarningHover: string = '#e19a04';
    colorDangerHover: string = '#c22d2d';
    colorDarkHover: string = '#06050a';
    colorLightHover: string = '#c4c4c4';

    logoSizeForDesktop: string = "250px";
    logoSizeForMobileAndTable: string = "85px";


    @ViewChild('contentTestMailModal', { static: false }) private contentTestMailModal: ElementRef;
    @ViewChild('contentTestSMSModal', { static: false }) private contentTestSMSModal: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, @Inject(DOCUMENT) private _document: HTMLDocument, private AppComponent: AppComponent, private translate: TranslateService) { }

    getMenuPermission(module_key, mTy) {
        return this.commonService.getMenuPermission(module_key, mTy);
    }

    open(content, sizeS, modalTitleID) {
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnInit() {

        this.userDetails = JSON.parse(localStorage.getItem('user_details'));

        for (let i = 1; i <= 10; i++) {
            let obj = {
                value: i.toString()
            };
            this.counts.push(obj);
        }

        this.getSettingsData();

        this.settingsForm = this.formBuilder.group({
            generalSettingsForm: this.formBuilder.group({
                siteName: ['', [Validators.required]],
                contactName: ['', [Validators.required]],
                contactEmail: ['', [Validators.required, Validators.email]],
                contactTelephone: ['', [Validators.required, Validators.pattern('^[0-9-() \-\']+')]],
                contactPhone: ['', [Validators.required, Validators.pattern('^[0-9\-\']+')]],
                address: ['', [Validators.required]],
                logo: [''],
                favicon: [''],
                enableLanguage: ['0'],
                defaultLanguage: ['', [Validators.required]],
                defaultCountry: ['', []],
                appUrl: ['', [Validators.required]],
                termsPage: [''],
            }),
            additionalSettingsForm: this.formBuilder.group({
                subscriptionSetting: ['', [Validators.required]],
                chartsPosition: ['', [Validators.required]],
                chartsHistory: ['', [Validators.required]],
                directPaymentDeposit: ['', [Validators.required]],
                subscriptionAdditionalMail: ['', [Validators.required]],
                documentAdditionalMail: ['', [Validators.required]],
                documentShow: ['', [Validators.required]],
                securityShow: ['', [Validators.required]],
            }),
            smtpSettingsForm: this.formBuilder.group({
                emailSetting: ['', [Validators.required]],
                mailToken: ['', [Validators.required]],
                mailUrl: ['', [Validators.required]],
                senderEmailId: ['', [Validators.required, Validators.email]],
                serverName: ['', [Validators.required]],
                contactPage: ['', [Validators.required]],
                downloadLink: ['', [Validators.required]]
            }),
            smsSettingsForm: this.formBuilder.group({
                smsSetting: ['', [Validators.required]],
                smsToken: ['', [Validators.required]],
                smsUrl: ['', [Validators.required]],
                senderId: ['', [Validators.required]]
            }),
            gSettingsForm: this.formBuilder.group({
                demoAccountAllowedCount: ['', [Validators.required]],
                liveAccountAllowedCount: ['', [Validators.required]],
                serialNumberFrom: ['', [Validators.required, Validators.pattern('^[0-9\-\']+')]],
                serialNumberTo: ['', [Validators.required, Validators.pattern('^[0-9\-\']+')]],
                passwordStrength: ['', [Validators.required]],
                updateClientEmailMobile: ['', [Validators.required]],
            }),
            uploadSettingsForm: this.formBuilder.group({
                uploadSetting: ['', [Validators.required]],
                uploadToken: ['', [Validators.required]],
                uploadUrl: ['', [Validators.required]]
            }),
            deleteSettingsForm: this.formBuilder.group({
                deleteSetting: ['', [Validators.required]],
                deleteToken: ['', [Validators.required]],
                deleteUrl: ['', [Validators.required]]
            }),
            downloadSettingsForm: this.formBuilder.group({
                downloadSetting: ['', [Validators.required]],
                downloadToken: ['', [Validators.required]],
                downloadUrl: ['', [Validators.required]]
            }),
        });

        this.testEmailForm = this.formBuilder.group({
            testEmail: ['', [Validators.required, Validators.pattern(/^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
        });
        this.testSMSForm = this.formBuilder.group({
            testMobile: ['', [Validators.required, Validators.required, Validators.pattern('^[0-9\']+')]],
        });
    }

    /**
     * Fecting the Settings Details
     */

    getSettingsData() {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
        };

        this.tradeApiService.ApiServices(this.urlGetSettingsData, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {

                var datas = responseData.settingsData;

                console.log(datas.color_scheme)

                /** Color Theme Value Update */

                    this.colorPrimary =     datas.color_scheme.colorPrimary
                    this.colorSecondary =   datas.color_scheme.colorSecondary
                    this.colorInfo =    datas.color_scheme.colorInfo
                    this.colorSuccess = datas.color_scheme.colorSuccess
                    this.colorWarning = datas.color_scheme.colorWarning
                    this.colorDanger =  datas.color_scheme.colorDanger
                    this.colorDark =    datas.color_scheme.colorDark
                    this.colorLight =   datas.color_scheme.colorLight
                    this.colorPrimaryHover =    datas.color_scheme.colorPrimaryHover
                    this.colorSecondaryHover =  datas.color_scheme.colorSecondaryHover
                    this.colorInfoHover =   datas.color_scheme.colorInfoHover
                    this.colorSuccessHover =    datas.color_scheme.colorSuccessHover
                    this.colorWarningHover =    datas.color_scheme.colorWarningHover
                    this.colorDangerHover = datas.color_scheme.colorDangerHover
                    this.colorDarkHover =   datas.color_scheme.colorDarkHover
                    this.colorLightHover =  datas.color_scheme.colorLightHover

                    /** End Color Update */

                    this.PreviewLogo = datas.logo_url;
                this.PreviewFavicon = datas.favicon_url;

                this.commonService.siteLogoImg = this.PreviewLogo;
                this.commonService.favIconImg = this.PreviewFavicon;
                this._document.getElementById('appFavicon').setAttribute('href', this.PreviewFavicon);
                this.AppComponent.setTitle(datas.site_name);

                this.language = responseData.LanguageDetails;
                this.country = responseData.CountryDetails;

                this.settingsForm.controls.generalSettingsForm.setValue({
                    siteName: datas.site_name,
                    contactName: datas.contact_name,
                    contactEmail: datas.contact_email,
                    contactPhone: datas.contact_phone,
                    contactTelephone: datas.contact_telephone,
                    address: datas.contact_address,
                    logo: '',
                    favicon: '',
                    enableLanguage: datas.enable_language.toString(),
                    defaultLanguage: datas.default_language,
                    defaultCountry: datas.default_country,
                    appUrl: datas.app_url,
                    termsPage:datas.terms_page
                });

                this.commonService.enableLanguale = datas.enable_language;

                this.settingsForm.controls.additionalSettingsForm.setValue({
                    subscriptionSetting: datas.subscription_setting,
                    subscriptionAdditionalMail: datas.subscription_additional_mail,
                    documentAdditionalMail: datas.document_additional_mail,
                    chartsPosition: datas.charts_position.toString(),
                    chartsHistory: datas.charts_history.toString(),
                    directPaymentDeposit: datas.direct_payment_deposit.toString(),
                    documentShow: datas.doc_show.toString(),
                    securityShow: datas.security_question_show.toString(),
                });

                this.settingsForm.controls.smtpSettingsForm.setValue({
                    emailSetting: datas.smtp_setting,
                    mailToken: datas.mail_token,
                    mailUrl: datas.mail_url,
                    senderEmailId: datas.sender_email,
                    serverName: datas.server_name,
                    contactPage: datas.contact_page,
                    downloadLink: datas.download_link
                });

                this.settingsForm.controls.smsSettingsForm.setValue({
                    smsSetting: datas.sms_setting,
                    smsToken: datas.sms_token,
                    smsUrl: datas.sms_url,
                    senderId: datas.sms_sender_id,
                }),

                this.settingsForm.controls.gSettingsForm.setValue({
                    demoAccountAllowedCount: datas.demo_account_allowed_count,
                    liveAccountAllowedCount: datas.live_account_allowed_count,
                    serialNumberFrom: datas.series_number_from,
                    serialNumberTo: datas.series_number_to,
                    passwordStrength: datas.password_strength,
                    updateClientEmailMobile: datas.update_email_mobile_setting,
                });

                this.settingsForm.controls.uploadSettingsForm.setValue({
                    uploadSetting: datas.upload_setting,
                    uploadToken: datas.upload_token,
                    uploadUrl: datas.upload_url,
                });

                this.settingsForm.controls.deleteSettingsForm.setValue({
                    deleteSetting: datas.delete_setting,
                    deleteToken: datas.delete_token,
                    deleteUrl: datas.delete_url,
                });

                this.settingsForm.controls.downloadSettingsForm.setValue({
                    downloadSetting: datas.download_setting,
                    downloadToken: datas.download_token,
                    downloadUrl: datas.download_url,
                });

            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    // convenience getter for easy access to form fields

    get gSE() { return (<FormGroup>this.settingsForm.get('generalSettingsForm')).controls; }
    get aSE() { return (<FormGroup>this.settingsForm.get('additionalSettingsForm')).controls; }
    get mSE() { return (<FormGroup>this.settingsForm.get('smtpSettingsForm')).controls; }
    get sSE() { return (<FormGroup>this.settingsForm.get('smsSettingsForm')).controls; }
    get geSE() { return (<FormGroup>this.settingsForm.get('gSettingsForm')).controls; }
    get uSE() { return (<FormGroup>this.settingsForm.get('uploadSettingsForm')).controls; }
    get dSE() { return (<FormGroup>this.settingsForm.get('deleteSettingsForm')).controls; }
    get doSE() { return (<FormGroup>this.settingsForm.get('downloadSettingsForm')).controls; }

    get generalSettingsForm() {
        return this.settingsForm.get('generalSettingsForm');
    }

    get additionalSettingsForm() {
        return this.settingsForm.get('additionalSettingsForm');
    }

    get smtpSettingsForm() {
        return this.settingsForm.get('smtpSettingsForm');
    }

    get smsSettingsForm() {
        return this.settingsForm.get('smsSettingsForm');
    }

    get gSettingsForm() {
        return this.settingsForm.get('gSettingsForm');
    }

    get uploadSettingsForm() {
        return this.settingsForm.get('uploadSettingsForm');
    }

    get deleteSettingsForm() {
        return this.settingsForm.get('deleteSettingsForm');
    }

    get downloadSettingsForm() {
        return this.settingsForm.get('downloadSettingsForm');
    }

    // image validaions

    readLogoURL(event: any) {
        if (event.target.files && event.target.files[0]) {
            var size = event.target.files[0].size;
            var type = event.target.files[0].type;

            if (Math.round(size / 1024) > 2000) {
                this.toastr.info('File exceeds the maximum size 2 Mb!', 'Info!');
                return false;
            }

            if (type == 'image/jpeg' || type == 'image/png') {
                var reader = new FileReader();

                reader.onload = (event: any) => {
                    this.PreviewLogo = event.target.result; // Instance preview image
                    this.uploadLogo = event.target.result; // Store the image base64 value for form post
                };
                reader.readAsDataURL(event.target.files[0]);
            } else {
                this.toastr.info('Upload only PNG / JPG!', 'Info!!');
                return false;
            }
        }
    }

    readFaviconURL(event: any) {
        if (event.target.files && event.target.files[0]) {
            var size = event.target.files[0].size;
            var type = event.target.files[0].type;

            if (Math.round(size / 1024) > 2000) {
                this.toastr.info('File exceeds the maximum size 2 Mb!', 'Info!');
                return false;
            }

            if (type == 'image/jpeg' || type == 'image/png') {
                var reader = new FileReader();

                reader.onload = (event: any) => {
                    this.PreviewFavicon = event.target.result; // Instance preview image
                    this.uploadFavicon = event.target.result; // Store the image base64 value for form post
                };
                reader.readAsDataURL(event.target.files[0]);
            } else {
                this.toastr.info('Upload only PNG / JPG!', 'Info!!');
                return false;
            }
        }
    }

    /**
     * Update General Settings Data``
     */

    generalFormSubmit() {

        this.generalSettingsSubmitted = true;

        // stop here if form is invalid
        if (this.settingsForm.controls.generalSettingsForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            uploadLogo: this.uploadLogo,
            uploadFavicon: this.uploadFavicon,
        };

        let userInput = Object.assign(input, this.settingsForm.controls.generalSettingsForm.value);

        this.tradeApiService.ApiServices(this.urlUpdateGeneralSettings, userInput, null).subscribe((responseData: any) => {
            if (responseData.status == 200) {
                this.commonService.showToaster(responseData.message, 1);
                this.getSettingsData();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Update Additional Settings Data
     */

    additionalFormSubmit() {

        this.additionalSettingsSubmitted = true;

        // stop here if form is invalid
        if (this.settingsForm.controls.additionalSettingsForm.invalid) {
            return;
        }
        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.settingsForm.controls.additionalSettingsForm.value);

        this.tradeApiService.ApiServices(this.urlUpdateAdditionalSettings, userInput, null).subscribe((responseData: any) => {
            if (responseData.status == 200) {
                this.commonService.showToaster(responseData.message, 1);
                this.getSettingsData();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Update SMTP Settings Data
     */

    smtpFormSubmit() {

        this.smtpSettingsSubmitted = true;

        // stop here if form is invalid
        if (this.settingsForm.controls.smtpSettingsForm.invalid) {
            return;
        }
        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.settingsForm.controls.smtpSettingsForm.value);

        this.tradeApiService.ApiServices(this.urlUpdateSmtpSettings, userInput, null).subscribe((responseData: any) => {
            if (responseData.status == 200) {
                this.commonService.showToaster(responseData.message, 1);
                this.getSettingsData();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Update SMS Settings Data
     */

    smsFormSubmit() {

        this.smsSettingsSubmitted = true;

        // stop here if form is invalid
        if (this.settingsForm.controls.smsSettingsForm.invalid) {
            return;
        }
        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.settingsForm.controls.smsSettingsForm.value);

        this.tradeApiService.ApiServices(this.urlUpdateSmsSettings, userInput, null).subscribe((responseData: any) => {
            if (responseData.status == 200) {
                this.commonService.showToaster(responseData.message, 1);
                this.getSettingsData();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Update SMS Settings Data
     */

    gFormSubmit() {

        this.gSettingsSubmitted = true;

        // stop here if form is invalid
        if (this.settingsForm.controls.gSettingsForm.invalid) {
            return;
        }
        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.settingsForm.controls.gSettingsForm.value);

        this.tradeApiService.ApiServices(this.urlUpdateGSettings, userInput, null).subscribe((responseData: any) => {
            if (responseData.status == 200) {
                this.commonService.showToaster(responseData.message, 1);
                this.getSettingsData();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Update s3 Upload Settings Data
     */

    uploadFormSubmit() {

        this.uploadSettingsSubmitted = true;

        // stop here if form is invalid
        if (this.settingsForm.controls.uploadSettingsForm.invalid) {
            return;
        }
        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.settingsForm.controls.uploadSettingsForm.value);

        this.tradeApiService.ApiServices(this.urlUpdateUploadSettings, userInput, null).subscribe((responseData: any) => {
            if (responseData.status == 200) {
                this.commonService.showToaster(responseData.message, 1);
                this.getSettingsData();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Update s3 Delete Settings Data
     */

    deleteFormSubmit() {

        this.deleteSettingsSubmitted = true;

        // stop here if form is invalid
        if (this.settingsForm.controls.deleteSettingsForm.invalid) {
            return;
        }
        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.settingsForm.controls.deleteSettingsForm.value);

        this.tradeApiService.ApiServices(this.urlUpdateDeleteSettings, userInput, null).subscribe((responseData: any) => {
            if (responseData.status == 200) {
                this.commonService.showToaster(responseData.message, 1);
                this.getSettingsData();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Update s3 Download Settings Data
     */

    downloadFormSubmit() {

        this.downloadSettingsSubmitted = true;

        // stop here if form is invalid
        if (this.settingsForm.controls.downloadSettingsForm.invalid) {
            return;
        }
        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.settingsForm.controls.downloadSettingsForm.value);

        this.tradeApiService.ApiServices(this.urlUpdateDownloadSettings, userInput, null).subscribe((responseData: any) => {
            if (responseData.status == 200) {
                this.commonService.showToaster(responseData.message, 1);
                this.getSettingsData();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Trigger test email modal popup
     */

    testMailModal() {
        this.resetForm();
        this.open(this.contentTestMailModal, 'x', 'modal-basic-title');
        this.showTestEmailResponse = false;
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
    }

    /**
     * Form error controll
     */

    get tE() { return this.testEmailForm.controls; } // Error Controller

    /**
     * Test Email Form
     */

    testEmailSubmit() {
        this.testEmailSubmitted = true;

        // stop here if form is invalid
        if (this.testEmailForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.testEmailForm.value);

        this.tradeApiService.ApiServices(this.urlTestEmail, userInput, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.showTestEmailResponse = true;
                this.testEmailresponse = responseData.message;
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Reset test email form data
     */

    resetForm() {
        this.testEmailSubmitted = false;
        this.testEmailForm.clearValidators();
        this.testEmailForm.reset();
        this.testEmailForm.markAsPristine();

        this.testMobileSubmitted = false;
        this.testSMSForm.clearValidators();
        this.testSMSForm.reset();
        this.testSMSForm.markAsPristine();
    }

    /**
     * Trigger test SMS modal popup
     */

    testSMSModal() {
        this.resetForm();
        this.open(this.contentTestSMSModal, 'x', 'modal-basic-title-sms');
        this.showTestSMSResponse = false;
    }

    /**
     * Form error controll
     */

    get tM() { return this.testSMSForm.controls; } // Error Controller

    /**
     * Test SMS Form
     */

    testSMSSubmit() {
        this.testMobileSubmitted = true;

        // stop here if form is invalid
        if (this.testSMSForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.testSMSForm.value);

        this.tradeApiService.ApiServices(this.urlTestSMS, userInput, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.showTestSMSResponse = true;
                this.testSMSresponse = responseData.message;
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }
    updateColorSolid() {
        console.log('updateColorSolid()');
        console.log(this.colorPrimary);



        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
        };

        let colors = {
            theme: {
                colorPrimary: this.colorPrimary,
                colorSecondary: this.colorSecondary,
                colorInfo: this.colorInfo,
                colorSuccess: this.colorSuccess,
                colorWarning: this.colorWarning,
                colorDanger: this.colorDanger,
                colorDark: this.colorDark,
                colorLight: this.colorLight,
                colorPrimaryHover: this.colorPrimaryHover,
                colorSecondaryHover: this.colorSecondaryHover,
                colorInfoHover: this.colorInfoHover,
                colorSuccessHover: this.colorSuccessHover,
                colorWarningHover: this.colorWarningHover,
                colorDangerHover: this.colorDangerHover,
                colorDarkHover: this.colorDarkHover,
                colorLightHover: this.colorLightHover,
            }

        }

        let userInput = Object.assign(input, colors);

        this.tradeApiService.ApiServices(this.urlThemeColor, userInput, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.showTestEmailResponse = true;
                this.testEmailresponse = responseData.message;
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });

    }
}

