import { Component, OnInit, HostListener  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  PasswordResetForm: FormGroup;
  submitted = false;
  Url = 'forget-password-with-check';

  getLanguage = '';
  getCountryList = 'c-l';
  terms_url = '';

  constructor(private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, public commonService: CommonService, private translate: TranslateService) { 
    translate.setDefaultLang( this.commonService.defaultLanguale );
    this.getLanguage = this.commonService.defaultLanguale;
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  ngOnInit() {
    this.getCounrtyDetailsList();
    this.PasswordResetForm = this.formBuilder.group({    
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{5,15}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],         
    });
  } 
  
   // convenience getter for easy access to form fields
   get f() { return this.PasswordResetForm.controls; }

   onSubmit() {    
       this.submitted = true;

       // stop here if form is invalid
       if (this.PasswordResetForm.invalid) {        
           return;
       }      
       this.commonService.showLoader = 1;
       this.tradeApiService.ApiServices(this.Url, this.PasswordResetForm.value, null).subscribe(( forgetDetails:any ) => {
        // do stuff with our data here.
        // ....
  
        // asign data to our class property in the end
        // so it will be available to our template                  
            if(forgetDetails.status == 200){                                   
              this.commonService.showToaster( forgetDetails.message , 1 ); 
              this.commonService.navigateToUrls('/login');
             
            } else{
              this.commonService.showToaster( forgetDetails.message , 4 ); 
            }

            this.commonService.showLoader = 0;
            
        });

   }

   changeLanguage(lang = 'en'){    
    this.getLanguage = lang;
    this.translate.setDefaultLang( this.getLanguage );
    this.commonService.changeCommonLanguage( this.getLanguage );
    }

     /***
*    Getting the Country List  
*    @getCounrtyDetailsList()
*/
getCounrtyDetailsList() {

  let userInput = {
      token: 'token to be passed',
  };

  this.commonService.showLoader = 1;

  this.tradeApiService.ApiServices(this.getCountryList, userInput, null).subscribe((CountryList: any) => {      
      this.terms_url = (CountryList.terms_page_url) ? CountryList.terms_page_url : '#';
      this.commonService.showLoader = 0;
  });
}   

}
