import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

@Component({
  selector: 'app-leverage',
  templateUrl: './leverage.component.html',
  styleUrls: ['./leverage.component.scss']
})

export class LeverageComponent implements OnInit {

    userDetails;
    urlGetLeverageDetails = 'get-leverage-lists';
    urlAddEditLeverage = 'add-edit-leverage';
    urlBlockUnblockLeverage = 'block-unblock-leverage';
    urlGetIndividualLeverage = 'get-individual-leverage-details';
    leverageLists; //for all Leverage lists
    closeResult: string;
    individualLeverageData; //individual Leverage details stored for edit Leverage modal
    title; //Modal Popup header Title
    buttonName; //Modal Popup Button Name

    addEditLeverageForm: FormGroup;
    addEditSubmitted = false;

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    searchLeverageType = '';
    searchDate;

    showMandatoryStart: boolean = false;

    @ViewChild('contentAddNewLeverage', {static: false}) private contentAddNewLeverage: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) { 
        this.AuthorisedLayoutComponent.getRouteAccess();
    }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(mTy) {
        return this.commonService.getMenuPermission('c76c4466f709ebdffe6e', mTy);
    }

    ngOnInit() {

        this.getLeverageLists();

        this.userDetails = JSON.parse( localStorage.getItem('user_details'));

        this.addEditLeverageForm = this.formBuilder.group({
            leverageEndValue: ['', [Validators.required, Validators.pattern('^[0-9\-\']+')]],
            leverageType: ['', [Validators.required]],
            lK: ['', []],
        });
    }

    open(content, sizeS, modalTitleID){
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    dateFormates( dateObject ) {
        let d = new Date(dateObject);
        let day:any = dateObject.day;
        let month:any = dateObject.month;
        let year = dateObject.year;
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        let date = year + '-' + month + '-' + day;
        return date;
    }

    /**
     * Fecting Leverage Lists
     */

    getLeverageLists() {

        this.commonService.showLoader = 1;

        var CreationDate;
        if ( this.searchDate !== undefined && this.searchDate !== null ) {
            CreationDate = this.dateFormates( this.searchDate );
        }

        let input = {
            page: this.page,
            _sLT: this.searchLeverageType,
            _sDat: CreationDate
        };

        this.tradeApiService.ApiServices(this.urlGetLeverageDetails, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.leverageLists = responseData.leverageLists.Records;
                this.totalItems = responseData.leverageLists.TotalCount; 
            }
            else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Load pagination next page
     */

    loadPage(page: number) {   
        this.page = page;
        this.getLeverageLists();
    }

    /**
     * Add Leverage Modal Popup
     */

    addNewLeverageModal() {
        this.resetForm();
        this.open(this.contentAddNewLeverage, 'x', 'modal-basic-title');
        this.title = 'Add New';
        this.buttonName = 'Add';
        this.addEditLeverageForm.controls['leverageType'].setValue('');
    }

    /**
     * Reset add Leverage form values and error messages
     */

    resetForm() {
        this.addEditSubmitted = false;
        this.addEditLeverageForm.clearValidators();
        this.addEditLeverageForm.reset();
        this.addEditLeverageForm.markAsPristine()
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
    }

    /**
     * Angular error message form control
     */

    get f() { return this.addEditLeverageForm.controls; } //Add Leverage Error Controller

    /**
     * Add & Edit Leverage form Submit
     */

    addEditLeverageSubmit() {
        this.addEditSubmitted = true;

        // stop here if form is invalid
        if (this.addEditLeverageForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.addEditLeverageForm.value);

        this.tradeApiService.ApiServices(this.urlAddEditLeverage, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.getLeverageLists();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for(var i=0; i<errorValues.length;i++){                                  
                    this.commonService.showToaster( errorValues[i] , 4 ); 
                }  
            }
            this.commonService.showLoader = 0;

        });
    }
    /**
     * Get Individual Leverage Detail
     */

    getIndividualLeverage(data) {

        this.commonService.showLoader = 1;

        this.resetForm();

        let input = {
            uK: this.userDetails.user_key,
            lK: data.leverage_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualLeverage, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {

                this.open(this.contentAddNewLeverage, 'x', 'modal-basic-title');
                this.title = 'Edit';
                this.buttonName = 'Update';

                this.individualLeverageData = responseData.lD;

                this.addEditLeverageForm.controls['lK'].setValue(data.leverage_key);
                this.addEditLeverageForm.controls['leverageEndValue'].setValue(this.individualLeverageData.leverage_value);
                this.addEditLeverageForm.controls['leverageType'].setValue(this.individualLeverageData.leverage_type);
                
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Clear Search Data's
     */

    clearSearchData() {
        this.searchLeverageType = '';
        this.searchDate = undefined;
        this.getLeverageLists();
    }
}
