import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-my-positions',
  templateUrl: './my-positions.component.html',
  styleUrls: ['./my-positions.component.scss']
})
export class MyPositionsComponent implements OnInit {

  getPositionMt5Login = 0;
  getDemoLive = 0;

  getPositionMt5LoginErr = 0;
  getDemoLiveErr = 0;

  userDetails;

  page = 1;
  totalItems = 0;
  itemsPerPage = 10;

  urlGetMyPositionDetails = 'my-position-details';
  urlGetMyAccountLogin = 'my-account-login-details';
  myPositionDetails;
  myHLoginsDetails;

  constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent, private translate: TranslateService) { 
    this.AuthorisedLayoutComponent.getRouteAccess();
    translate.setDefaultLang( this.commonService.defaultLanguale );
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('user_details'));
  }

  getMypostionDetails() {

      this.getPositionMt5LoginErr = 0;
      this.getDemoLiveErr = 0;


    let err = 0;
    if( this.getPositionMt5Login === undefined || this.getPositionMt5Login == 0) {
      this.getPositionMt5LoginErr = 1;
      err = 1;
    }

    console.log( this.getDemoLive );
    console.log( this.getPositionMt5Login );

    if( this.getDemoLive === undefined || this.getDemoLive == 0){
      this.getDemoLiveErr = 1;
      err = 1;
    }

   if( err == 0){   
    this.getMypostionDetailsFromMt5();
   }
  }

  dateFormates( dateObject ){
    let d = new Date(dateObject);
    let day:any = d.getDate();
    let month:any = d.getMonth() + 1;
    let year = d.getFullYear();
    if (day < 10) {
        day = "0" + day;
    }
    if (month < 10) {
        month = "0" + month;
    }
    let date = year + "-" + month + "-" + day;

    return date;
  }

  getMypostionDetailsFromMt5(){
    this.commonService.showLoader = 1;
    let input = {
      // token:localStorage.getItem('auth_token'),
      uK:  this.userDetails.user_key,
      _dL: this.getPositionMt5Login,
      _dA: this.getDemoLive,
      _p: this.page,
      _ty: this.userDetails.user_type,
    };

    this.tradeApiService.ApiServices(this.urlGetMyPositionDetails, input, null).subscribe((positionDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template                  
      if (positionDetails.status == 200) {
          
        this.myPositionDetails = positionDetails.positionDetails;
        this.totalItems = positionDetails.total;
      }
      else {
        const errorValues = Object.keys(positionDetails.message).map(key => positionDetails.message[key]);
        for(var i=0; i<errorValues.length;i++){                                  
          this.commonService.showToaster( errorValues[i] , 4 ); 
        }
      }

      this.commonService.showLoader = 0;

    });


  }

  loadPage(page: number) {
   
    this.page = page;
    this.getMypostionDetailsFromMt5();
  }

  getAccountLogin(){  
    this.commonService.showLoader = 1;

    let input = {
      //token:localStorage.getItem('auth_token'),                       
      uK:  this.userDetails.user_key,
      _dL: this.getDemoLive,
      _ty: this.userDetails.user_type,
    };

    this.tradeApiService.ApiServices(this.urlGetMyAccountLogin, input, null).subscribe((HistoryDetails: any) => {
      // do stuff with our data here.
      // ....

      // asign data to our class property in the end
      // so it will be available to our template
      if (HistoryDetails.status == 200) {
        console.log( HistoryDetails.logins );
        this.myHLoginsDetails =  HistoryDetails.logins;
      }
      else {
        const errorValues = Object.keys(HistoryDetails.message).map(key => HistoryDetails.message[key]);
        for(var i=0; i<errorValues.length;i++){                                  
          this.commonService.showToaster( errorValues[i] , 4 ); 
        }
      }

      this.commonService.showLoader = 0;

    });

  }

}
