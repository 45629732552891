import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.scss']
})

export class DesignationComponent implements OnInit {

    userDetails;
    urlGetDesignationDetails = 'get-designation-lists';
    urlAddEditDesignation = 'add-edit-designation';
    urlBlockUnblockDesignation = 'block-unblock-designation';
    urlGetIndividualDesignation = 'get-individual-designation-details';
    designationLists; //for all designation lists
    branchList; //for all branches lists
    closeResult: string;
    designationData; //for block & unblock
    individualDesignationData; //individual designation details stored for edit designation modal
    title; //Modal Popup header Title
    buttonName; //Modal Popup Button Name
    blockUnblockTitle;

    addEditDesignationForm: FormGroup;
    addEditSubmitted = false;

    selectBranch = true;

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    searchInputData = '';
    searchBK = '';

    @ViewChild('contentAddNewDesignation', {static: false}) private contentAddNewDesignation: ElementRef;
    @ViewChild('blockUnblockDesignation', {static: false}) private blockUnblockDesignation: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) {
        this.AuthorisedLayoutComponent.getRouteAccess();
     }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(module_key, mTy) {
        return this.commonService.getMenuPermission(module_key, mTy);
    }

    ngOnInit() {

        this.getDesignationLists();

        this.userDetails = JSON.parse( localStorage.getItem('user_details'));

        this.addEditDesignationForm = this.formBuilder.group({
            designationName: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
            bK: ['', [Validators.required]], //Branch Key
            dK: ['', []], //Designation Key
            comments: ['', []], //Comments
        });
    }

    open(content, sizeS, modalTitleID){
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    /**
     * Fecting Designation Lists
     */

    getDesignationLists() {

        this.commonService.showLoader = 1;

        let input = {
            page: this.page,
            _sDl: this.searchInputData,
            _sBK: this.searchBK,
        };

        this.tradeApiService.ApiServices(this.urlGetDesignationDetails, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.designationLists = responseData.designationLists.Records;
                this.totalItems = responseData.designationLists.TotalCount;
                this.branchList = responseData.branchList;
            }
            else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Load pagination next page
     */

    loadPage(page: number) {   
        this.page = page;
        this.getDesignationLists();
    }

    /**
     * Add Designation Modal Popup
     */

    addNewDesignationModal() {
        this.resetForm();
        this.open(this.contentAddNewDesignation, 'x', 'modal-basic-title');
        this.title = 'Add New';
        this.buttonName = 'Add';
        this.selectBranch = true;
        this.addEditDesignationForm.controls['bK'].setValue('');
    }

    /**
     * Reset add Designation form values and error messages
     */

    resetForm() {
        this.addEditSubmitted = false;
        this.addEditDesignationForm.clearValidators();
        this.addEditDesignationForm.reset();
        this.addEditDesignationForm.markAsPristine()
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
    }

    /**
     * Angular error message form control
     */

    get f() { return this.addEditDesignationForm.controls; } //Add Designation Error Controller

    /**
     * Add & Edit Designation form Submit
     */

    addEditDesignationSubmit() {

        this.addEditSubmitted = true;

        // stop here if form is invalid
        if (this.addEditDesignationForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.addEditDesignationForm.value);

        this.tradeApiService.ApiServices(this.urlAddEditDesignation, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.getDesignationLists();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for(var i=0; i<errorValues.length;i++){                                  
                    this.commonService.showToaster( errorValues[i] , 4 ); 
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Block & Unblock Designation Modal Popup
     */

    blockUnblock(data) {
        this.designationData = data;
        
        this.blockUnblockTitle = (data.designation_status == 0) ? 'Unblock' : 'Block';

        this.open(this.blockUnblockDesignation, 'x', 'modal-blockunblock-title');
    }

    confirmBlockUnblock() {
        
        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
          bK: this.designationData.branch_key,
          dK: this.designationData.designation_key,
          dS: this.designationData.designation_status,
        };

        let userInput = Object.assign(input);

        this.tradeApiService.ApiServices(this.urlBlockUnblockDesignation, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.designationData = null;
                this.getDesignationLists();
            } else {
                this.commonService.showToaster( responseData.message , 4 );
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Get Individual Designation Detail
     */

    getIndividualDesignation(data) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            bK: data.branch_key,
            dK: data.designation_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualDesignation, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.open(this.contentAddNewDesignation, 'x', 'modal-basic-title');
                this.title = 'Edit';
                this.buttonName = 'Update';

                this.individualDesignationData = responseData.dD;

                this.addEditDesignationForm.controls['dK'].setValue(data.designation_key);
                this.addEditDesignationForm.controls['bK'].setValue(data.branch_key);
                this.addEditDesignationForm.controls['designationName'].setValue(this.individualDesignationData.name);
                this.addEditDesignationForm.controls['comments'].setValue(this.individualDesignationData.comments);

                this.selectBranch = false;
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Clear Search Data's
     */

    clearSearchData() {
        this.searchInputData = '';
        this.searchBK = '';
        this.getDesignationLists();
    }
}
