import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';
import { Lightbox } from 'ngx-lightbox'; // for image viewer

import Swal from 'sweetalert2';

import { Observable, timer, NEVER, BehaviorSubject, fromEvent, of } from 'rxjs';
import { map, tap, takeWhile, share, startWith, switchMap, filter } from 'rxjs/operators';

import {TranslateService} from '@ngx-translate/core';

import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';


@Component({
    selector: 'app-back-office-live-account-request',
    templateUrl: './back-office-live-account-request.component.html',
    styleUrls: ['./back-office-live-account-request.component.scss']
})
export class BackOfficeLiveAccountRequestComponent implements OnInit {


    closeResult: string;
    modalReference;
    userDetails;
    userDetailsAccount;
    userDetailsAccountAddress;
    userDetailsAccountDocuments;

    userPendingliveAccountDetails;
    userDetailsAccountLeverage;
    userDetailsAccountGroup;

    useAccountDetailsAvailMsg = 0;


    urlGetliveAccountDetails = 'get-live-account-request-details';
    urlGetDetails = 'get-account-details';
    CheckMt5AvailabilityUrl = 'get-availability-details';
    releaseMt5AvailabilityUrl = 'release-availability-details';
    urlCreateLiveDetails = 'create-lv-sr-account-details';
    urlRejectLiveDetails = 'reject-lv-sr-account-details';


    leverageLive = 0;
    groupLive = 0;
    mt5LiveAcc = '';
    mt5LiveTempAcc = '';

    timeLeftToCreateLiveAccount = 300;

    viewRequestK = '';

    leverageLiveErr = 0;
    groupLiveErr = 0;

    aRg = '';

    _albums: any = []; // for image preview

    nextAvailNumber = 0;

    @ViewChild('contentliveAccounts', { static: false }) private contentliveAccounts: ElementRef;
    @ViewChild('contentliveAccountsDetails', { static: false }) private contentliveAccountsDetails: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private _lightbox: Lightbox, private translate: TranslateService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) { 
        this.AuthorisedLayoutComponent.getRouteAccess();
        translate.setDefaultLang( this.commonService.defaultLanguale );
    }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(mTy) {
        return this.commonService.getMenuPermission('95536e1e2a98deb6deea', mTy);
    }

    ngOnInit() {
        this.userDetails = JSON.parse(localStorage.getItem('user_details'));
        this.getliveAccountDetails();
    }


    open(content, sizeS) {
        //this.modalReference = this.modalService.open(content);
        this.modalService.open(content, { backdrop: 'static', keyboard: false, size: sizeS, ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    getliveAccountDetails() {
        console.log('getliveAccountDetails');
        this.commonService.showLoader = 1;

        let input = {
            //token:localStorage.getItem('auth_token'),                       
            uK: this.userDetails.user_key,
            _dL: 2,
            _uType: this.userDetails.user_type,
        };

        this.tradeApiService.ApiServices(this.urlGetliveAccountDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (otpDetails.status == 200) {
                console.log(otpDetails);
                this.userPendingliveAccountDetails = otpDetails.aLp;
                this.aRg = otpDetails.aRg;
                localStorage.setItem('uTlCount', JSON.stringify(otpDetails.uTlCount));
                this.commonService.uTlCount = otpDetails.uTlCount;
            }
            else {
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }

            this.commonService.showLoader = 0;

        });

    }

    viewRequestDetails(uKR, lk) {
        this.viewRequestK = lk;
        this.commonService.showLoader = 1;

        var info; 
        this.translate.get('backOfficeLiveAccountRequest.info').subscribe(lang =>{
            info = lang;           
        });
        var close; 
        this.translate.get('backOfficeLiveAccountRequest.close').subscribe(lang =>{
            close = lang;           
        });
        var AlreadyRequestProcessedByAnotherTeam; 
        this.translate.get('backOfficeLiveAccountRequest.AlreadyRequestProcessedByAnotherTeam').subscribe(lang =>{
            AlreadyRequestProcessedByAnotherTeam = lang;           
        });
        
        let input = {
            uK: uKR,
            _dL: 2,
            _lpK: this.viewRequestK,
        };

        this.tradeApiService.ApiServices(this.urlGetDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (otpDetails.status == 200) {

                if (otpDetails.pSh == 0) {
                    Swal.fire({
                        title: info,
                        text: AlreadyRequestProcessedByAnotherTeam,
                        type: 'info',
                        confirmButtonText: close
                    });
                    this.getliveAccountDetails();
                }
                else {
                    this.userDetailsAccount = otpDetails.pU;
                    this.userDetailsAccountAddress = otpDetails.pK;
                    this.userDetailsAccountDocuments = otpDetails.pD;
                    this.open(this.contentliveAccounts, 'xl');
                    this.userDetailsAccountLeverage = otpDetails.dL;
                    this.userDetailsAccountGroup = otpDetails.dG;
                    this.mt5LiveAcc = otpDetails.mL;
                    this.mt5LiveTempAcc = otpDetails.mL;
                    this.documentValueSet();
                }


            }
            else {
                this.commonService.showToaster(otpDetails.message, 4);
            }

            this.commonService.showLoader = 0;

        });


    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

    CheckMt5Availability(fromCreate = 0) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            _dL: 2,
            _cL: this.mt5LiveAcc, // current enter login
            _pL: this.mt5LiveTempAcc, // already avail login
        };

        this.tradeApiService.ApiServices(this.CheckMt5AvailabilityUrl, input, null).subscribe((otpDetails: any) => {
            this.commonService.showLoader = 0;
            if (otpDetails.status == 200) {
                this.useAccountDetailsAvailMsg = otpDetails._iSa;
                this.nextAvailNumber = otpDetails.lNumber;
                if (fromCreate == 1) {
                    if (this.useAccountDetailsAvailMsg == 1) {
                        this.sendToMt5();
                    }
                }
            }
            else {
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }

        });

    }

    timeLeftHandleEvent(events) {
        if (events.action == 'done') {
            this.releaseMt5Account();
        }
    }
    // release The Mt5 account and close the PopUp
    releaseMt5Account() {
        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            _rel: 1,
            _pL: this.mt5LiveTempAcc, // already avail login
            _cL: this.mt5LiveAcc, // already avail login
            _lpK: this.viewRequestK,
        };

        this.tradeApiService.ApiServices(this.releaseMt5AvailabilityUrl, input, null).subscribe((otpDetails: any) => {
            if (otpDetails.status == 200) {
                this.clearData();
                this.closeModal();
            }
            else {
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });

    }

    clearData() {
        this.leverageLive = 0;
        this.groupLive = 0;
        this.useAccountDetailsAvailMsg = 0;
        this.mt5LiveAcc = '';
        this.mt5LiveTempAcc = '';
    }

    closeMt5Modal() {
        // this.releaseMt5Account();
        this.clearData();
        this.closeModal();
    }

    createLiveAccountInTradeServer() {

        this.leverageLiveErr = 0;
        this.groupLiveErr = 0;

        if (this.leverageLive == 0) {
            this.leverageLiveErr = 1;
        }
        if (this.groupLive == 0) {
            this.groupLiveErr = 1;
        }

        if (this.leverageLive != 0 && this.groupLive != 0) {
            this.CheckMt5Availability(1);
        }

    }

    sendToMt5() {
        this.commonService.showLoader = 1;

        var info; 
        this.translate.get('backOfficeLiveAccountRequest.info').subscribe(lang =>{
            info = lang;           
        });
        var close; 
        this.translate.get('backOfficeLiveAccountRequest.close').subscribe(lang =>{
            close = lang;           
        });
        var AlreadyRequestProcessedByAnotherTeam; 
        this.translate.get('backOfficeLiveAccountRequest.AlreadyRequestProcessedByAnotherTeam').subscribe(lang =>{
            AlreadyRequestProcessedByAnotherTeam = lang;           
        });

        let input = {
            //token:localStorage.getItem('auth_token'),                       
            uK: this.userDetails.user_key,
            _cL: this.leverageLive,
            _cG: this.groupLive,
            _aN: this.mt5LiveAcc,
            _lpK: this.viewRequestK,
        };

        this.tradeApiService.ApiServices(this.urlCreateLiveDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (otpDetails.status == 200) {
                console.log(otpDetails);
                this.closeModal();
                this.getliveAccountDetails();

                if (otpDetails.pSh == 0) {
                    Swal.fire({
                        title: info,
                        text: AlreadyRequestProcessedByAnotherTeam,
                        type: 'info',
                        confirmButtonText: close
                    });
                }
                else {
                    this.commonService.showToaster(otpDetails.message, 1);
                }
            }
            else {
                this.commonService.showToaster(otpDetails.message, 4);
            }

            this.commonService.showLoader = 0;

        });
    }

    rejectRequestValidation() {
        
        var Reject; 
        this.translate.get('backOfficeLiveAccountRequest.Reject').subscribe(lang =>{
            Reject = lang;           
        });

        var ValidReasonRequired; 
        this.translate.get('backOfficeLiveAccountRequest.ValidReasonRequired').subscribe(lang =>{
            ValidReasonRequired = lang;           
        });

        var AreYouSureWantToReject; 
        this.translate.get('backOfficeLiveAccountRequest.AreYouSureWantToReject').subscribe(lang =>{
            AreYouSureWantToReject = lang;           
        });

        var ReasonForRejection; 
        this.translate.get('backOfficeLiveAccountRequest.ReasonForRejection').subscribe(lang =>{
            ReasonForRejection = lang;           
        });

        Swal.mixin({
            input: 'text',
            confirmButtonText: Reject+' &rarr;',
            showCancelButton: true,
            inputValidator: (result) => {
                return !result && ValidReasonRequired
            }
            // progressSteps: ['1']
        }).queue([
            {
                title: AreYouSureWantToReject,
                text: ReasonForRejection
            }
        ]).then((result) => {
            if (result.value) {
                console.log(result.value[0]);
                this.rejectRequest(result.value[0]);
            }
        })
    }

    rejectRequest(reason) {
        this.commonService.showLoader = 1;

        var info; 
        this.translate.get('backOfficeLiveAccountRequest.info').subscribe(lang =>{
            info = lang;           
        });
        var close; 
        this.translate.get('backOfficeLiveAccountRequest.close').subscribe(lang =>{
            close = lang;           
        });
        var AlreadyRequestProcessedByAnotherTeam; 
        this.translate.get('backOfficeLiveAccountRequest.AlreadyRequestProcessedByAnotherTeam').subscribe(lang =>{
            AlreadyRequestProcessedByAnotherTeam = lang;           
        });

        let input = {
            uK: this.userDetails.user_key,
            _lpK: this.viewRequestK,
            _lRea: reason,
        };

        this.tradeApiService.ApiServices(this.urlRejectLiveDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template                  
            if (otpDetails.status == 200) {
                console.log(otpDetails);
                this.closeModal();
                this.getliveAccountDetails();

                if (otpDetails.pSh == 0) {
                    Swal.fire({
                        title: info,
                        text: AlreadyRequestProcessedByAnotherTeam,
                        type: 'info',
                        confirmButtonText: close
                    });
                }
                else {
                    this.commonService.showToaster(otpDetails.message, 1);
                }
            }
            else {
                this.commonService.showToaster(otpDetails.message, 4);
            }

            this.commonService.showLoader = 0;

        });
    }

    // open document image 
    Sopen(index: number): void {
        // open lightbox
        console.log(this._albums);
        this._lightbox.open(this._albums, index);
    }

    /**
   * Assing the document image URL to popup image viewer and thumline
   */

    documentValueSet() {
        this._albums = []; // for image preview
        console.log(this.userDetailsAccountDocuments);
        var i = 1;
        for (var doc of this.userDetailsAccountDocuments) {

            // for image preview
            const src = doc.image;
            const caption = 'Document ' + i;
            const thumb = doc.image;
            const album = {
                src: src,
                caption: caption,
                thumb: thumb
            };

            this._albums.push(album);

            // end for image preview

            i++;
        }
    }
}
