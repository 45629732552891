import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss']
})

export class BalanceComponent implements OnInit {

    userDetails;
    urlGetBalanceDetails = 'get-balance-lists';
    urlAddEditBalance = 'add-edit-balance';
    urlBlockUnblockBalance = 'block-unblock-balance';
    urlGetIndividualBalance = 'get-individual-balance-details';
    balanceLists; //for all balance lists
    closeResult: string;
    individualBalanceData; //individual balance details stored for edit balance modal
    title; //Modal Popup header Title
    buttonName; //Modal Popup Button Name

    addEditBalanceForm: FormGroup;
    addEditSubmitted = false;

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    searchBalanceType = '';
    searchDate;

    showMandatoryStart: boolean = false;

    @ViewChild('contentAddNewBalance', {static: false}) private contentAddNewBalance: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) { 
        this.AuthorisedLayoutComponent.getRouteAccess();
    }

    /**
     * Menu Enable (or) Disable
     */

    getMenuPermission(mTy) {
        return this.commonService.getMenuPermission('3c131720a7c93e4ffe23', mTy);
    }

    ngOnInit() {

        this.getBalanceLists();

        this.userDetails = JSON.parse( localStorage.getItem('user_details'));

        this.addEditBalanceForm = this.formBuilder.group({
            // balanceValue: ['', [Validators.required, Validators.pattern('/^-?\$?[0-9][0-9]*[0-9]\.?[0-9]{0,2}$/i')]],
            balanceValue: ['', [Validators.required, Validators.pattern(/^-?\$?[0-9][0-9]*[0-9]\.?[0-9]{0,2}$/)]],
            balanceType: ['', [Validators.required]],
            bK: ['', []],
        });
    }

    open(content, sizeS, modalTitleID){
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    dateFormates( dateObject ) {
        let d = new Date(dateObject);
        let day:any = dateObject.day;
        let month:any = dateObject.month;
        let year = dateObject.year;
        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        let date = year + "-" + month + "-" + day;
        return date;
    }

    /**
     * Fecting Balance Lists
     */

    getBalanceLists() {

        this.commonService.showLoader = 1;

        var CreationDate;

        if ( this.searchDate !== undefined && this.searchDate !== null ) {
            CreationDate = this.dateFormates( this.searchDate );
        }

        let input = {
            page: this.page,
            _sBT: this.searchBalanceType,
            _sDat: CreationDate
        };

        this.tradeApiService.ApiServices(this.urlGetBalanceDetails, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {
                this.balanceLists = responseData.balanceLists.Records;
                this.totalItems = responseData.balanceLists.TotalCount; 
            }
            else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Load pagination next page
     */

    loadPage(page: number) {   
        this.page = page;
        this.getBalanceLists();
    }

    /**
     * Add Balance Modal Popup
     */

    addNewBalanceModal() {
        this.resetForm();
        this.open(this.contentAddNewBalance, 'x', 'modal-basic-title');
        this.title = 'Add New';
        this.buttonName = 'Add';
        this.addEditBalanceForm.controls['balanceType'].setValue('');
    }

    /**
     * Reset add Balance form values and error messages
     */

    resetForm() {
        this.addEditSubmitted = false;
        this.addEditBalanceForm.clearValidators();
        this.addEditBalanceForm.reset();
        this.addEditBalanceForm.markAsPristine()
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
    }

    /**
     * Angular error message form control
     */

    get f() { return this.addEditBalanceForm.controls; } //Add Balance Error Controller

    /**
     * Add & Edit Balance form Submit
     */

    addEditBalanceSubmit() {
        this.addEditSubmitted = true;

        // stop here if form is invalid
        if (this.addEditBalanceForm.invalid) {
            return;
        }

        this.commonService.showLoader = 1;

        let input = {
          uK: this.userDetails.user_key,
        };

        let userInput = Object.assign(input, this.addEditBalanceForm.value);

        this.tradeApiService.ApiServices(this.urlAddEditBalance, userInput , null).subscribe(( responseData:any ) => {

            if(responseData.status == 200) {
                this.commonService.showToaster( responseData.message , 1 );
                this.closeModal();
                this.getBalanceLists();
            } else {
                const errorValues = Object.keys(responseData.message).map(key => responseData.message[key]);
                for(var i=0; i<errorValues.length;i++){                                  
                    this.commonService.showToaster( errorValues[i] , 4 ); 
                }  
            }
            this.commonService.showLoader = 0;

        });
    }
    /**
     * Get Individual Balance Detail
     */

    getIndividualBalance(data) {

        this.commonService.showLoader = 1;

        this.resetForm();

        let input = {
            uK: this.userDetails.user_key,
            bK: data.balance_key,
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualBalance, input, null).subscribe((responseData: any) => {

            if (responseData.status == 200) {

                this.open(this.contentAddNewBalance, 'x', 'modal-basic-title');
                this.title = 'Edit';
                this.buttonName = 'Update';

                this.individualBalanceData = responseData.bD;

                this.addEditBalanceForm.controls['bK'].setValue(data.balance_key);
                this.addEditBalanceForm.controls['balanceValue'].setValue(this.individualBalanceData.balance_value);
                this.addEditBalanceForm.controls['balanceType'].setValue(this.individualBalanceData.balance_type);
                
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;

        });
    }

    /**
     * Clear Search Data's
     */

    clearSearchData() {
        this.searchBalanceType = '';
        this.searchDate = undefined;
        this.getBalanceLists();
    }
}
