import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr'; // for notifications

@Injectable({
    providedIn: 'root'
})
export class ApiTradeService {


    showLog = '1';
    tZone = '+3';

    uri = 'https://api.admtrades.com/api/';
    // uri = 'http://localhost:8000/api/';
    //uri = 'https://api.tradefca.com/api/';
    // uri = 'https://service.xn--22ceio5d5cydd6bf0c8n7b.com/api/';

    accessUrl = 'auth/login';
    token;
    myGlobalVar;
    permissions;
    public loaderR: number;

    constructor(private http: HttpClient, private router: Router, public toastr: ToastrService, private translate: TranslateService) { }

    public ApiServices(accessUrls, parametes, optionsDetails) {
        // console.log( localStorage.getItem('auth_token') );

        var auth = '';
        var Rupdate = '';
        var rolKey = '';

        if (localStorage.getItem('auth_token')) {
            auth = 'bearer ' + localStorage.getItem('auth_token');
            Rupdate = localStorage.getItem('RUpdated');
            rolKey = localStorage.getItem('rolKey');
        }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache', 'Authorization': auth
        });
        let options = { headers: headers };

        var lang = localStorage.getItem('language');

        let input = {
            lang: lang,
            Rupdate: Rupdate,
            rolKey: rolKey,
        };

        parametes = Object.assign(parametes, input);

        return this.http.post(this.uri + accessUrls, parametes, options).pipe(
            map((res: any) => {
                if (res.status == 200 || res.status == 500) {
                    return res
                }
                else if (res.status == 401) {
                    return res;
                }
                else if (res.status == 307) {
                    // this.toastr.info('Role Access Has been Updated', 'Info!');          
                    localStorage.setItem('permission', JSON.stringify(res.privileges));
                    localStorage.setItem('RUpdated', res.RUpdated);
                    window.location.href = '/'
                }
                else if (res.status == 407) {
                    this.removeLocalStr();

                    var Warning; 
                    this.translate.get('commonService.Warning').subscribe(lang =>{
                        Warning = lang;           
                    });

                    this.toastr.warning(res.message, Warning);
                    this.router.navigateByUrl('/');
                }
                else {
                    /**
                     * toaster messa to addded
                     */
                    // this.toastr.errorToastr( res.message , 'Alert!'); 
                }
            }));
    }

    removeLocalStr() {

        localStorage.removeItem('auth_token');
        localStorage.removeItem('user_details');
        localStorage.removeItem('uAdCount');
        localStorage.removeItem('uTlCount');
        localStorage.removeItem('permission');
        localStorage.removeItem('currentRecord');
        localStorage.removeItem('RUpdated');
        localStorage.removeItem('rolKey');

    }

}
