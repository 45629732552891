import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';
import Swal from 'sweetalert2';
import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';

declare var $: any;

@Component({
    selector: 'app-individual-activity-log',
    templateUrl: './individual-activity-log.component.html',
    styleUrls: ['./individual-activity-log.component.scss']
})
export class IndividualActivityLogComponent implements OnInit {

    userDetails;
    urlGetIndividualActivityLogs = 'get-individual-activity-logs';
    urlGetActivityLogsDetailedView = 'get-activity-logs-detailed-view';
    activityLogs;
    searchDate;
    searchInputData;

    page = 1;
    totalItems = 0;
    itemsPerPage = 10;

    individualUserKey;
    closeResult: string;
    formData;

    @ViewChild('viewFormData', { static: false }) private viewFormData: ElementRef;

    // tslint:disable-next-line: max-line-length
    constructor(private modalService: NgbModal, private tradeApiService: ApiTradeService, private commonService: CommonService, private AuthorisedLayoutComponents: AuthorisedLayoutComponent) {
        this.AuthorisedLayoutComponents.getRouteAccess();
    }

    dateFormates(dateObject) {
        let day: any = dateObject.day;
        let month: any = dateObject.month;
        const year = dateObject.year;
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        const date = year + '-' + month + '-' + day;
        return date;
    }

    open(content, sizeS, modalTitleID) {
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: modalTitleID }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnInit() {
        this.userDetails = JSON.parse(localStorage.getItem('user_details'));

        let userKey;
        userKey = window.localStorage.getItem('individualActivityLogUserKey');

        this.individualUserKey = (userKey) ? userKey : this.individualUserKey;
        // localStorage.removeItem('individualActivityLogUserKey');

        this.getIndividualActivitylog();
    }

    /**
     * Get Individual Activity Log
     */

    getIndividualActivitylog() {

        this.commonService.showLoader = 1;

        let CreationDate: any;
        if ( this.searchDate !== undefined && this.searchDate !== null ) {
            CreationDate = this.dateFormates(this.searchDate);
        }

        const input = {
            page: this.page,
            uK: this.userDetails.user_key,
            uKey: this.individualUserKey,
            _sDl: this.searchInputData,
            _sDat: CreationDate
        };

        this.tradeApiService.ApiServices(this.urlGetIndividualActivityLogs, input, null).subscribe((responseData: any) => {

            if (responseData.status === 200) {
                this.activityLogs = responseData.activityLists.Records;
                this.totalItems = responseData.activityLists.TotalCount;
            } else {
                this.commonService.showToaster(responseData.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }

    /**
     * Load pagination next page
     */

    loadPage(page: number) {
        this.page = page;
        this.getIndividualActivitylog();
    }

    /**
     * Clear Search Data's
     */

    clearSearchData() {
        this.searchInputData = '';
        this.searchDate = undefined;
        this.getIndividualActivitylog();
    }

    /**
     * Close modal popup
     */

    closeModal() {
        this.modalService.dismissAll();
    }

    /**
     * Get Activity Log Detailed View
     */

    getActivityLogDetailedView(data) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            aLK: data.activity_log_key,
        };

        this.tradeApiService.ApiServices(this.urlGetActivityLogsDetailedView, input, null).subscribe((responseData: any) => {

            if (responseData.status === 200) {

                this.open(this.viewFormData, 'x', 'modal-blockunblock-title');

                var response = responseData.activityLists;

                if (response.old_data) {

                    var html = '';
                    $.each(JSON.parse(response.old_data), function(i, item) {

                        // tslint:disable-next-line: max-line-length
                        html += '<div class=\'list-order mb-3\'><h6 class=\'mb-2\'><strong>' + i + ' </strong></h6><ul class=\'list-item pl-3\'><li class=\'d-flex justify-content-between flex-row\'><span class=\'flex-1\'><strong>Previous</strong></span>: <span class=\'flex-2\'>' + item.previous + '</span></li><li class=\'d-flex justify-content-between flex-row\'><span class=\'flex-1\'><strong>Change</strong></span>: <span class=\'flex-2\'>' + item.changes + '</span></li></ul></div>';

                    });

                    $('pre').html(html);
                } else if (response.data) {
                    $('pre').text(response.data);
                    $('pre').text(JSON.stringify(JSON.parse($('pre').text()), null, '\t'));
                }  else if (response.form_data) {
                    $('pre').text(response.form_data);
                    $('pre').text(JSON.stringify(JSON.parse($('pre').text()), null, '\t'));
                }

            } else {
                this.commonService.showToaster(response.message, 4);
            }
            this.commonService.showLoader = 0;
        });
    }
}
