import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr'; // for notifications

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiTradeService } from '../../api-trade.service';
import { CommonService } from '../../common.service';

import Swal from 'sweetalert2';

import { TranslateService } from '@ngx-translate/core';


import { AuthorisedLayoutComponent } from '../../layout/authorised/authorised-layout/authorised-layout.component';


@Component({
    selector: 'app-client-live-accounts-details',
    templateUrl: './client-live-accounts-details.component.html',
    styleUrls: ['./client-live-accounts-details.component.scss']
})
export class ClientLiveAccountsDetailsComponent implements OnInit {


    closeResult: string;
    modalReference;
    userDetailsAccount;
    userDetailsAccountLeverage;
    userDetailsAccountBalance;
    userDetailsAccountAddress;
    userDetails;
    userliveAccountDetails;
    userPendingliveAccountDetails;
    userMt5liveAccountDetails;
    userMt5liveAccountTradeDetails;
    userliveAccountDetailsAllowed = 0;
    urlGetDetails = 'get-live-account-creation-details';
    urlCreateDetails = 'create-live-account-details';

    urlGetliveAccountDetails = 'get-live-account-details';
    urlGetliveSingleMt5AccountDetails = 'get-live-mt5-account-details';

    leverage = 0;
    isCollapsedP;
    isCollapsedL;

    confirm = false;
    invalidLogin = false;
    pwdPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}';
    numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
    passwordUpdateForm: FormGroup;
    submittedPassword = false;
    urlProfileChangePasswordTradeOtp = 'customer-trade-password-reset-otp';
    urlProfileChangePasswordTrade = 'customer-trade-password-reset';
    urlcustomerTradePasswordCheckMt5AccountDetails = 'get-customer-mt5-password-change-check-details';

    disableTradeForm = 0;
    showTradeOtp = 0;

    tradeOtpPassword = '';
    tradeOtpPasswordErr = 0;

    canLiveAccountCreate = 0;

    timeLeftToCreateLiveAccount = 60;
    showTimeResendOtp = 0;

    info;
    confirmButtonText;
    MT5Login;

    @ViewChild('contentliveAccounts', { static: false }) private contentliveAccounts: ElementRef;
    @ViewChild('contentliveAccountsDetails', { static: false }) private contentliveAccountsDetails: ElementRef;
    @ViewChild('contentChangeTradePasswordliveAccounts', { static: false }) private contentChangeTradePasswordliveAccounts: ElementRef;

    constructor(private modalService: NgbModal, private toastr: ToastrService, private formBuilder: FormBuilder, private tradeApiService: ApiTradeService, private commonService: CommonService, private translate: TranslateService, private AuthorisedLayoutComponent: AuthorisedLayoutComponent) {
        this.AuthorisedLayoutComponent.getRouteAccess();
        translate.setDefaultLang(this.commonService.defaultLanguale);
    }

    ngOnInit() {
        this.userDetails = JSON.parse(localStorage.getItem('user_details'));
        this.getliveAccountDetails();

        this.passwordUpdateForm = this.formBuilder.group({
            newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.pwdPattern)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.pwdPattern)]],
            mt5Login: ['', [Validators.required]],
            user_key: ['', [Validators.required]],
            PasswordType: ['', [Validators.required]],
        }, {
            validator: this.MustMatch('newPassword', 'confirmPassword')
        });


        this.translate.get('tsFileDetails.Info').subscribe(lang => {
            this.info = lang;
        });

        this.translate.get('tsFileDetails.confirmButtonText').subscribe(lang => {
            this.confirmButtonText = lang;
        });


    }


    open(content, sizeS) {
        // this.modalReference = this.modalService.open(content);
        this.modalService.open(content, { size: sizeS, ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    // Showing the Popup for creating the Creating the live account request

    createliveAccount() {
        this.clearData();
        // console.log( this.userliveAccountDetails.length );
        var Allowed_to_create_only;
        var live_Accounts;

        this.translate.get('tsFileDetails.Allowed_to_create_only').subscribe(lang => {
            Allowed_to_create_only = lang;
        });

        this.translate.get('tsFileDetails.live_Accounts').subscribe(lang => {
            live_Accounts = lang;
        });

        if (this.userliveAccountDetailsAllowed <= this.userliveAccountDetails.length) {

            Swal.fire({
                title: this.info,
                text: Allowed_to_create_only + ' ' + this.userliveAccountDetailsAllowed + ' ' + live_Accounts,
                type: 'info',
                confirmButtonText: this.confirmButtonText
            })

        }
        else {
            this.getProfileLeverageBalanceDetails(2);
        }

    }

    /**
    * Fecting the leverage and balance Details
    */

    getProfileLeverageBalanceDetails(islive = 2) {
        this.commonService.showLoader = 1;

        let input = {
            // token:localStorage.getItem('auth_token'),
            uK: this.userDetails.user_key,
            _dL: islive,
        };
        var We_already_processing;
        this.translate.get('tsFileDetails.We_already_processing_your_previous_request').subscribe(lang => {
            We_already_processing = lang;
        });

        this.tradeApiService.ApiServices(this.urlGetDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template
            if (otpDetails.status == 200) {
                if (otpDetails.dPs > 0) {
                    Swal.fire({
                        title: this.info,
                        text: We_already_processing,
                        type: 'info',
                        confirmButtonText: this.confirmButtonText
                    });
                } else {
                    this.userDetailsAccount = otpDetails.pU;
                    this.userDetailsAccountLeverage = otpDetails.dL;
                    this.userDetailsAccountBalance = otpDetails.dB;
                    this.userDetailsAccountAddress = otpDetails.pUExt;
                    this.open(this.contentliveAccounts, 'xl');
                }
            } else {
                this.commonService.showToaster(otpDetails.message, 4);
            }

            this.commonService.showLoader = 0;
        });
    }

    // creating the request for live account

    createliveAccountInTradeServer() {

        this.commonService.showLoader = 1;

        let input = {
            // token:localStorage.getItem('auth_token'),
            uK: this.userDetails.user_key,
            _cL: this.leverage,
        };

        this.tradeApiService.ApiServices(this.urlCreateDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template
            if (otpDetails.status == 200) {
                // console.log( otpDetails );
                this.closeModal();
                this.getliveAccountDetails();
                this.commonService.showToaster(otpDetails.message, 1);
            } else {
                this.commonService.showToaster(otpDetails.message, 4);
            }

            this.commonService.showLoader = 0;

        });
    }

    // getting the live / oending account details


    getliveAccountDetails() {
        console.log('getliveAccountDetails');
        this.commonService.showLoader = 1;

        let input = {
            // token:localStorage.getItem('auth_token'),
            uK: this.userDetails.user_key,
            _dL: 2,
        };

        this.tradeApiService.ApiServices(this.urlGetliveAccountDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template
            if (otpDetails.status == 200) {
                console.log(otpDetails);
                this.userliveAccountDetails = otpDetails.aDc;
                this.userliveAccountDetailsAllowed = otpDetails.aW;
                this.userPendingliveAccountDetails = otpDetails.aLp;
                this.canLiveAccountCreate = otpDetails.dCp;
                localStorage.setItem('uAdCount', JSON.stringify(otpDetails.uAdCount));
                this.commonService.uAdCount = otpDetails.uAdCount;
            } else {
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }

            this.commonService.showLoader = 0;

        });
    }

    // clearing the select fields
    clearData() {
        this.leverage = 0;
    }

    // viewing the live account details from the MT5 server

    viewMt5Details(mt5Login) {
        console.log(mt5Login)

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            _dL: 2,
            _dM: mt5Login,
        };

        this.tradeApiService.ApiServices(this.urlGetliveSingleMt5AccountDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template
            if (otpDetails.status == 200) {
                console.log(otpDetails);
                this.userMt5liveAccountDetails = otpDetails.aDd;
                this.userMt5liveAccountTradeDetails = otpDetails.aDdAccnts;
                this.open(this.contentliveAccountsDetails, 'xl');
            } else {
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }

            this.commonService.showLoader = 0;

        });
    }


    checkForValidTradePasswordChange(item) {

        this.commonService.showLoader = 1;

        let input = {
            uK: this.userDetails.user_key,
            _dL: 2,
        };

        var Your_Are_allowed_to_change_twice_perday;

        this.translate.get('tsFileDetails.Your_Are_allowed_to_change_twice_perday').subscribe(lang => {
            Your_Are_allowed_to_change_twice_perday = lang;
        });

        console.log(Your_Are_allowed_to_change_twice_perday);
        this.tradeApiService.ApiServices(this.urlcustomerTradePasswordCheckMt5AccountDetails, input, null).subscribe((otpDetails: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template
            if (otpDetails.status == 200) {
                console.log(otpDetails);
                if (otpDetails.aLw == 1) {
                    this.callResetPassword(item)
                } else {
                    Swal.fire({
                        title: this.info,
                        text: Your_Are_allowed_to_change_twice_perday,
                        type: 'info',
                        confirmButtonText: this.confirmButtonText
                    })
                }
            } else {
                const errorValues = Object.keys(otpDetails.message).map(key => otpDetails.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }

            this.commonService.showLoader = 0;

        });
    }

    resetTradePassword(item) {
        this.showTimeResendOtp = 0;
        this.checkForValidTradePasswordChange(item);
        return;
    }

    callResetPassword(item) {
        // console.log( item );
        this.clearForm();
        this.disableTradeForm = 0;
        this.showTradeOtp = 0;
        this.confirm = false;
        this.invalidLogin = false;

        this.tradeOtpPassword = '';
        this.tradeOtpPasswordErr = 0

        this.passwordUpdateForm.controls['mt5Login'].setValue(item.mt5_login);
        this.MT5Login = item.mt5_login;
        this.passwordUpdateForm.controls['user_key'].setValue(item.user_key);
        this.passwordUpdateForm.controls['PasswordType'].setValue('');
        this.open(this.contentChangeTradePasswordliveAccounts, 'sl');
    }

    // Password compare

    MustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }

            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                this.confirm = true;
                matchingControl.setErrors({ mustMatch: true });
            } else {
                this.confirm = false;
                matchingControl.setErrors(null);
            }
        }
    }

    urlChangePasswordTradeOtp() {

        this.submittedPassword = true;

        // stop here if form is invalid
        if (this.passwordUpdateForm.invalid) {
            return;
        }

        this.invalidLogin = false;
        if(this.passwordUpdateForm.value.mt5Login != this.MT5Login) {
            this.invalidLogin = true;
            return;
        }

        this.confirm = false;
        if (this.passwordUpdateForm.value.confirmPassword != this.passwordUpdateForm.value.newPassword) {
            this.confirm = true;
            return;
        }

        let input = {
            uK: this.userDetails.user_key,
            _dl: 2,
        };

        this.commonService.showLoader = 1;

        let userInput = Object.assign(input, this.passwordUpdateForm.value);

        this.showTimeResendOtp = 0;

        this.tradeApiService.ApiServices(this.urlProfileChangePasswordTradeOtp, userInput, null).subscribe((changePassword: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template
            if (changePassword.status == 200) {
                this.commonService.showToaster(changePassword.message, 1);
                // console.log( changePassword )
                this.disableTradeForm = 1;
                this.showTradeOtp = 1;
            } else {
                const errorValues = Object.keys(changePassword.message).map(key => changePassword.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }

            this.commonService.showLoader = 0;
        });
    }

    get fv() { return this.passwordUpdateForm.controls; }

    /**
     * Clearing the form data for change password
     */

    clearForm() {

        this.submittedPassword = false;
        this.passwordUpdateForm.clearValidators();
        this.passwordUpdateForm.reset();
        this.passwordUpdateForm.markAsPristine();

    }

    VerifyUpdateTradePassword() {
        // console.log(this.tradeOtpPassword);

        let input = {
            uK: this.userDetails.user_key,
            _otT: this.tradeOtpPassword,
            _dT: 2
        };

        this.commonService.showLoader = 1;

        let userInput = Object.assign(input, this.passwordUpdateForm.value);

        // console.log( userInput );

        this.tradeApiService.ApiServices(this.urlProfileChangePasswordTrade, userInput, null).subscribe((changePassword: any) => {
            // do stuff with our data here.
            // ....

            // asign data to our class property in the end
            // so it will be available to our template
            if (changePassword.status == 200) {
                this.commonService.showToaster(changePassword.message, 1);
                // console.log( changePassword )
                this.closeModal();
            } else {
                const errorValues = Object.keys(changePassword.message).map(key => changePassword.message[key]);
                for (var i = 0; i < errorValues.length; i++) {
                    this.commonService.showToaster(errorValues[i], 4);
                }
            }
            this.commonService.showLoader = 0;
        });
    }

    timeLeftHandleEvent(events) {
        if (events.action == 'done') {
            this.showTimeResendOtp = 1;
        }
    }
}
